import { env } from "@saas/config/shared";

import { kratos } from "../..";

import { Session } from "@ory/client";

export type FetchSessionInput = string;

export type FetchSessionOutput = Promise<Session>;

export const SESSION_COOKIE_KEY = "ory_kratos_session";

const BASE_URL = typeof window !== "undefined" ? "/" : env.APP_URL;

export const fetchSession = async (
  cookie?: FetchSessionInput,
  baseUrl = BASE_URL
): FetchSessionOutput => {
  const { data } = await kratos.toSession(
    {
      cookie: cookie ? `${SESSION_COOKIE_KEY}=${cookie}` : undefined,
    },
    {
      baseURL: baseUrl,
    }
  );

  return data;
};

export default fetchSession;
