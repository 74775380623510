import { BusinessInformationModel } from "@saas/account/utils";
import { fetchJson, ResponseInterface } from "@saas/core";
import { mapToCamelCase } from "@saas/shared/utils";

export interface GetBusinessInformationResponse extends ResponseInterface {
  data: BusinessInformationModel;
}

export type GetBusinessInformationOutput = BusinessInformationModel;

export const getBusinessInformation = async (
  options?: RequestInit
): Promise<GetBusinessInformationOutput> => {
  const response = await fetchJson<GetBusinessInformationResponse>(
    "/api/promotion/user-accounts/business-information-summary",
    options
  );

  return mapToCamelCase(response.data);
};

export default getBusinessInformation;
