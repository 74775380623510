export const accessRouteMapping: Record<string, string> = {
  "accounting_journal:all": "/accounting",
  "broadcast_campaign:all": "/promotion/broadcast-campaign",
  "catalog:all": "/catalog",
  "chat:all": "/crm/chat",
  "customer_analysis:all": "/customer-analysis",
  "loyalty_points:action": "/loyalty-point",
  "loyalty_points:view": "/loyalty-point",
  "main_dashboard:all": "/",
  "marketplace_connection:action": "/marketplace-connections",
  "marketplace_connection:view": "/marketplace-connections",
  "order:action": "/order",
  "order:view": "/order",
  "payment:all": "/payment",
  "pos:all": "/cashier",
  "price_monitoring:all": "/keyword-monitoring",
  "product_collection:action": "/inventory/collections",
  "product_collection:view": "/inventory/collections",
  "produk:action": "/inventory/products",
  "produk:view": "/inventory/products",
  "promotion:all": "/promotion/promotion-list",
  "reports_list:all": "/reports",
  "salesperson:all": "/salesperson",
  "settings_service_fee:all": "/service-fee",
  "shop_monitoring:all": "/shop-monitoring",
  "stock:action": "/inventory/stocks",
  "stock:view": "/inventory/stocks",
  "term_of_payment:all": "/term-of-payment",
  "virtual_bundling:action": "/inventory/bundling",
  "virtual_bundling:view": "/inventory/bundling",
  // Since we don't have warehouse feature yet, the route is mapped to "/"
  "warehouse:action": "/",
  "warehouse:view": "/",
  "voucher:view": "/voucher",
  "voucher:action": "/voucher",
};

export default accessRouteMapping;
