import { memo, ReactNode, useMemo } from "react";

import { env } from "@saas/config/shared";
import { queryClient } from "@saas/core";
import { WebSocketChatConnectionProvider } from "@saas/crm/feature";
import { FlagKeys } from "@saas/flags/utils";
import { AlertProvider } from "@saas/shared/feature";

import { FeatureFlagsProvider } from "..";

import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import {
  DehydratedState,
  Hydrate,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

interface ProvidersData {
  flags: Record<FlagKeys, boolean> | undefined;
}

export interface ProvidersProps {
  dehydratedState?: DehydratedState;
  children: (props: ProvidersData) => ReactNode;
}

export const Providers = memo(
  ({ dehydratedState, children }: ProvidersProps) => {
    const client = useMemo(() => queryClient, []);

    return (
      <QueryClientProvider client={client}>
        <FeatureFlagsProvider>
          {(flags) => (
            <GTMProvider state={{ id: env.GTM_ID }}>
              <ReactQueryDevtools initialIsOpen={false} />
              <Hydrate state={dehydratedState}>
                <AlertProvider>
                  <WebSocketChatConnectionProvider>
                    {children({ flags })}
                  </WebSocketChatConnectionProvider>
                </AlertProvider>
              </Hydrate>
            </GTMProvider>
          )}
        </FeatureFlagsProvider>
      </QueryClientProvider>
    );
  }
);

Providers.displayName = "Providers";

export default Providers;
