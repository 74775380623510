import { useEffect } from "react";

export type UseBackgroundScannerInputType = {
  isEnabled?: boolean;
  onReceivedData: (data: string) => void;
};

export const useBackgroundScanner = ({
  isEnabled = true,
  onReceivedData,
}: UseBackgroundScannerInputType) => {
  useEffect(() => {
    if (isEnabled) {
      let timeout: NodeJS.Timeout;
      let input = "";

      const handleKeyDown = (e: KeyboardEvent) => {
        clearTimeout(timeout);
        if (!(e.key.length > 1 || e.ctrlKey)) input += e.key;

        timeout = setTimeout(() => {
          if (input.length <= 3) {
            input = "";
            return;
          }
          onReceivedData(input);
          input = "";
        }, 20);
      };

      window.addEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
        clearTimeout(timeout);
      };
    }
  }, [isEnabled, onReceivedData]);
};

export default useBackgroundScanner;
