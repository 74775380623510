import { env } from "@saas/config/shared";
import { ampli, eventGA } from "@saas/core";

import ReactGA from "react-ga4";

export const setGAUserID = (id: string) => {
  ReactGA.set({ userId: id });
};

export const setGTagUserId = (id: string | null) => {
  ReactGA.gtag("config", env.GOOGLE_ANALYTICS_ID, {
    user_id: id,
  });
};

export const setGTagUserNameProperty = (value: string) => {
  ReactGA.gtag("set", "user_properties", {
    name: value,
  });
};

export const setGTagUserVerifiedProperty = (value: boolean) => {
  ReactGA.gtag("set", "user_properties", {
    account_verified: value,
  });
};

export const trackUpdateEmailSuccess = () => {
  eventGA({
    name: "Edit Profile",
    params: {
      category: "User Account",
      action: "click_change_email",
      value: 1,
    },
  });

  setGTagUserVerifiedProperty(false);

  ampli.editProfile({
    action: "click_change_email",
  });

  ampli.identify(undefined, {
    account_verified: false,
  });
};

export const trackUpdateEmailError = () => {
  eventGA({
    name: "Edit Profile",
    params: {
      category: "User Account",
      action: "click_change_email",
      value: 0,
    },
  });

  ampli.editProfile({
    action: "click_change_email",
  });
};

export const trackUpdatePasswordSuccess = () => {
  eventGA({
    name: "Edit Profile",
    params: {
      category: "User Account",
      action: "click_change_password",
      value: 1,
    },
  });

  ampli.editProfile({
    action: "click_change_password",
  });
};

export const trackUpdatePasswordError = () => {
  eventGA({
    name: "Edit Profile",
    params: {
      category: "User Account",
      action: "click_change_password",
      value: 0,
    },
  });

  ampli.editProfile({
    action: "click_change_password",
  });
};

export const trackUpdateProfileSuccess = (username: string) => {
  eventGA({
    name: "Edit Profile",
    params: {
      category: "User Account",
      action: "click_update_general_information",
      value: 1,
    },
  });

  setGTagUserNameProperty(username);

  ampli.editProfile({
    action: "click_update_general_information",
  });

  ampli.identify(undefined, {
    name: username,
  });
};

export const trackUpdateProfileError = () => {
  eventGA({
    name: "Edit Profile",
    params: {
      category: "User Account",
      action: "click_update_general_information",
      value: 0,
    },
  });

  ampli.editProfile({
    action: "click_update_general_information",
  });
};

export const trackResendVerificationLink = () => {
  eventGA({
    name: "Resend Verification",
    params: {
      category: "User Account",
      action: "click_resend_verification_link",
    },
  });

  ampli.resendVerification({
    action: "click_resend_verification_link",
  });
};

export const trackMissionBanner = (
  missionType: "Verify Email" | "Add Marketplace"
) => {
  eventGA({
    name: "Click Mission",
    params: {
      category: "",
      mission_name: missionType,
      action: "click_mission_action_button",
    },
  });

  ampli.clickMission({
    action: "click_mission_action_button",
    mission_name: missionType,
  });
};

export const trackCompleteMissionBanner = () => {
  eventGA({
    name: "Finish Mission",
    params: {
      category: "",
      action: "click_complete_mission_button",
    },
  });

  ampli.finishMission({
    action: "click_complete_mission_button",
  });
};

export const trackLoginSuccess = (
  userId: string,
  username: string,
  verified: boolean
) => {
  eventGA({
    name: "Login Account",
    params: {
      category: "User Account",
      action: "click_login_button",
      value: 1,
    },
  });

  setGAUserID(userId);
  setGTagUserId(userId);
  setGTagUserNameProperty(username);
  setGTagUserVerifiedProperty(verified);

  ampli.loginAccount({
    action: "click_login_button",
    login_status: true,
  });

  ampli.identify(userId, {
    account_verified: verified,
    name: username,
  });
};

export const trackLoginError = () => {
  eventGA({
    name: "Login Account",
    params: {
      category: "User Account",
      action: "click_login_button",
      value: 0,
    },
  });

  ampli.loginAccount({
    action: "click_login_button",
    login_status: false,
  });
};

export const trackRegisterSuccess = (
  userId: string,
  username: string,
  verified: boolean,
  registerTo?: string
) => {
  eventGA({
    name: "Register Account",
    params: {
      category: "User Account",
      action: "click_register_button",
      value: 1,
    },
  });

  setGAUserID(userId);
  setGTagUserNameProperty(username);
  setGTagUserVerifiedProperty(verified);

  ampli.registerAccount({
    action: "click_register_button",
    register_status: true,
    register_to: registerTo,
  });

  ampli.identify(userId, {
    account_verified: verified,
    name: username,
  });
};

export const trackRegisterError = (registerTo?: string) => {
  eventGA({
    name: "Register Account",
    params: {
      category: "User Account",
      action: "click_register_button",
      value: 0,
    },
  });

  ampli.registerAccount({
    action: "click_register_button",
    register_status: false,
    register_to: registerTo,
  });
};

export const trackAccountType = (
  userId: string,
  haveSubaccount: boolean,
  accountLevel: "parent_account" | "sub_account"
) => {
  ampli.identify(userId, {
    have_sub_account: haveSubaccount,
    account_level: accountLevel,
  });
};
