import { env } from "@saas/config/shared";

import OneSignalReact from "react-onesignal";

type Action<T> = (item: T) => void;

const setExternalUserId = (userId: string) => {
  OneSignalReact.setExternalUserId(userId);
};

const initOneSignal = async () => {
  await OneSignalReact.init({
    appId: env.ONESIGNAL_APP_ID,
  });
  OneSignalReact.showSlidedownPrompt();
};

const registerUserDevice = async (
  userId: string,
  callback: (oneSignalUserId: string) => void
) => {
  await Promise.all([
    OneSignalReact.isPushNotificationsEnabled(),
    OneSignalReact.getUserId(),
    OneSignalReact.getExternalUserId(),
  ]).then(([isEnabled, oneSignalUserId, externalUserId]) => {
    const isDifferentUserId = externalUserId !== userId;
    const shouldRegister = isEnabled && oneSignalUserId && isDifferentUserId;

    // register different user on the same device
    if (shouldRegister) {
      callback(oneSignalUserId);
      setExternalUserId(userId);
    }
  });

  OneSignalReact.on("subscriptionChange", async (isSubs) => {
    if (isSubs) {
      const oneSignalUserId = await OneSignalReact.getUserId();
      // register subscribed user
      oneSignalUserId && callback(oneSignalUserId);
      setExternalUserId(userId);
    }
  });
};

const addListenerForOneSignalNotificationOpened = (
  callback: Action<Notification>
) => {
  OneSignalReact.addListenerForNotificationOpened(callback);
};

export {
  addListenerForOneSignalNotificationOpened,
  setExternalUserId,
  initOneSignal,
  registerUserDevice,
};

export default OneSignalReact;
