import {
  AlertActionType,
  AlertDispatch,
  AlertInterface,
} from "../@interface/alert/alert.interface";

const notify = (dispatch: AlertDispatch, payload: AlertInterface) => {
  dispatch({ type: AlertActionType.ADD_MESSAGE, payload });
};

const clear = (dispatch: AlertDispatch, payload: { id: string }) => {
  dispatch({ type: AlertActionType.REMOVE_MESSAGE, payload });
};

export { notify, clear };
