import { kratos } from "../..";

import { VerificationFlowState } from "@ory/client";

export type GetVerificationInput = string;

export type GetVerificationOutput = {
  state: VerificationFlowState;
  code?: number;
};

export const getVerification = async (
  flowId: GetVerificationInput
): Promise<GetVerificationOutput> => {
  const { data } = await kratos.getVerificationFlow({ id: flowId });

  return {
    state: data.state,
    code: data.ui.messages?.[0].id,
  };
};

export default getVerification;
