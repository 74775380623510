export interface Env {
  APP_NAME: string;
  APP_ENV: string;
  APP_URL: string;
  LANDING_URL: string;
  DISCOVER_URL: string;
  DASHBOARD_URL: string;
  CATALOG_URL: string;
  RESELLER_URL: string;
  POS_URL: string;
  HELP_URL: string;
  HELP_DISCOVER_URL: string;
  ACADEMY_URL: string;
  POS_PLAYSTORE_URL: string;
  GOOGLE_ANALYTICS_ID: string;
  GTM_ID: string;
  AMPLITUDE_API_KEY: string;
  SUPPORT_PHONE_NO: string;
  TOKOPEDIA_CARE_URL: string;
  ACCOUNTING_TEMPLATE_LINK: string;
  BROADCAST_TEMPLATE_LINK: string;
  SUPPORT_MARKET: string;
  API_MOCKS_ENABLED: boolean;
  ONESIGNAL_APP_ID: string;
  USETIFUL_TOKEN: string;
  WEBSOCKET_CHAT_MOCKS_ENABLED: boolean;
  WEBSOCKET_CHAT_URL: string;
  NX_PROMOTION_SERVICE_URL: string;
  NX_CHAT_SERVICE_URL: string;
  ORY_KRATOS_URL: string;
  NX_MARKETPLACE_CONNECTION_SERVICE_URL: string;
  CATALOG_BANNER_URL: string;
  CATALOG_BANNER_ACCOUNT_NUMBER: string;
  TNC_URL: string;
}

/**
 * @description App URL override for preview
 */
const DEFAULT_APP_URL = `https://${process.env["NEXT_PUBLIC_VERCEL_URL"]}`;

/**
 * @description Landing URL override for preview
 */
const PREVIEW_LANDING_URL = `https://${process.env["NEXT_PUBLIC_GIT_REF"]}-hypefast.vercel.app`;

/**
 * @description Discover URL override for preview
 */
const PREVIEW_DISCOVER_URL = `https://${process.env["NEXT_PUBLIC_GIT_REF"]}-hypefast-discover.vercel.app`;

/**
 * @description Dashboard URL override for preview
 */
const PREVIEW_DASHBOARD_URL = `https://${process.env["NEXT_PUBLIC_GIT_REF"]}-hypefast-dashboard.vercel.app`;

/**
 * @description Catalog URL override for preview
 */
const PREVIEW_CATALOG_URL = `https://${process.env["NEXT_PUBLIC_GIT_REF"]}-hypefast-catalog.vercel.app`;

/**
 * @description PoS URL override for preview
 */
const PREVIEW_POS_URL = `https://${process.env["NEXT_PUBLIC_GIT_REF"]}-hypefast-pos.vercel.app`;

export const env: Env = {
  APP_NAME: process.env["NX_APP_NAME"] ?? "App",
  APP_ENV: process.env["NX_APP_ENV"] ?? "dev",
  APP_URL: process.env["NX_APP_URL"] ?? DEFAULT_APP_URL,
  LANDING_URL: process.env["NX_LANDING_URL"] ?? PREVIEW_LANDING_URL,
  DISCOVER_URL: process.env["NX_DISCOVER_URL"] ?? PREVIEW_DISCOVER_URL,
  DASHBOARD_URL: process.env["NX_DASHBOARD_URL"] ?? PREVIEW_DASHBOARD_URL,
  CATALOG_URL: process.env["NX_CATALOG_URL"] ?? PREVIEW_CATALOG_URL,
  POS_URL: process.env["NX_POS_URL"] ?? PREVIEW_POS_URL,
  RESELLER_URL: process.env["NX_RESELLER_URL"] ?? "",
  HELP_URL: process.env["NX_HELP_URL"] ?? "",
  HELP_DISCOVER_URL: process.env["NX_HELP_DISCOVER_URL"] ?? "",
  ACADEMY_URL: process.env["NX_ACADEMY_URL"] ?? "",
  POS_PLAYSTORE_URL: process.env["NX_POS_PLAYSTORE_URL"] ?? "",
  GOOGLE_ANALYTICS_ID: process.env["NX_GOOGLE_ANALYTICS_ID"] ?? "",
  GTM_ID: process.env["NX_GOOGLE_TAG_MANAGER_ID"] ?? "",
  AMPLITUDE_API_KEY: process.env["NX_AMPLITUDE_API_KEY"] ?? "",
  SUPPORT_PHONE_NO: process.env["NX_SUPPORT_PHONE_NO"] ?? "",
  TOKOPEDIA_CARE_URL: process.env["NX_TOKOPEDIA_CARE_URL"] ?? "",
  ACCOUNTING_TEMPLATE_LINK: process.env["NX_ACCOUNTING_TEMPLATE_LINK"] ?? "",
  BROADCAST_TEMPLATE_LINK: process.env["NX_BROADCAST_TEMPLATE_LINK"] ?? "",
  SUPPORT_MARKET: process.env["NX_SUPPORT_MARKET"] ?? "",
  API_MOCKS_ENABLED: process.env["NX_PUBLIC_API_MOCKING"] === "enabled",
  ONESIGNAL_APP_ID: process.env["NX_ONESIGNAL_APP_ID"] ?? "",
  USETIFUL_TOKEN: process.env["NX_USETIFUL_TOKEN"] ?? "",
  WEBSOCKET_CHAT_URL:
    process.env["NX_WEBSOCKET_CHAT_URL"] ?? "ws://localhost.test",
  WEBSOCKET_CHAT_MOCKS_ENABLED:
    process.env["NX_WEBSOCKET_CHAT_MOCKS_ENABLED"] === "enabled",
  NX_PROMOTION_SERVICE_URL: process.env["NX_PROMOTION_SERVICE_URL"] ?? "",
  NX_CHAT_SERVICE_URL: process.env["NX_CHAT_SERVICE_URL"] ?? "",
  ORY_KRATOS_URL: process.env["ORY_KRATOS_URL"] ?? "",
  NX_MARKETPLACE_CONNECTION_SERVICE_URL:
    process.env["NX_MARKETPLACE_CONNECTION_SERVICE_URL"] ?? "",
  CATALOG_BANNER_URL: process.env["NX_CATALOG_BANNER_URL"] ?? "",
  CATALOG_BANNER_ACCOUNT_NUMBER:
    process.env["NX_CATALOG_BANNER_ACCOUNT_NUMBER"] ?? "",
  TNC_URL: process.env["NX_TNC_URL"] ?? "",
};

export default env;
