import { HTMLAttributes } from "react";

import { classNames } from "@saas/shared/utils";

import { useNProgress } from "@tanem/react-nprogress";

export interface ProgressBarProps extends HTMLAttributes<HTMLDivElement> {
  isAnimating: boolean;
}

export const ProgressBar = ({
  isAnimating,
  className,
  ...props
}: ProgressBarProps) => {
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating,
  });

  return (
    <div
      className={classNames(
        "bg-green-G100 pointer-events-none fixed inset-x-0 top-0 z-[8] h-2 w-full",
        className
      )}
      style={{
        opacity: isFinished ? 0 : 1,
        transition: `opacity ${animationDuration}ms linear`,
      }}
      {...props}
    >
      <div
        className={
          "bg-green-G300 absolute top-0 z-[1] h-2 rounded-r  will-change-auto"
        }
        style={{
          width: `${progress * 100}%`,
          transition: `all ${animationDuration}ms linear`,
        }}
      />
    </div>
  );
};

export default ProgressBar;
