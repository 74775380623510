export enum PathKeysEnum {
  CRM = "crm",
  INVENTORY = "inventory",
  STORE_PROMO = "store",
  SALESPERSON = "salesperson",
  TERM_OF_PAYMENT = "term-of-payment",
  SHOP_MONITORING = "shop-monitoring",
  LOYALTY_POINT = "loyalty-point",
  CUSTOMER_TIERS = "customer-tiers",
  INVENTORY_BUNDLING = "inventory/bundling",
  VOUCHERS_LIST = "voucher",
  VOUCHER_DETAIL = "voucher/:id/detail",
}

export default PathKeysEnum;
