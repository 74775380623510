import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";

import { expiredNotificationRoutesBlacklist } from "@saas/config/dashboard";
import { env } from "@saas/config/shared";
import { useExpiredMarketplaceConnectionsQuery } from "@saas/marketplace-connection/data-access";
import { trackConnection } from "@saas/marketplace-connection/utils";
import { NotificationBar } from "@saas/shared/ui";

import { capitalize } from "lodash-es";
import { useMediaQuery } from "usehooks-ts";

const testid = "notification__expired__marketplace";

export const AuthorizationExpiredNotification = () => {
  const isStandaloneView = useMediaQuery("(display-mode: standalone)");
  const isPOS = isStandaloneView && env.APP_URL.includes(env.POS_URL);
  const router = useRouter();
  const [isClosed, setIsClosed] = useState(false);

  const { data: expired = [] } = useExpiredMarketplaceConnectionsQuery();

  const { showNotification, expiredChannel } = useMemo(() => {
    const path = "/" + router.asPath.split("/")[1];
    const blacklisted = expiredNotificationRoutesBlacklist.some(
      (route) => route === path
    );

    const expiredChannels = expired
      .map((channel) => `${capitalize(channel.channel)} ${channel.shopName}`)
      .join(", ");

    return {
      showNotification: !blacklisted && expired.length > 0,
      expiredChannel: expiredChannels,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expired, router.asPath]);

  useEffect(() => {
    expired.forEach(({ channel }) => trackConnection(channel, false, true));
  }, [expired]);

  return showNotification && !isClosed ? (
    <NotificationBar
      title={"Koneksi Toko Kedaluwarsa"}
      message={
        <>
          Koneksi{" "}
          <span
            className={"font-bold"}
            data-testid={"notification__expired__marketplace__channel"}
          >
            {expiredChannel}
          </span>{" "}
          kedaluwarsa! Mohon periksa kembali toko online-mu.
        </>
      }
      variant={"warning"}
      testid={testid}
      action={{
        label: "LIHAT KONEKSI TOKO ONLINE",
        onClick: () =>
          isPOS
            ? navigator.share({
                url: env.DASHBOARD_URL + "/account/marketplace-connections",
              })
            : router.push("/account/marketplace-connections"),
        testid: testid + "__link",
      }}
      onClose={() => setIsClosed(true)}
    />
  ) : null;
};

export default AuthorizationExpiredNotification;
