import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  invalidateLogoutUrlQuery,
  invalidateUseSessionQuery,
  triggerLogout,
  useLogoutUrlQuery,
} from "@saas/account/data-access";
import { setGTagUserId } from "@saas/account/utils";
import { env } from "@saas/config/shared";
import { eventGA, mediaQuery, resetAmplitude } from "@saas/core";
import { useAlert } from "@saas/shared/feature";
import { GeneralIcon, LeftIcon, LogoutIcon, UserIcon } from "@saas/shared/icon";
import {
  DropdownItemProps,
  DropdownMenu,
  MenuItem,
  SlideOver,
  Typography,
} from "@saas/shared/ui";
import { classNames, notify } from "@saas/shared/utils";

import { Avatar, useSessionProfile } from "../..";

import { useIsomorphicLayoutEffect, useWindowSize } from "usehooks-ts";

export const DISCLAIMER_COOKIE = "isAgreeDisclaimer";

export const AccountMenu = () => {
  const { reload } = useRouter();
  const { profile } = useSessionProfile();
  const { data: logoutToken, error: logoutError } = useLogoutUrlQuery({
    enabled: profile?.isActive ?? false,
  });
  const [, dispatch] = useAlert();
  const { width } = useWindowSize();

  const [openSlideOver, setOpenSlideOver] = useState(false);

  const heading = "Akunku";

  const handleLogout = useCallback(async () => {
    if (logoutToken)
      try {
        await triggerLogout({
          token: logoutToken,
        });

        await invalidateUseSessionQuery();

        setGTagUserId(null);

        eventGA({
          name: "Logout Account",
          params: {
            category: "",
            action: "click_logout_button",
          },
        });

        resetAmplitude();

        reload();
      } catch (error) {
        await invalidateLogoutUrlQuery();

        notify(dispatch, {
          content: "Gagal keluar, silakan coba lagi.",
          variant: "negative",
        });
      }
  }, [dispatch, logoutToken, reload]);

  const isDiscoverApp = env.APP_URL === env.DISCOVER_URL;

  const withMissionBanner: DropdownItemProps[] = useMemo(
    () => [
      {
        as: "button",
        label: "Keluar",
        icon: LogoutIcon,
        divider: true,
        testid: "home__dropdown-menu__keluar",
        onClick: handleLogout,
      },
    ],
    [handleLogout]
  );

  const menuItems: DropdownItemProps[] = useMemo(
    () =>
      isDiscoverApp
        ? [
            {
              as: "a",
              label: "Edit Profil",
              icon: UserIcon,
              href: env.DASHBOARD_URL + "/account/profile",
              testid: "home__dropdown-menu__edit-profile",
            },
            ...withMissionBanner,
          ]
        : [
            {
              as: "a",
              label: "Ke Landing Page",
              icon: GeneralIcon,
              href: env.LANDING_URL + "?view",
              testid: "home__dropdown-menu__landing-page",
            },
            {
              as: "a",
              label: "Pengaturan Akun",
              icon: UserIcon,
              href: env.DASHBOARD_URL + "/account/profile",
              testid: "home__dropdown-menu__edit-profile",
            },
            ...withMissionBanner,
          ],
    [isDiscoverApp, withMissionBanner]
  );

  useEffect(() => {
    if (logoutError) {
      invalidateUseSessionQuery();
    }
  }, [logoutError]);

  useIsomorphicLayoutEffect(() => {
    if (width < mediaQuery.md) setOpenSlideOver(false);
  }, [width]);

  return (
    <>
      {/* Mobile */}
      <div className={"block md:hidden"}>
        <button
          className={"rounded focus:outline-none"}
          data-testid={"home__menu__my-account"}
          onClick={() => setOpenSlideOver(true)}
        >
          <Avatar />
        </button>
        <SlideOver
          origin={"right"}
          open={openSlideOver}
          onClose={() => setOpenSlideOver(false)}
        >
          <div className={"flex items-center gap-4 py-4 px-5"}>
            <button
              className={
                "text-le hover:text-he rounded focus:outline-none focus:ring"
              }
              onClick={() => setOpenSlideOver(false)}
            >
              <LeftIcon className={"h-6 w-6"} />
            </button>

            <Typography
              as={"h2"}
              type={"title-large"}
              className={"text-he uppercase"}
            >
              {heading}
            </Typography>
          </div>
          <div className={"flex flex-col"}>
            {menuItems.map((item, index) => {
              if (item.as === "button") {
                return (
                  <button
                    key={index}
                    onClick={() => {
                      item.onClick();
                      setOpenSlideOver(false);
                    }}
                    className={classNames(
                      "group focus:outline-none",
                      item.divider && "border-neutral-N200 border-t"
                    )}
                    data-testid={item.testid}
                  >
                    <MenuItem className={"py-4 px-5 md:mx-0"} icon={item.icon}>
                      {item.label}
                    </MenuItem>
                  </button>
                );
              } else if (item.as === "a") {
                return (
                  <Link href={item.href} key={index}>
                    <a
                      className={classNames(
                        "group focus:outline-none",
                        item.divider && "border-neutral-N200 border-t"
                      )}
                      data-testid={item.testid}
                      onClick={() => setOpenSlideOver(false)}
                    >
                      <MenuItem
                        className={"py-4 px-5 md:mx-0"}
                        icon={item.icon}
                      >
                        {item.label}
                      </MenuItem>
                    </a>
                  </Link>
                );
              } else {
                return null;
              }
            })}
          </div>
        </SlideOver>
      </div>

      {/* Desktop */}
      <div className={"hidden md:block"}>
        <DropdownMenu
          title={heading}
          items={menuItems}
          origin={"right"}
          testid={"home__menu__my-account"}
        >
          {(open) => <Avatar active={open} />}
        </DropdownMenu>
      </div>
    </>
  );
};

export default AccountMenu;
