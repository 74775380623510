import { queryClient } from "@saas/core";
import { chatQueryKey } from "@saas/crm/data";
import { DeliveryStatusEnum, MessageStatusEnum } from "@saas/crm/utils";

import { LoadChatRoomMessagesHandlerInput } from "../load-chat-room-messages-handler/load-chat-room-messages-handler";

interface OpenNotificationParams {
  content: string;
  variant: "negative" | "positive";
}

export type SendMessageHandlerInput = {
  notificationProvider: {
    open: (params: OpenNotificationParams) => void;
  };
  response: {
    data?: {
      id: number;
      deliveryStatus: DeliveryStatusEnum;
      messageStatus: MessageStatusEnum;
    };
    errorCode?: number;
    message?: string;
    requestId: string;
    statusCode?: number;
  };
};

export type SendMessageHandlerOutput = void;

export const sendMessageHandler = ({
  notificationProvider,
  response,
}: SendMessageHandlerInput): SendMessageHandlerOutput => {
  const requestId = response.requestId;
  const [chatRoomId, generatedId] = requestId.split(",");

  const id = response.data?.id;
  const messageStatus = response.data?.messageStatus;
  const deliveryStatus = response.data?.deliveryStatus;
  const isDeliveryFailed = deliveryStatus === DeliveryStatusEnum.FAILED;

  if (isDeliveryFailed && messageStatus === MessageStatusEnum.NORMAL) {
    notificationProvider.open({
      variant: "negative",
      content:
        "Pesan kamu gagal terkirim. Mohon cek koneksi internet atau refresh halaman ini, ya!",
    });
  }

  if (isDeliveryFailed && messageStatus === MessageStatusEnum.BLACKLISTED) {
    notificationProvider.open({
      variant: "negative",
      content:
        "Pesan kamu tidak dapat dikirimkan karena termasuk blacklist keywords.",
    });
  }

  if (isDeliveryFailed && messageStatus === MessageStatusEnum.NO_ACCESS) {
    notificationProvider.open({
      variant: "negative",
      content:
        "Pesan kamu gagal terkirim. Pastikan kamu punya akses balas chat di pengaturan seller center.",
    });
  }

  const prevChatsResponse = queryClient.getQueryData<
    LoadChatRoomMessagesHandlerInput["response"]
  >(chatQueryKey.chatRoom([], Number(chatRoomId)));
  const prevChats = prevChatsResponse?.data ?? [];
  const activeIndex = prevChats.findIndex((chat) => chat.id === generatedId);

  if (activeIndex !== -1) {
    const updatedChats = prevChats.map((chat, index) =>
      index === activeIndex
        ? {
            ...chat,
            id: id,
            message: {
              ...chat.message,
              deliveredStatus: deliveryStatus,
            },
          }
        : chat
    );

    queryClient.setQueryData(chatQueryKey.chatRoom([], Number(chatRoomId)), {
      data: updatedChats,
    });
  }
};

export default sendMessageHandler;
