import Link from "next/link";
import { ReactNode } from "react";

import { env } from "@saas/config/shared";
import {
  AppNotificationCategoryEnum,
  AppNotificationInterface,
} from "@saas/notification/utils";
import {
  FinancialReportIcon,
  InventoryIcon,
  MailIcon,
  MarketplaceIcon,
  OrdersIcon,
  PromotionIcon,
} from "@saas/shared/icon";
import { Typography } from "@saas/shared/ui";
import { classNames, formatRelativeDatetime } from "@saas/shared/utils";

import { useMediaQuery } from "usehooks-ts";

export interface NotificationCardProps {
  notification: AppNotificationInterface;
  onRead: () => void;
}

const testid = "inapp-notification-list";

export const NotificationCard = ({
  notification,
  onRead: handleRead,
}: NotificationCardProps) => {
  const NotificationIcon = getNotificationIcon(notification.category.id);

  return (
    <NotificationCardWrapper notification={notification} onRead={handleRead}>
      <div
        className={
          "bg-neutral-N100 text-neutral-N600 rounded-full p-2 align-top"
        }
      >
        <NotificationIcon data-testid={`${testid}__text__message-icon`} />
      </div>
      <div className={"flex flex-col gap-1"}>
        <Typography
          type={"body-b2"}
          className={"text-he !font-semibold"}
          data-testid={`${testid}__text__message-title`}
        >
          {notification.title}
        </Typography>
        <Typography
          type={"body-b2"}
          className={"text-he"}
          data-testid={`${testid}__text__message-body`}
        >
          {notification.body}
        </Typography>
        <Typography
          type={"body-b3"}
          className={"text-le"}
          data-testid={`${testid}__text__message-time`}
        >
          {formatRelativeDatetime(notification.createdAt)}
        </Typography>
      </div>
    </NotificationCardWrapper>
  );
};

const getNotificationIcon = (category: AppNotificationCategoryEnum) => {
  switch (category) {
    case AppNotificationCategoryEnum.CONNECTION:
      return MarketplaceIcon;
    case AppNotificationCategoryEnum.CUSTOMER:
      return MailIcon;
    case AppNotificationCategoryEnum.INVENTORY:
      return InventoryIcon;
    case AppNotificationCategoryEnum.ORDER:
      return OrdersIcon;
    case AppNotificationCategoryEnum.PROMOTION:
      return PromotionIcon;
    case AppNotificationCategoryEnum.ACCOUNTING:
      return FinancialReportIcon;
  }
};

export default NotificationCard;

interface NotificationCardWrapperProps {
  children: ReactNode;
  notification: AppNotificationInterface;
  onRead: () => void;
}

const NotificationCardWrapper = ({
  children,
  notification,
  onRead: handleRead,
}: NotificationCardWrapperProps) => {
  const isStandaloneView = useMediaQuery("(display-mode: standalone)");
  const isPOS = isStandaloneView && env.APP_URL.includes(env.POS_URL);
  const isNotificationOrder =
    notification.category.id === AppNotificationCategoryEnum.ORDER;
  const isPOSPWAAndNotificationOrder = isPOS && isNotificationOrder;
  const notificationUrl = new URL(notification.url);

  const handleClickNotification = () => {
    navigator.share({ url: notification.url });

    handleRead();
  };

  return isPOS && !isNotificationOrder ? (
    <button
      className={classNames(
        "flex cursor-pointer items-start gap-2 p-4",
        notification.isRead ? "bg-white" : "bg-blue-B100"
      )}
      onClick={handleClickNotification}
    >
      {children}
    </button>
  ) : (
    <Link
      href={
        isPOSPWAAndNotificationOrder
          ? "/#" + notificationUrl.search
          : notification.url
      }
      passHref={true}
    >
      <a
        onClick={handleRead}
        data-testid={`${testid}__text__message`}
        className={classNames(
          "flex cursor-pointer items-start gap-2 p-4",
          notification.isRead ? "bg-white" : "bg-blue-B100"
        )}
      >
        {children}
      </a>
    </Link>
  );
};
