import { FetchError, queryClient } from "@saas/core";
import {
  InfiniteQueryOptionsInterface,
  QueryOptionsInterface,
} from "@saas/shared/utils";

import { GetNotificationsInput, GetNotificationsOutput } from "../..";
import getNotifications from "../../get-notifications/get-notifications";
import { getNotificationsQueryKey } from "../../get-notifications/get-notifications.query-key";

import { Updater, useInfiniteQuery, useQuery } from "@tanstack/react-query";

export type UseGetNotificationsQueryData = GetNotificationsOutput;

export type UseGetNotificationsQueryParams = GetNotificationsInput;

export const useGetNotificationsQuery = <
  DerivedQueryData = UseGetNotificationsQueryData
>(
  params?: UseGetNotificationsQueryParams,
  options?: QueryOptionsInterface<
    UseGetNotificationsQueryData,
    DerivedQueryData
  >
) => {
  return useQuery<UseGetNotificationsQueryData, FetchError, DerivedQueryData>(
    getNotificationsQueryKey.all(params),
    () => getNotifications(params),
    {
      ...options,
      select: options?.select,
      enabled: options?.enabled,
    }
  );
};

export const useGetNotificationsInfiniteQuery = <
  DerivedQueryData = UseGetNotificationsQueryData
>(
  params: Partial<UseGetNotificationsQueryParams>,
  options?: InfiniteQueryOptionsInterface<
    UseGetNotificationsQueryData,
    DerivedQueryData
  >
) => {
  return useInfiniteQuery<
    UseGetNotificationsQueryData,
    FetchError,
    DerivedQueryData
  >(
    getNotificationsQueryKey.all(params),
    ({ pageParam = 1 }) => getNotifications({ ...params, page: pageParam }),
    {
      ...options,
      getNextPageParam: (page) =>
        page.pagination
          ? page.pagination?.currentPage < page.pagination?.totalPage &&
            page.pagination?.currentPage + 1
          : null,
      select: options?.select,
      onSuccess: options?.onSuccess,
    }
  );
};

export const setUseGetNotificationsQuery = (
  params?: UseGetNotificationsQueryParams,
  updater?: Updater<
    UseGetNotificationsQueryData | undefined,
    UseGetNotificationsQueryData
  >
): void => {
  queryClient.setQueryData<UseGetNotificationsQueryData>(
    getNotificationsQueryKey.all(params),
    updater
  );
};

export const prefetchUseGetNotificationsQuery = async (
  params: UseGetNotificationsQueryParams,
  cookie: string
): Promise<UseGetNotificationsQueryData> => {
  const data = await getNotifications(params, { headers: { cookie } });

  await queryClient.prefetchQuery(
    getNotificationsQueryKey.all(params),
    () => data
  );

  return data;
};

export const invalidateUseGetNotificationsQuery = () => {
  return queryClient.invalidateQueries(getNotificationsQueryKey.all());
};

export default useGetNotificationsQuery;
