import { SubaccountModel } from "@saas/account/utils";
import { fetchJson, ResponseInterface } from "@saas/core";
import { cleanPayload, mapToCamelCase } from "@saas/shared/utils";

import { stringify } from "qs";

export interface GetSubaccountsResponse extends ResponseInterface {
  data: ReadonlyArray<SubaccountModel>;
}

export type GetSubaccountsInput = {
  page: number;
  perPage: number;
};

export type GetSubaccountsOutput = ReadonlyArray<SubaccountModel>;

export const getSubaccounts = async (
  input: GetSubaccountsInput,
  options?: RequestInit
): Promise<GetSubaccountsOutput> => {
  const queryString = stringify(
    cleanPayload({
      page: input.page,
      per_page: input.perPage,
    }),
    {
      arrayFormat: "comma",
    }
  );
  const response = await fetchJson<GetSubaccountsResponse>(
    "/api/subaccount/user-accounts/subaccounts?" + queryString,
    options
  );

  return mapToCamelCase<GetSubaccountsOutput>(response.data);
};

export default getSubaccounts;
