import { kratos } from "../..";

export type TriggerLogoutInput = {
  token: string;
};

export type TriggerLogoutOutput = Promise<void>;

export const triggerLogout = async ({
  token,
}: TriggerLogoutInput): TriggerLogoutOutput => {
  await kratos.updateLogoutFlow({ token: token });
};

export default triggerLogout;
