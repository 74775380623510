import Router from "next/router";
import { Component, ErrorInfo, ReactNode } from "react";

import { NotificationBar } from "../..";

import * as Sentry from "@sentry/react";

export interface ErrorBoundaryProps {
  children?: ReactNode;
}

export interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  public override state: ErrorBoundaryState = {
    hasError: false,
  };

  constructor(props: ErrorBoundaryProps | Readonly<ErrorBoundaryProps>) {
    super(props);

    this.state = { hasError: false };
  }

  public override componentDidMount(): void {
    Router.events.on("routeChangeComplete", this.handleRouteChange);
  }

  public override componentWillUnmount(): void {
    Router.events.off("routeChangeComplete", this.handleRouteChange);
  }

  public static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  public override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (process.env["NODE_ENV"] !== "development") {
      Sentry.withScope((scope) => {
        scope.setExtra("componentStack", errorInfo);
        Sentry.captureException(error);
      });
    }
  }

  handleRouteChange = () => {
    this.setState({ hasError: false });
  };

  public override render() {
    if (this.state.hasError) {
      return (
        <NotificationBar
          variant={"error"}
          title={"Sebuah kesalahan telah terjadi."}
          message={
            "Silakan coba lagi nanti. Jika masih terjadi kesalahan, hubungi pengembang."
          }
          onClose={() => this.setState({ hasError: false })}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
