import { ChannelEnum } from "@saas/shared/utils";

export type CrmChannelDisplayConfigInput = ChannelEnum | "whatsapp"; // temporary until whatsapp counted as channel

export type DisplayConfig = {
  // feel free to add config here.
  isCounterBubbleVisible: boolean;
  chatRoomBackground: string;
  isChatMediaInputFormEnabled: boolean;
};

export type CrmChannelDisplayConfigOutput = DisplayConfig;

export const crmChannelDisplayConfig = (
  input: CrmChannelDisplayConfigInput
): CrmChannelDisplayConfigOutput => {
  const channelConfig = {
    [ChannelEnum.TOKOPEDIA]: {
      isCounterBubbleVisible: false,
      chatRoomBackground: "bg-[url('/images/chat-watermark-tokopedia.svg')]",
      isChatMediaInputFormEnabled: false,
    },
    [ChannelEnum.SHOPEE]: {
      isCounterBubbleVisible: true,
      chatRoomBackground: "bg-[url('/images/chat-watermark-shopee.svg')]",
      isChatMediaInputFormEnabled: true,
    },
    [ChannelEnum.TIKTOK]: {
      isCounterBubbleVisible: true,
      chatRoomBackground: "bg-[url(''/images/chat-watermark-shopee.svg')]", //tbd
      isChatMediaInputFormEnabled: true,
    },
    [ChannelEnum.MARKET]: {
      isCounterBubbleVisible: true,
      chatRoomBackground: "bg-[url('/images/chat-watermark-shopee.svg')]", //tbd
      isChatMediaInputFormEnabled: true,
    },
    [ChannelEnum.CATALOG]: {
      isCounterBubbleVisible: false,
      chatRoomBackground: "bg-[url('/images/chat-watermark-shopee.svg')]", //tbd
      isChatMediaInputFormEnabled: false,
    },
    [ChannelEnum.SHOPIFY]: {
      isCounterBubbleVisible: true,
      chatRoomBackground: "bg-[url('/images/chat-watermark-shopee.svg')]", //tbd
      isChatMediaInputFormEnabled: true,
    },
    [ChannelEnum.OFFLINE]: {
      isCounterBubbleVisible: false,
      chatRoomBackground: "bg-[url('/images/chat-watermark-shopee.svg')]", //tbd
      isChatMediaInputFormEnabled: false,
    },
    whatsapp: {
      isCounterBubbleVisible: true,
      chatRoomBackground: "bg-[url('/images/chat-watermark-shopee.svg')]", //tbd
      isChatMediaInputFormEnabled: true,
    }, // temporary until whatsapp counted as channel
  };

  return channelConfig[input];
};

export default crmChannelDisplayConfig;
