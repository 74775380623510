export enum ChannelEnum {
  TOKOPEDIA = "tokopedia",
  SHOPEE = "shopee",
  TIKTOK = "tiktok",
  MARKET = "melaka_market",
  CATALOG = "melaka_catalog",
  SHOPIFY = "shopify",
  OFFLINE = "offline",
}

export default ChannelEnum;
