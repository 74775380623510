import Link from "next/link";
import { useRouter } from "next/router";
import { createRef, Fragment, useMemo } from "react";

import { AccessesKeyEnum } from "@saas/account/utils";
import { env } from "@saas/config/shared";
import { mediaQuery } from "@saas/core";
import { NavigationInterface } from "@saas/layout/utils";
import { ChevronDownIcon } from "@saas/shared/icon";
import {
  CounterBubble,
  Divider,
  Logo,
  MenuItem,
  NewIndicator,
  ScheduleDemoButton,
  Typography,
} from "@saas/shared/ui";
import { classNames, NewMenuEnum, useNewIndicator } from "@saas/shared/utils";

import { Disclosure } from "@headlessui/react";
import { useWindowSize } from "usehooks-ts";

export interface AsideNavigationProps {
  navigation: ReadonlyArray<NavigationInterface>;
  onNavigate?: () => void;
  showScheduleDemo?: boolean;
  totalUnreadChatRoom?: number;
  accesses?: AccessesKeyEnum[];
}

export const AsideNavigation = ({
  navigation,
  totalUnreadChatRoom,
  showScheduleDemo,
  onNavigate: handleNavigate,
  accesses,
}: AsideNavigationProps) => {
  const { asPath } = useRouter();

  const isCurrentChild = (child?: NavigationInterface) =>
    asPath.includes(child?.href || "");

  const { showIndicator: showNewIndicator } = useNewIndicator();

  const { width } = useWindowSize();
  const isDesktop = width >= mediaQuery.md;

  const newBadge = (
    <span>
      {isDesktop ? (
        <NewIndicator
          size={"big"}
          showLabel={true}
          testid={"journal__icon__indicator-menu"}
        />
      ) : (
        <span
          data-testid={"journal__icon__indicator-menu"}
          className={
            "bg-orange-O600 relative flex rounded-3xl py-[2px] px-[7px]"
          }
        >
          <Typography className={"text-white"} type={"label-medium"}>
            Baru
          </Typography>
        </span>
      )}
    </span>
  );

  const refs = useMemo(() => {
    return (
      navigation.map(() => {
        return createRef<HTMLButtonElement>();
      }) ?? []
    );
  }, [navigation]);

  const activeParentId = useMemo(() => {
    let id = undefined;

    navigation.forEach((parent) => {
      if (asPath.includes(parent.href)) {
        id = parent.testid;
      }

      (parent?.children || []).forEach((child) => {
        if (asPath.includes(child.href)) {
          id = parent.testid;
        }
      });
    });

    return id;
  }, [navigation, asPath]);

  const handleOpenDisclosure = (
    currParent: NavigationInterface,
    childData?: NavigationInterface
  ) => {
    const id = currParent.testid;
    const hasChildren = !!currParent.children;

    const otherRefs = refs.filter((ref) => {
      const dataId = ref.current?.getAttribute("data-id");
      return dataId !== id;
    });

    const activeRef = refs.find((ref) => {
      const dataId = ref.current?.getAttribute("data-id");
      return dataId === activeParentId;
    });

    const isActiveRefOpen =
      activeRef?.current?.getAttribute("data-open") === "true";
    const activeRefId = activeRef?.current?.getAttribute("data-id");

    if (!childData && currParent.target) {
      return;
    } else if (!childData && !hasChildren) {
      refs.forEach((ref) => {
        const isOpen = ref.current?.getAttribute("data-open") === "true";
        if (isOpen) {
          ref.current?.click();
        }
      });

      return;
    }

    if (!!childData && isActiveRefOpen && activeRefId !== id) {
      activeRef.current?.click();
    } else if (!childData && isActiveRefOpen && activeRefId === id) {
      activeRef.current?.click();
    } else if (!childData && isActiveRefOpen && activeRefId !== id) {
      otherRefs.forEach((ref) => {
        const isOpen = ref.current?.getAttribute("data-open") === "true";
        const refId = ref.current?.getAttribute("data-id");
        if (isOpen && refId !== activeParentId) {
          ref.current?.click();
        }
      });
    } else {
      otherRefs.forEach((ref) => {
        const isOpen = ref.current?.getAttribute("data-open") === "true";
        if (isOpen) {
          ref.current?.click();
        }
      });
    }
  };

  const withActiveNavigation = useMemo(() => {
    return navigation.map((item) => {
      const [, navigationPath] = item.href.split("/");
      const pathWithoutQueryString = asPath.split("?")[0];
      const pathComponents = pathWithoutQueryString.split("/");
      const basePath = pathComponents.slice(0, 2).join("/");

      const isExternal = item.href.startsWith("http");
      const isParentActive = isExternal
        ? false
        : navigationPath
        ? basePath.substring(1) === navigationPath
        : basePath === "/";
      let isChildActive = false;

      if (item.children?.length) {
        item.children = item.children.map((child) => {
          if (isCurrentChild(child)) {
            isChildActive = true;
          }

          // check if access from navigation is match with accesses props

          if (accesses?.length && accesses && child.accesses) {
            const isAccessMatch = accesses.some((access) =>
              child.accesses?.includes(access)
            );

            if (!isAccessMatch) {
              return {
                ...child,
                enabled: false,
              };
            }
          }

          return {
            ...child,
            isActive: isCurrentChild(child),
          };
        });
      }

      // check if access from navigation is match with accesses props
      if (accesses?.length && accesses && item.accesses) {
        const isAccessMatch = accesses.some((access) =>
          item.accesses?.includes(access)
        );

        if (!isAccessMatch) {
          return {
            ...item,
            enabled: false,
          };
        }
      }

      return {
        ...item,
        isActive: isParentActive || isChildActive,
      };
    });
  }, [asPath, accesses, isCurrentChild, navigation]);

  return (
    <aside
      className={
        "bg-neutral-N0 border-neutral-N200 z-10 min-h-screen border-r md:fixed md:inset-y-0 md:flex md:w-[248px] md:flex-col"
      }
    >
      <div className={"flex flex-grow flex-col overflow-y-auto"}>
        <div className={"m-6 flex flex-shrink-0 items-center"}>
          <Logo />
        </div>
        <nav className={"mr-2 flex flex-1 flex-col gap-1"}>
          {showScheduleDemo ? (
            <div className={"grid w-full pb-3 pl-6 md:hidden"}>
              <ScheduleDemoButton
                phoneNumber={env.SUPPORT_PHONE_NO}
                variant={"tertiary"}
              />
            </div>
          ) : null}

          {withActiveNavigation.map((item, index) => {
            const isDefaultOpen = item.isActive;
            const hasSubmenu =
              item.children?.length &&
              item.children?.some((child) => {
                return child?.enabled;
              });

            return item.enabled ? (
              hasSubmenu ? (
                <Disclosure key={item.label} defaultOpen={isDefaultOpen}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={"overflow-hidden rounded-r-xl text-left"}
                        ref={refs[index]}
                        data-id={item.testid}
                        data-open={open}
                        onClick={() => handleOpenDisclosure(item)}
                        data-testid={"aside-navigation__button__menu"}
                      >
                        <MenuItem
                          className={
                            "block w-full gap-[10px] py-2 pr-2 pl-6 md:flex"
                          }
                          icon={item.icon}
                          active={open}
                          hasSubmenu={!!item.children?.length}
                        >
                          <span
                            className={
                              "flex w-full items-center justify-between gap-2"
                            }
                            data-testid={item.testid}
                          >
                            {item.label}

                            <ChevronDownIcon
                              className={classNames(
                                "text-le transition",
                                open ? "rotate-180" : "rotate-0"
                              )}
                            />
                          </span>
                        </MenuItem>
                      </Disclosure.Button>
                      <Disclosure.Panel className={"flex flex-col gap-1"}>
                        {item.children?.map((child) => {
                          return child.enabled ? (
                            <Link href={child.href} key={child.label}>
                              <a
                                className={
                                  "group block w-full cursor-pointer overflow-hidden rounded-r-xl"
                                }
                                target={child.target}
                                onClick={handleNavigate}
                                data-testid={child.testid}
                                data-id={child.testid}
                              >
                                <button
                                  className={"w-full"}
                                  onClick={() =>
                                    handleOpenDisclosure(item, child)
                                  }
                                >
                                  <span
                                    className={classNames(
                                      "flex items-center gap-2 rounded py-3 pr-2 pl-[56px]",
                                      isCurrentChild(child)
                                        ? "bg-neutral-N100"
                                        : "group-hover:bg-neutral-N100 group-focus:bg-neutral-N100 bg-white"
                                    )}
                                  >
                                    <Typography
                                      type={
                                        isCurrentChild(child)
                                          ? "body-b2-semibold"
                                          : "body-b2"
                                      }
                                      className={classNames(
                                        "text-left",
                                        isCurrentChild(child)
                                          ? "text-button"
                                          : "text-he"
                                      )}
                                    >
                                      {child.label}
                                    </Typography>

                                    {child.isNew &&
                                    showNewIndicator[
                                      child.keyIndicator as NewMenuEnum
                                    ]
                                      ? newBadge
                                      : null}
                                  </span>
                                </button>
                              </a>
                            </Link>
                          ) : null;
                        })}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ) : (
                <Fragment key={item.label}>
                  {item.gap ? (
                    <div className={"px-6"}>
                      <Divider orientation={"horizontal"} />
                    </div>
                  ) : null}

                  <Link href={item.href}>
                    <a
                      className={classNames(
                        "group block w-full cursor-pointer overflow-hidden rounded-r-xl",
                        item.sticky && "absolute bottom-0 z-10",
                        item.className
                      )}
                      target={item.target}
                      data-testid={item.testid}
                      data-id={item.testid}
                      onClick={handleNavigate}
                    >
                      <button
                        className={"w-full"}
                        onClick={() => handleOpenDisclosure(item)}
                      >
                        <MenuItem
                          className={"gap-[10px] py-2 pr-2 pl-6"}
                          icon={item.icon}
                          active={item.isActive}
                          hasSubmenu={!!hasSubmenu}
                        >
                          <span
                            className={"inline-flex w-full items-center gap-2"}
                          >
                            {item.label}
                            {item.isNew &&
                            showNewIndicator[item.keyIndicator as NewMenuEnum]
                              ? newBadge
                              : null}

                            {item.keyIndicator === NewMenuEnum.CRM &&
                            totalUnreadChatRoom ? (
                              <CounterBubble
                                counter={totalUnreadChatRoom}
                                className={"ml-auto"}
                                testid={`${item.testid}__icon__unread-chat-room`}
                              />
                            ) : null}
                          </span>
                        </MenuItem>
                      </button>
                    </a>
                  </Link>
                </Fragment>
              )
            ) : null;
          })}
        </nav>
      </div>
    </aside>
  );
};

export default AsideNavigation;
