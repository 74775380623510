import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  invalidateUseSessionQuery,
  useSessionCookieValueQuery,
} from "@saas/account/data-access";
import { usePermissions, useSessionProfile } from "@saas/account/feature";
import { env } from "@saas/config/shared";
import { queryClient, useWebSocket } from "@saas/core";
import { useFlagStatus } from "@saas/flags/feature";
import { useConnectedChatMarketplaceConnectionsQuery } from "@saas/marketplace-connection/feature";
import { useAlert } from "@saas/shared/feature";
import {
  cleanPayload,
  mapToCamelCase,
  notify,
  PaginationInterface,
} from "@saas/shared/utils";

import loadChatRoomMessagesHandler from "../../load-chat-room-messages-handler/load-chat-room-messages-handler";
import loadChatRoomsHandler from "../../load-chat-rooms-handler/load-chat-rooms-handler";
import markAsReadHandler from "../../mark-as-read-handler/mark-as-read-handler";
import receiveMessageHandler from "../../receive-message-handler/receive-message-handler";
import sendMessageHandler from "../../send-message-handler/send-message-handler";
import syncChatInfoHandler from "../sync-chat-info-handler/sync-chat-info-handler";

import { v4 } from "uuid";

type RequestIds = {
  [key: string]: any;
};

type Status = "connecting" | "connected" | "stopped";

const WebSocketChatConnectionContext = createContext<{
  canSendMessage: boolean;
  status: Status;
  chatRoomsMessagePagination: ChatRoomsMessagePaginationInterface;
  setChatRoomsMessagePagination: Dispatch<
    SetStateAction<ChatRoomsMessagePaginationInterface>
  >;
  sendMessage: <T, U>(
    message: Record<string, T>,
    requestPayload?: Record<string, U>
  ) => void;
  handleChatSyncAfterRead: (chatRoomId: number) => void;
}>({
  status: "connecting",
  canSendMessage: false,
  sendMessage: () => null,
  chatRoomsMessagePagination: undefined,
  setChatRoomsMessagePagination: () => undefined,
  handleChatSyncAfterRead: (chatRoomId: number) => undefined,
});

export interface WebSocketChatConnectionProps {
  children: ReactNode;
}

export type ChatRoomsMessagePaginationInterface =
  | Record<string, PaginationInterface | undefined>
  | undefined;

export const WebSocketChatConnectionProvider = ({
  children,
}: WebSocketChatConnectionProps) => {
  const [, dispatch] = useAlert();

  const [requestIds, setRequestIds] = useState<RequestIds>({});
  const [status, setStatus] = useState<Status>("connecting");
  const [chatRoomsMessagePagination, setChatRoomsMessagePagination] =
    useState<ChatRoomsMessagePaginationInterface>(undefined);

  const isCrmEnabled = useFlagStatus("crm");
  const { profile } = useSessionProfile();
  const { data: sessionCookieValue } = useSessionCookieValueQuery();
  const { hasPermission, hasParentId } = usePermissions();

  const isSubaccount = !!hasParentId;
  const isPathIsWhitelisted = hasPermission("/crm/chat");
  const isAccountHasChatPermission = isSubaccount ? isPathIsWhitelisted : true;

  const isUserHasChatAccess = isAccountHasChatPermission;

  const connectedChatMarketplaceConnections =
    useConnectedChatMarketplaceConnectionsQuery({
      onSettled: async (data) => {
        await new Promise((resolve) => setTimeout(resolve, 3000));

        setStatus(!data?.length ? "stopped" : "connected");
      },
      enabled: isCrmEnabled && (profile?.isActive ?? false),
    });

  const shouldConnectWS =
    isCrmEnabled &&
    !!sessionCookieValue &&
    (connectedChatMarketplaceConnections?.data?.length || 0) > 0 &&
    isUserHasChatAccess;

  /**
   * Adjust useWebSocket<T> with the correct type of T based on the
   * message from BE
   */
  const { canSendMessage, connectionStatus, lastMessage, sendMessage } =
    useWebSocket<any>({
      connect: shouldConnectWS,
      options: {},
      subscriptionUrl: `${env.WEBSOCKET_CHAT_URL}?ms=${sessionCookieValue}`,
    });

  const handleSendMessage = useCallback(
    <T, U>(data: Record<string, T>, requestPayload?: Record<string, U>) => {
      let requestId: string | undefined = undefined;

      if (requestPayload) {
        const generatedId = v4();
        requestId = generatedId;

        setRequestIds((prev) => ({
          ...prev,
          [generatedId]: requestPayload,
        }));
      }

      sendMessage({ ...data, request_id: requestId ?? data["request_id"] });
    },
    [sendMessage]
  );

  const handleRemoveRequestId = useCallback((id?: string) => {
    if (id)
      setRequestIds((prev) => {
        delete prev[id];

        return { ...prev };
      });
  }, []);

  const handleChatRoomMessagesAfterManualSync = useCallback(() => {
    const listOpenedRoomId = Object.keys(chatRoomsMessagePagination ?? {});

    listOpenedRoomId.forEach((chatRoomId) => {
      const currentPagination = chatRoomsMessagePagination?.[chatRoomId];
      const page = currentPagination?.currentPage ?? 1;
      const perPage = currentPagination?.perPage ?? 60;

      const payload = cleanPayload({
        type: "load_chat_room_messages",
        page: 1,
        per_page: page * perPage,
        chat_room_id: Number(chatRoomId),
        request_id: `${chatRoomId},isSyncTrue`,
      });

      sendMessage(payload);
    });

    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === "chat" &&
        query.queryKey[2] === "shop" &&
        query.queryKey[4] === "messages",
    });
  }, [chatRoomsMessagePagination, sendMessage]);

  const handleChatSyncAfterRead = useCallback(
    (chatRoomId: number) => {
      const currentPagination = chatRoomsMessagePagination?.[chatRoomId];
      const page = currentPagination?.currentPage ?? 1;
      const perPage = currentPagination?.perPage ?? 60;

      const payload = cleanPayload({
        type: "load_chat_room_messages",
        page: 1,
        per_page: page * perPage,
        chat_room_id: Number(chatRoomId),
        request_id: `${chatRoomId},isSyncTrue`,
      });

      sendMessage(payload);
    },
    [chatRoomsMessagePagination, sendMessage]
  );

  useEffect(() => {
    if (connectionStatus === "Open" && lastMessage) {
      const requestPayload = requestIds[lastMessage.request_id];

      if (lastMessage.type === "load_chat_rooms") {
        loadChatRoomsHandler({
          response: mapToCamelCase(lastMessage),
          requestPayload,
        });
      }

      if (lastMessage.type === "mark_as_read") {
        markAsReadHandler({
          response: mapToCamelCase(lastMessage),
        });
      }

      if (lastMessage.type === "sync_chat_info") {
        syncChatInfoHandler({
          notificationProvider: {
            open: (params) => {
              notify(dispatch, {
                content: params.message,
                variant: params.type,
                testid: "sync_chat__snackbar__" + params.type,
              });
            },
          },
          response: mapToCamelCase(lastMessage),
          handleChatRoomMessagesAfterManualSync,
        });
      }

      if (lastMessage.type === "load_chat_room_messages") {
        loadChatRoomMessagesHandler({
          response: mapToCamelCase(lastMessage),
          requestPayload,
        });
      }

      if (lastMessage.type === "receive_message") {
        receiveMessageHandler({
          response: mapToCamelCase(lastMessage),
          sendMessageProvider: sendMessage,
        });
      }

      if (lastMessage.type === "send_message") {
        sendMessageHandler({
          response: mapToCamelCase(lastMessage),
          notificationProvider: {
            open: (params) => {
              notify(dispatch, {
                content: params.content,
                variant: params.variant,
                testid: "send_message__snackbar__" + params.variant,
              });
            },
          },
        });
      }

      /**
       * Currently no specific error code from BE to tell the disconnected account issue.
       */
      if (lastMessage.status_code === 500) {
        notify(dispatch, {
          content:
            "Ups, Akun kamu baru saja terhapus. Mohon refresh halaman ini, ya!",
          variant: "negative",
          testid: "disconnected_chat_account__snackbar__negative",
        });
      }

      handleRemoveRequestId(lastMessage.request_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionStatus, lastMessage]);

  useEffect(() => {
    if (!canSendMessage) invalidateUseSessionQuery();
  }, [canSendMessage]);

  return (
    <WebSocketChatConnectionContext.Provider
      value={{
        status,
        canSendMessage,
        chatRoomsMessagePagination,
        setChatRoomsMessagePagination,
        sendMessage: handleSendMessage,
        handleChatSyncAfterRead,
      }}
    >
      {children}
    </WebSocketChatConnectionContext.Provider>
  );
};

const useChatSocketConnection = () => {
  const context = useContext(WebSocketChatConnectionContext);

  return context;
};

export { useChatSocketConnection };

export default WebSocketChatConnectionProvider;
