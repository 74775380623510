export enum AppNotificationCategoryEnum {
  ORDER = "order",
  CUSTOMER = "customer",
  PROMOTION = "promotion",
  INVENTORY = "inventory",
  ACCOUNTING = "accounting",
  CONNECTION = "connection",
}

export default AppNotificationCategoryEnum;
