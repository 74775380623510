import { useAlert } from "@saas/shared/feature";
import { SnackbarItem } from "@saas/shared/ui";
import { classNames } from "@saas/shared/utils";

export interface SnackbarProps {
  className?: string;
}

export const Snackbar = ({ className }: SnackbarProps) => {
  const [alerts, dispatcher] = useAlert();

  return (
    <div
      aria-live={"assertive"}
      className={classNames(
        "pointer-events-none fixed inset-0 z-20 flex items-start",
        className
      )}
    >
      <div className={"flex w-full flex-col space-y-1"}>
        {/* Dynamically insert this into the live region when it needs to be displayed */}
        {alerts.map((alert) => (
          <SnackbarItem key={alert.id} dispatcher={dispatcher} {...alert} />
        ))}
      </div>
    </div>
  );
};

export default Snackbar;
