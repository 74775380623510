import { ReactNode, useEffect, useRef, useState } from "react";

import { NoAccess } from "@saas/account/ui";
import { AccessesKeyEnum } from "@saas/account/utils";
import { useFlagsStatus } from "@saas/flags/utils";
import { NavigationInterface, useLayoutShowAside } from "@saas/layout/utils";
import {
  DesktopFeatureBlockerModal,
  OfflineState,
  ProgressBar,
  SlideOver,
} from "@saas/shared/ui";
import { AppNameEnum, PathKeysEnum, useProgress } from "@saas/shared/utils";

import { AsideNavigation, Snackbar, TopNavigationBar } from "../..";

import { useNetworkState } from "react-use";

export interface AppLayoutProps {
  children: ReactNode;
  accountMenu: () => JSX.Element;
  marketplaceConnectionBlockerModal: () => JSX.Element;
  navigation: ReadonlyArray<NavigationInterface>;
  unverifiedAccountBlockerModal: () => JSX.Element;
  enabledNotificationR2?: boolean;
  isVerified?: boolean;
  showScheduleDemo?: boolean;
  variantLogo?: AppNameEnum;
  totalUnreadChatRoom?: number;
  accesses?: AccessesKeyEnum[];
  hasAccess: boolean;
  hasParentId: string | null;
  statusCode: number;
}

export const AppLayout = ({
  accountMenu,
  marketplaceConnectionBlockerModal: MarketplaceConnectionBlockerModal,
  navigation,
  unverifiedAccountBlockerModal: UnverifiedAccountBlockerModal,
  enabledNotificationR2,
  children,
  isVerified,
  showScheduleDemo = true,
  variantLogo = AppNameEnum.MELAKA,
  totalUnreadChatRoom,
  accesses,
  hasAccess,
  hasParentId,
  statusCode,
}: AppLayoutProps) => {
  const desktopFeatureBlockerModalRef = useRef<HTMLButtonElement>(null);
  const [isOnline, setIsOnline] = useState<boolean | undefined>(true);
  const [isNotFound, setIsNotFound] = useState<boolean>(false);

  const progressBarProps = useProgress();
  const { online } = useNetworkState();

  const { setShowAside, showAside } = useLayoutShowAside();

  const {
    "inventory/distributors-solution": inventoryDistributorSolutionFlag,
    "inventory/stocks": inventoryStocksFlag,
  } = useFlagsStatus();

  const isParentIdExist = hasParentId !== null;
  const isAccessesExist = accesses && accesses?.length > 0;
  const isSubaccount = isAccessesExist && isParentIdExist;

  const hasPermissions = hasAccess || !isSubaccount;

  const excludedPaths = inventoryDistributorSolutionFlag
    ? [PathKeysEnum.INVENTORY]
    : [];

  useEffect(() => {
    setIsOnline(online);
    if (statusCode === 404) {
      setIsNotFound(true);
    }
  }, [online, statusCode]);

  return (
    <div className={"bg-neutral-N100 flex min-h-screen flex-col"}>
      <div className={"hidden md:block"}>
        <AsideNavigation
          showScheduleDemo={showScheduleDemo}
          navigation={navigation}
          totalUnreadChatRoom={totalUnreadChatRoom}
          accesses={accesses}
        />
      </div>
      <div className={"block md:hidden"}>
        <SlideOver
          origin={"left"}
          size={"small"}
          open={showAside}
          onClose={() => setShowAside(false)}
        >
          <AsideNavigation
            navigation={navigation}
            onNavigate={() => setShowAside(false)}
            totalUnreadChatRoom={totalUnreadChatRoom}
            accesses={accesses}
          />
        </SlideOver>
      </div>

      <div className={"flex flex-1 flex-col md:pl-[248px]"}>
        <TopNavigationBar
          accountMenu={accountMenu}
          handleShowAside={() => setShowAside(true)}
          enabledNotificationR2={enabledNotificationR2}
          variantLogo={variantLogo}
          accesses={accesses}
          isSubaccount={isSubaccount}
        />

        <ProgressBar
          {...progressBarProps}
          className={"top-[58px] left-auto right-0 md:w-[calc(100%_-_248px)]"}
        />

        <main className={"relative flex min-h-full w-full flex-1 flex-col"}>
          <div>
            {!hasPermissions && !isNotFound ? (
              <NoAccess />
            ) : (
              <>
                <Snackbar className={"pt-[58px] md:pl-[248px]"} />

                <UnverifiedAccountBlockerModal />

                <MarketplaceConnectionBlockerModal />

                {inventoryStocksFlag ? (
                  <DesktopFeatureBlockerModal
                    initialRef={desktopFeatureBlockerModalRef}
                    isVerified={isVerified}
                    excludedPaths={excludedPaths}
                  />
                ) : null}

                {isOnline ? (
                  <div>{children}</div>
                ) : (
                  <div className={"mt-12 p-10 md:mt-24"}>
                    <OfflineState />
                  </div>
                )}
              </>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default AppLayout;
