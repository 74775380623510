import { fetchJson, ResponseInterface } from "@saas/core";
import {
  cleanPayload,
  mapToCamelCase,
  mapToSnakeCase,
} from "@saas/shared/utils";

export interface UpdateReadNotificationResponse extends ResponseInterface {
  error_code: number;
}

export type UpdateReadNotificationInput = {
  id: number;
  isRead: boolean;
};

export type UpdateReadNotificationOutput = {
  statusCode: number;
  message?: string;
};

export const updateReadNotification = async (
  input: UpdateReadNotificationInput,
  options?: RequestInit
): Promise<UpdateReadNotificationOutput> => {
  const response = await fetchJson<UpdateReadNotificationResponse>(
    `/api/notifications/notifications/${input.id}/status`,
    {
      ...options,
      method: "PATCH",
      body: JSON.stringify(
        mapToSnakeCase(cleanPayload({ isRead: input.isRead }))
      ),
    }
  );

  return mapToCamelCase(response);
};

export default updateReadNotification;
