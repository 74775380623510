import { useMemo } from "react";

import { useWindowSize } from "usehooks-ts";

interface UseMediaQueryOptions {
  type: "min" | "max";
}

export type UseMediaQueryReturnType = {
  isMobile: boolean;
  isDesktop: boolean;
};

/**
 * Hook to handle media query.
 */
export const useMediaQuery = (
  mediaQuery: number,
  options?: UseMediaQueryOptions
): UseMediaQueryReturnType => {
  const { type = "min" } = options ?? {};

  const { width } = useWindowSize();

  return useMemo<UseMediaQueryReturnType>(() => {
    const operator = {
      min: ">=",
      max: "<=",
    };

    const boolean = compare(width, operator[type], mediaQuery);

    return {
      isMobile: !boolean,
      isDesktop: boolean,
    };
  }, [mediaQuery, type, width]);
};

const compare = (a: number, operator: string, b: number) => {
  switch (operator) {
    case ">=":
      return a >= b;
    case "<=":
      return a <= b;
    default:
      throw new Error("Invalid operator");
  }
};

export default useMediaQuery;
