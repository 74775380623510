import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import CookiesNameEnum from "../../@enum/cookies-name/cookies-name.enum";
import NewMenuEnum from "../../@enum/new-menu/new-menu.enum";
import useHasMounted from "../use-has-mounted/use-has-mounted";

import { getCookie, setCookie } from "cookies-next";

export type DictionaryListIndicator = Record<NewMenuEnum, boolean>;

export type UseNewIndicatorReturnType = {
  showIndicator: DictionaryListIndicator;
};

export const defaultListIndicator: DictionaryListIndicator = {
  accountingJournal: false,
  promotionBroadcastCampaign: false,
  productCollections: false,
  crm: false,
};

export const useNewIndicator = (): UseNewIndicatorReturnType => {
  const router = useRouter();

  const hasMounted = useHasMounted();

  const [showIndicator, setShowIndicator] =
    useState<DictionaryListIndicator>(defaultListIndicator);

  const pageCookieMap: Record<string, CookiesNameEnum> = {
    "/accounting": CookiesNameEnum.ACCOUNTING_JOURNAL,
    "/promotion/broadcast-campaign":
      CookiesNameEnum.PROMOTION_BROADCAST_CAMPAIGN,
    "/inventory/collections": CookiesNameEnum.PRODUCT_COLLECTIONS,
  };

  useEffect(() => {
    const newIndicator = { ...defaultListIndicator };

    const cookieValues = {
      [CookiesNameEnum.ACCOUNTING_JOURNAL]: "accountingJournal",
      [CookiesNameEnum.PROMOTION_BROADCAST_CAMPAIGN]:
        "promotionBroadcastCampaign",
      [CookiesNameEnum.PRODUCT_COLLECTIONS]: "productCollections",
    };

    Object.entries(cookieValues).forEach(([cookieName, indicatorKey]) => {
      if (!getCookie(cookieName as CookiesNameEnum)) {
        newIndicator[indicatorKey as NewMenuEnum] = true;
      }
    });

    setShowIndicator(newIndicator);
  }, []);

  useEffect(() => {
    if (hasMounted) {
      setShowIndicator((prevIndicator) => {
        const newIndicator = { ...prevIndicator };

        Object.entries(pageCookieMap).forEach(([pagePath, cookieName]) => {
          if (router.pathname === pagePath && !getCookie(cookieName)) {
            setCookie(cookieName, true);
            newIndicator[pagePath.replace("/", "") as NewMenuEnum] = false;
          }
        });

        return newIndicator;
      });
    }
  }, [router.pathname, hasMounted]);

  return {
    showIndicator,
  };
};

export default useNewIndicator;
