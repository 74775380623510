export type UnverifiedRoutesWhitelist = ReadonlyArray<string>;

// route that allowed to be accessed by unverified user
export const unverifiedRoutesWhitelist: UnverifiedRoutesWhitelist = [
  "/",
  "/account",
  "/events",
  "/login",
];

export default unverifiedRoutesWhitelist;
