import { ChatIcon } from "@saas/shared/icon";
import { classNames } from "@saas/shared/utils";

export interface NotificationChatIconProps {
  testid: string;
  totalUnread: number;
}

export const NotificationChatIcon = ({
  testid,
  totalUnread,
}: NotificationChatIconProps) => {
  return (
    <div className={"relative"}>
      <ChatIcon className={"text-le md:h-6 md:w-6"} />
      {totalUnread > 0 ? (
        <div
          className={classNames(
            "bg-red-R700 absolute bottom-2 left-1.5 flex h-5 w-5 place-content-center items-center justify-center rounded-full",
            totalUnread > 99 ? "md:h-6 md:w-6" : "md:h-5 md:w-5"
          )}
        >
          <span
            className={"text-invert label-small md:font-semibold"}
            data-testid={`${testid}__icon__unread`}
          >
            {totalUnread > 99 ? (
              <>
                99<span className={"hidden md:inline-block"}>+</span>
              </>
            ) : (
              totalUnread
            )}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default NotificationChatIcon;
