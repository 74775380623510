export interface RouteKeyLabel {
  [key: string]: string;
}

export const routeKeyLabel: RouteKeyLabel = {
  account: "Akunku",
  profile: "Edit Profil",
  changeEmail: "Ubah Email",
  changePassword: "Ubah Kata Sandi",
  promotion: "Promosi Saya",
  promotionList: "Promosi Toko Online",
  newDiscount: "Buat Diskon Baru",
  newShopVoucher: "Buat Voucher Toko Baru",
  newProductVoucher: "Buat Voucher Produk Baru",
  marketplaceConnections: "Koneksi Toko Online",
  inventory: "Produk & Stok",
  products: "Daftar Produk",
  stocks: "Stok Inventori",
  masterProductData: "Pemetaan Produk (Live)",
  demographics: "Demografis Pembeli",
  order: "Order Saya",
  channelAllocationStock: "Channel dan Alokasi Stok",
  stockMapping: "Pemetaan Stok",
  stockManagement: "Manajemen Stok",
  stockMovement: "Pergerakan Stok",
  stockMovementDetail: "Pergerakan Stok: Detail Pergerakan Stok",
  newStockIn: "Tambah Stok Baru",
  internal: "Internal",
  accounting: "Catatan Keuangan",
  newEntry: "Tambah Catatan",
  skuMapping: "Pemetaan SKU",
  newProduct: "Tambah Produk",
  broadcastCampaignWa: "Broadcast via Whatsapp",
  broadcastCampaign: "Broadcast via Whatsapp",
  newRecipient: "Tambah Penerima",
  addKeyword: "Tambah Keyword",
  catalog: "Promosi Katalog Melaka",
  customerAnalysis: "Analisa Pelanggan",
  reports: "Analisis Bisnis",
  payment: "Saldo Saya",
  store: "Toko Saya",
  salesperson: "Tim Sales",
  termOfPayment: "Piutang",
  shopMonitoring: "Pantau Toko",
  keywordMonitoring: "Pantau Kata Kunci",
  priceMonitoring: "Pantau Harga",
  collections: "Koleksi Produk",
};

export const routeKeyLabelV2: RouteKeyLabel = {
  account: "Akunku",
  profile: "Pengaturan Akun",
  changeEmail: "Ubah Email",
  changePassword: "Ubah Kata Sandi",
  promotion: "Promosi Saya",
  promotionList: "Promosi Toko Online",
  newDiscount: "Buat Diskon Baru",
  newShopVoucher: "Buat Voucher Toko Baru",
  newProductVoucher: "Buat Voucher Produk Baru",
  marketplaceConnections: "Koneksi Toko Online",
  inventory: "Produk & Stok",
  products: "Daftar Produk",
  stocks: "Stok Inventori",
  masterProductData: "Pemetaan Produk (Live)",
  demographics: "Demografis Pembeli",
  order: "Order Saya",
  channelAllocationStock: "Channel dan Alokasi Stok",
  stockMapping: "Pemetaan Stok",
  stockManagement: "Manajemen Stok",
  stockMovement: "Pergerakan Stok",
  stockMovementDetail: "Pergerakan Stok: Detail Pergerakan Stok",
  newStockIn: "Tambah Stok Baru",
  internal: "Internal",
  accounting: "Catatan Keuangan",
  newEntry: "Tambah Catatan",
  skuMapping: "Pemetaan SKU",
  newProduct: "Tambah Produk",
  broadcastCampaignWa: "Broadcast via Whatsapp",
  broadcastCampaign: "Broadcast via Whatsapp",
  newRecipient: "Tambah Penerima",
  addKeyword: "Tambah Keyword",
  catalog: "Promosi Katalog Melaka",
  customerAnalysis: "Analisa Pelanggan",
  reports: "Analisis Bisnis",
  payment: "Saldo Saya",
  store: "Toko Saya",
  salesperson: "Tim Sales",
  termOfPayment: "Piutang",
  shopMonitoring: "Pantau Toko",
  keywordMonitoring: "Pantau Kata Kunci",
  priceMonitoring: "Pantau Harga",
  loyaltyPoint: "Poin Loyalti",
  bundling: "Virtual Bundling",
  createBundling: "Tambah Virtual Bundling",
  collections: "Koleksi Produk",
  voucher: "Voucher",
};

export default routeKeyLabel;
