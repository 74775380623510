import { ChannelEnum } from "@saas/shared/utils";

export interface ChatQueryKey {
  all: () => readonly ["chat"];
  allChatRoomsByChannel: (
    channels: Array<ChannelEnum>
  ) => readonly ["chat", Array<ChannelEnum>, "rooms"];
  allUnreadChatRoomsBychannel: (
    channels: Array<ChannelEnum>,
    shopId: string
  ) => readonly ["chat", Array<ChannelEnum>, "rooms", "unread", "shop", string];
  allUnreadChatRoomsCount: () => readonly ["chat", "unread", "count"];
  allChatRoomMessages: (
    channels: Array<ChannelEnum>,
    roomId: string
  ) => readonly ["chat", Array<ChannelEnum>, "rooms", string, "messages"];
  allChatRoomsByShop: (
    channels: Array<ChannelEnum>,
    shopId: string
  ) => readonly ["chat", Array<ChannelEnum>, "shop", string, "messages"];
  chatRoom: (
    channels: Array<ChannelEnum>,
    roomId: number
  ) => readonly ["chat", Array<ChannelEnum>, "rooms", number];
  quickReplyTemplates: () => readonly ["chat", "quickReplyTemplates"];
  sync: (
    channels: ReadonlyArray<ChannelEnum>
  ) => readonly ["chat", "sync", ReadonlyArray<ChannelEnum>];
  mutateSyncChat: (
    channels: ReadonlyArray<ChannelEnum>
  ) => readonly ["mutate", "chat", "sync", ReadonlyArray<ChannelEnum>];
}

export const chatQueryKey: ChatQueryKey = {
  all: () => ["chat"] as const,
  allChatRoomsByChannel: (channels) =>
    [...chatQueryKey.all(), channels, "rooms"] as const,
  allUnreadChatRoomsBychannel: (channels, shopId) =>
    [
      ...chatQueryKey.allChatRoomsByChannel(channels),
      "unread",
      "shop",
      shopId,
    ] as const,
  allUnreadChatRoomsCount: () => ["chat", "unread", "count"],
  allChatRoomMessages: (channels, roomId) => [
    ...chatQueryKey.allChatRoomsByChannel(channels),
    roomId,
    "messages",
  ],
  chatRoom: (channels: Array<ChannelEnum>, roomId) => [
    ...chatQueryKey.allChatRoomsByChannel(channels),
    roomId,
  ],
  allChatRoomsByShop: (channels: Array<ChannelEnum>, shopId: string) => [
    "chat",
    channels,
    "shop",
    shopId,
    "messages",
  ],
  quickReplyTemplates: () => ["chat", "quickReplyTemplates"],
  sync: (channels: ReadonlyArray<ChannelEnum>) => ["chat", "sync", channels],
  mutateSyncChat: (channels: ReadonlyArray<ChannelEnum>) => [
    "mutate",
    "chat",
    "sync",
    channels,
  ],
};

export default chatQueryKey;
