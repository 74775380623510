export enum QueryKey {
  SESSION = "session",
  SESSION_COOKIE_VALUE = "session-cookie-value",
  AUTH_LOGIN = "auth/login",
  AUTH_REGISTER = "auth/register",
  AUTH_RESET_PASSWORD = "auth/resetPassword",
  AUTH_FORGOT_PASSWORD = "auth/forgotPassword",
  AUTH_LOGOUT = "auth/logout",
  ACCOUNT_PROFILE = "account/profile",
  ACCOUNT_VERIFICATION = "account/verification",
}

export default QueryKey;
