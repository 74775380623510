import { mediaQuery } from "@saas/core";
import { NotificationsIcon } from "@saas/shared/icon";
import { Typography } from "@saas/shared/ui";
import { classNames } from "@saas/shared/utils";

import { useWindowSize } from "usehooks-ts";

type NotificationBellProps = {
  totalUnread: number;
};

const testid = "inapp-notification";

export const NotificationBell = ({ totalUnread }: NotificationBellProps) => {
  const { width } = useWindowSize();
  const isMobile = width < mediaQuery.md;

  return (
    <div
      className={
        "relative hover:cursor-pointer md:flex md:py-3 md:pl-2.5 md:pr-2.5"
      }
    >
      <NotificationsIcon
        className={"md:h-6 md:w-6"}
        data-testid={`${testid}__icon__bell`}
      />
      {totalUnread > 0 ? (
        <span
          className={classNames(
            "bg-red-R700 absolute bottom-1.5 left-1 flex h-5 w-5 place-content-center items-center justify-center rounded-full md:relative md:bottom-0 md:left-0 md:ml-1",
            totalUnread > 99 ? "md:h-6 md:w-8" : "md:h-6 md:w-6"
          )}
        >
          <Typography
            className={"text-invert md:inline md:font-semibold"}
            type={isMobile ? "label-small" : "body-b3"}
            data-testid={`${testid}__icon__bell-unread`}
          >
            {totalUnread > 99 ? (isMobile ? "99" : "99+") : totalUnread}
          </Typography>
        </span>
      ) : null}
    </div>
  );
};

export default NotificationBell;
