export type PublicRoutes = ReadonlyArray<string>;

export const publicRoutes: PublicRoutes = [
  "/public",
  "/register",
  "/login",
  "/forgot-password",
  "/setup-password",
];

export default publicRoutes;
