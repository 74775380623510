import { useRouter } from "next/router";
import { memo, ReactElement, useCallback, useEffect, useMemo } from "react";

import { useBusinessInformationQuery } from "@saas/account/data";

import { useSessionProfile } from "..";

import { useIsClient } from "usehooks-ts";

export interface UseAccountVerificationGuardProps {
  children: ReactElement;
}

export const AccountVerificationGuard = memo(
  ({ children }: UseAccountVerificationGuardProps) => {
    const { profile } = useSessionProfile();
    const { pathname, push } = useRouter();
    const isClient = useIsClient();
    const { data: businessInformation, isLoading } =
      useBusinessInformationQuery({
        refetchOnWindowFocus: false,
      });

    const isPassedBusinessInformation = isLoading
      ? true
      : businessInformation?.passedBusinessInformation ?? false;

    const allowAccessForVerified = useMemo(() => {
      if (!profile?.isVerified) {
        return false;
      }

      return true;
    }, [profile?.isVerified]);

    const redirectToAccountActivation = useCallback(async () => {
      if (isClient && pathname !== "/account-activation") {
        await push("/account-activation");
      }
    }, [isClient, pathname, push]);

    const allowAccessForBusinessInformation = useMemo(() => {
      if (profile?.isVerified && !isPassedBusinessInformation) {
        return false;
      }

      return true;
    }, [isPassedBusinessInformation, profile?.isVerified]);

    const redirectToBusinessInformation = useCallback(async () => {
      if (isClient && pathname !== "/business-information") {
        await push("/business-information");
      }
    }, [isClient, pathname, push]);

    useEffect(() => {
      if (!allowAccessForVerified) {
        redirectToAccountActivation();
      }

      if (!allowAccessForBusinessInformation) {
        redirectToBusinessInformation();
      }
    }, [
      allowAccessForBusinessInformation,
      allowAccessForVerified,
      redirectToAccountActivation,
      redirectToBusinessInformation,
    ]);

    return allowAccessForVerified ? children : null;
  }
);

AccountVerificationGuard.displayName = "AccountVerificationGuard";

export default AccountVerificationGuard;
