import { Fragment, useEffect, useMemo, useRef, useState } from "react";

import { mediaQuery } from "@saas/core";
import {
  useGetNotificationsInfiniteQuery,
  useUpdateReadNotificationMutation,
} from "@saas/notification/data";
import { NotificationBell, NotificationCard } from "@saas/notification/ui";
import {
  AppNotificationInterface,
  trackOpenNotification,
  trackReadNotification,
} from "@saas/notification/utils";
import {
  CloseIcon,
  NoNotificationTwoTonedIcon,
  SortIcon,
  SpinnerIcon,
} from "@saas/shared/icon";
import { Toggle, Typography } from "@saas/shared/ui";
import { classNames } from "@saas/shared/utils";

import { Popover, Transition } from "@headlessui/react";
import { useInView } from "react-intersection-observer";
import { usePopper } from "react-popper";
import { useWindowSize } from "usehooks-ts";

const testid = "inapp-notification-list";

export const NotificationContainer = ({
  enabledNotificationR2,
}: {
  enabledNotificationR2?: boolean;
}) => {
  const updateReadNotificationMutation = useUpdateReadNotificationMutation();
  const { width } = useWindowSize();
  const isMobile = useMemo(() => width < mediaQuery.md, [width]);

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [isUnread, setIsUnread] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
  });

  const scrollableContainer = useRef<HTMLDivElement>(null);
  const { ref, inView } = useInView({
    root: scrollableContainer.current,
  });

  const {
    fetchNextPage,
    hasNextPage,
    isFetching,
    data,
    isFetchingNextPage,
    isLoading,
  } = useGetNotificationsInfiniteQuery(
    {
      page: 1,
      perPage: 20,
      isRead: isUnread ? false : undefined,
    },
    {
      refetchOnWindowFocus: enabledNotificationR2,
      staleTime: 1000 * 60 * 2,
      refetchInterval: enabledNotificationR2 ? 1000 * 60 * 2 : false,
    }
  );

  useEffect(() => {
    if (
      inView &&
      hasNextPage &&
      !isFetchingNextPage &&
      !isLoading &&
      !isFetching
    ) {
      fetchNextPage();
    }
  }, [
    inView,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
    fetchNextPage,
  ]);

  const notifications = useMemo(() => {
    return data?.pages.flatMap((data) => data.data) ?? [];
  }, [data?.pages]);

  const totalUnreadNotification = useMemo(() => {
    return data?.pages[0]?.metadata?.totalUnread ?? 0;
  }, [data?.pages]);

  const onRead = (data: AppNotificationInterface) => {
    trackReadNotification({
      id: String(data.id),
      title: data.title,
    });
    updateReadNotificationMutation.mutate({ id: data.id, isRead: true });
  };

  const onOpen = (isOpen: boolean) => {
    if (!isOpen)
      trackOpenNotification({
        totalUnreadNotification: String(totalUnreadNotification),
      });
  };

  return (
    <Popover>
      {({ open, close }) => (
        <>
          <Transition
            show={open}
            as={Fragment}
            enter={"ease-out duration-300 md:duration-100"}
            enterFrom={"transform opacity-0"}
            enterTo={"transform opacity-100"}
            leave={"ease-in duration-200 md:duration-75"}
            leaveFrom={"transform opacity-100"}
            leaveTo={"transform opacity-0"}
          >
            <div
              className={"bg-neutral-N700/40 fixed inset-0 z-10 md:hidden"}
            />
          </Transition>

          <div className={"relative h-full w-full"}>
            <Popover.Button
              ref={setReferenceElement}
              className={classNames("rounded-xl", open ? "bg-blue-B100" : null)}
              data-testid={`${testid}__button__open-notification`}
              onClick={() => onOpen(open)}
            >
              <NotificationBell totalUnread={totalUnreadNotification} />
            </Popover.Button>

            <Transition
              show={open}
              as={Fragment}
              enter={"ease-out duration-300 md:duration-100"}
              enterFrom={
                "transform opacity-0 translate-y-4 md:translate-y-0 md:scale-y-95"
              }
              enterTo={"transform opacity-100 translate-y-0 scale-100"}
              leave={"ease-in duration-200 md:duration-75"}
              leaveFrom={"transform opacity-100 translate-y-0 scale-100"}
              leaveTo={
                "transform opacity-0 translate-y-4 md:translate-y-0 md:scale-y-95"
              }
            >
              <Popover.Panel
                ref={setPopperElement}
                className={
                  "fixed inset-0 z-10 mt-auto flex w-full flex-1 items-end md:absolute md:inset-auto md:mt-3 md:w-[413px]"
                }
                style={!isMobile ? styles["popper"] : undefined}
                {...attributes["popper"]}
              >
                <div
                  className={
                    "md:shadow-elevation-1 bg-neutral-N0 shadow-elevation-2 relative w-full rounded-t-xl text-base md:overflow-y-auto md:overflow-x-hidden md:rounded-lg"
                  }
                >
                  <hr
                    className={
                      "bg-neutral-N0 relative -top-3 z-10 mx-auto h-1 w-14 rounded-3xl shadow-sm  md:hidden"
                    }
                  />

                  <div
                    className={"flex items-center gap-4 px-5 py-4 md:hidden"}
                  >
                    <button onClick={() => close?.()}>
                      <CloseIcon className={"text-neutral-N600 h-5 w-5"} />
                    </button>

                    <Typography
                      as={"h3"}
                      type={"title-large"}
                      className={"text-he"}
                      data-testid={`${testid}__text__header`}
                    >
                      Notifikasi
                    </Typography>
                  </div>

                  <div
                    className={
                      "text-he flex justify-between gap-5 border-b px-4 py-2 md:pt-4"
                    }
                  >
                    <Typography
                      type={"title-large"}
                      className={"hidden md:block"}
                      data-testid={`${testid}__text__header`}
                    >
                      Notifikasi
                    </Typography>
                    <NotificationFilter
                      onFilter={() => setIsUnread((prevValue) => !prevValue)}
                      isUnread={isUnread}
                      isMobile={isMobile}
                    />
                  </div>

                  <div
                    className={
                      "flex max-h-[85vh] flex-col divide-y overflow-auto md:max-h-[75vh]"
                    }
                    ref={scrollableContainer}
                  >
                    {notifications.map((notification) => (
                      <NotificationCard
                        notification={notification}
                        onRead={() => {
                          close?.();
                          onRead(notification);
                        }}
                        key={notification.id}
                      />
                    ))}
                    {isLoading || isFetchingNextPage ? (
                      <LoadingSpinner />
                    ) : (
                      <>
                        {hasNextPage ? (
                          <div ref={ref} className={"p-6"} />
                        ) : null}
                        {notifications.length === 0 ? (
                          <EmptyNotification />
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        </>
      )}
    </Popover>
  );
};

type NotificationFilterProps = {
  onFilter: () => void;
  isUnread: boolean;
  isMobile: boolean;
};

const NotificationFilter = ({
  onFilter: handleFilter,
  isUnread,
  isMobile,
}: NotificationFilterProps) => {
  return (
    <div
      className={
        "flex w-full select-none items-center justify-between gap-2 md:w-auto md:cursor-pointer"
      }
      data-testid={`${testid}__button__unread`}
      onClick={() => !isMobile && handleFilter()}
    >
      <Typography type={"body-b3"}>Lihat yang belum dibaca saja</Typography>
      {isMobile ? (
        <Toggle
          label={"Lihat yang belum dibaca saja"}
          showLabel={false}
          checked={isUnread}
          data-testid={`${testid}__togle__unread`}
          onChange={handleFilter}
        />
      ) : (
        <div className={"relative"}>
          <SortIcon className={"text-neutral-N600"} />
          {isUnread ? (
            <span
              className={
                "bg-green-G500 border-neutral-N0 absolute top-[2px] right-0 box-border h-2.5 w-2.5 rounded-full border"
              }
              data-testid={`${testid}__icon__sort-unread`}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

const EmptyNotification = () => {
  return (
    <div
      className={"flex items-center justify-center gap-4 p-10"}
      data-testid={`${testid}__text__empty-notification`}
    >
      <div className={"h-8 w-8"}>
        <NoNotificationTwoTonedIcon />
      </div>
      <Typography type={"body-b2"}>Tidak ada notifikasi</Typography>
    </div>
  );
};

const LoadingSpinner = () => {
  return (
    <div className={"w-full p-6"}>
      <SpinnerIcon
        className={"text-neutral-N600 mx-auto h-6 w-6 animate-spin self-center"}
      />
    </div>
  );
};

export default NotificationContainer;
