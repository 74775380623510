import { ReactNode } from "react";

import { useFeatureFlagsStatusQuery } from "@saas/flags/data";
import { FlagKeys } from "@saas/flags/utils";

import { FlagsProvider } from "flagged";

export interface FeatureFlagsProviderProps {
  children: (flags: Record<FlagKeys, boolean> | undefined) => ReactNode;
}

export const FeatureFlagsProvider = ({
  children,
}: FeatureFlagsProviderProps) => {
  const { data: flags } = useFeatureFlagsStatusQuery();

  return <FlagsProvider features={flags}>{children(flags)}</FlagsProvider>;
};

export default FeatureFlagsProvider;
