import { UiNode } from "@ory/client";
import { isUiNodeInputAttributes } from "@ory/integrations/ui";

export const getOryCsrf = (nodes: UiNode[]): string => {
  return nodes.map(
    ({ attributes }) =>
      isUiNodeInputAttributes(attributes) &&
      attributes.name === "csrf_token" &&
      attributes.value
  )[0];
};

export default getOryCsrf;
