import { FetchError, queryClient } from "@saas/core";
import { QueryOptionsInterface } from "@saas/shared/utils";

import {
  businessInformationQueryKeys,
  getBusinessInformation,
  GetBusinessInformationOutput,
} from "../..";

import { Updater, useQuery } from "@tanstack/react-query";

export type UseBusinessInformationQueryData = GetBusinessInformationOutput;

export const useBusinessInformationQuery = <
  DerivedQueryData = UseBusinessInformationQueryData
>(
  options?: QueryOptionsInterface<
    UseBusinessInformationQueryData,
    DerivedQueryData
  >
) => {
  return useQuery<
    UseBusinessInformationQueryData,
    FetchError,
    DerivedQueryData
  >(
    businessInformationQueryKeys.all(),
    async () => {
      const data = await getBusinessInformation();
      return data;
    },
    {
      ...options,
      select: options?.select,
      enabled: options?.enabled,
    }
  );
};

export const setUseBusinessInformationQuery = (
  updater: Updater<
    UseBusinessInformationQueryData | undefined,
    UseBusinessInformationQueryData
  >
): void => {
  queryClient.setQueryData<UseBusinessInformationQueryData>(
    businessInformationQueryKeys.all(),
    updater
  );
};

export const getUseBusinessInformationQuery = ():
  | UseBusinessInformationQueryData
  | undefined => {
  return queryClient.getQueryData<UseBusinessInformationQueryData>([
    businessInformationQueryKeys.all(),
  ]);
};

export const prefetchUseBusinessInformationQuery = async (
  cookie: string
): Promise<UseBusinessInformationQueryData | null> => {
  let data: UseBusinessInformationQueryData | null;

  try {
    data = await getBusinessInformation({ headers: { cookie } });
  } catch {
    data = null;
  }

  await queryClient.prefetchQuery(
    businessInformationQueryKeys.all(),
    () => data
  );

  return data;
};

export const invalidateUseBusinessInformationQuery = () => {
  return queryClient.invalidateQueries(businessInformationQueryKeys.all());
};

export default useBusinessInformationQuery;
