import { fetchJson, ResponseInterface } from "@saas/core";
import { AppNotificationInterface } from "@saas/notification/utils";
import { mapToCamelCase, PaginationInterface } from "@saas/shared/utils";

import { stringify } from "qs";

export interface Metadata {
  totalUnread: number;
}

export interface GetNotificationsResponse extends ResponseInterface {
  data: AppNotificationInterface;
  metadata: Metadata;
  pagination: PaginationInterface;
}

export type GetNotificationsInput = {
  isRead?: boolean | null;
  categoryId?: string;
  page?: number;
  perPage?: number;
};

export type GetNotificationsOutput = {
  data: AppNotificationInterface;
  metadata: Metadata;
  pagination: PaginationInterface;
};

export const getNotifications = async (
  input?: GetNotificationsInput,
  options?: RequestInit
): Promise<GetNotificationsOutput> => {
  const queryString = stringify(
    {
      page: input?.page,
      per_page: input?.perPage,
      category_id: input?.categoryId,
      is_read: input?.isRead,
    },
    {
      arrayFormat: "comma",
    }
  );

  const response = await fetchJson<GetNotificationsResponse>(
    "/api/notifications/notifications?" + queryString,
    options
  );
  return mapToCamelCase({
    data: response.data,
    metadata: response.metadata,
    pagination: response.pagination,
  });
};

export default getNotifications;
