import { ampli } from "@saas/core";

export const trackOpenNotification = ({
  totalUnreadNotification,
}: {
  totalUnreadNotification?: string;
}) => {
  ampli.openNotification({
    action: "Open Notification",
    total_unread_notification: totalUnreadNotification,
  });
};

export const trackReadNotification = ({
  title,
  id,
}: {
  title: string;
  id: string;
}) => {
  ampli.readNotification({
    action: "Read Notification",
    notification_category_title: title,
    notification_id: id,
  });
};
