import { GetNotificationsInput } from "./get-notifications";

export interface GetNotificationsQueryKey {
  all: (
    queries?: GetNotificationsInput
  ) => readonly ["notifications", GetNotificationsInput];
}

export const getNotificationsQueryKey: GetNotificationsQueryKey = {
  /* add implementation */
  all: (queries?: GetNotificationsInput) => [
    "notifications",
    { ...queries } as const,
  ],
};

export default getNotificationsQueryKey;
