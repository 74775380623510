import { useRouter } from "next/router";
import { useEffect } from "react";

import {
  getVerification,
  invalidateUseSessionQuery,
  sendVerificationEmail,
  useSendVerificationQuery,
} from "@saas/account/data-access";
import {
  setGTagUserVerifiedProperty,
  trackResendVerificationLink,
} from "@saas/account/utils";
import { env } from "@saas/config/shared";
import { useAlert } from "@saas/shared/feature";
import { NotificationBar } from "@saas/shared/ui";
import { notify } from "@saas/shared/utils";

import useSessionProfile from "../../@hook/session-profile/use-session-profile";

const DASHBOARD_PATH = "/";

export const VerificationNotification = () => {
  const { asPath, query, push } = useRouter();
  const { profile } = useSessionProfile();
  const { data: flow } = useSendVerificationQuery(!profile?.isVerified);
  const { flow: flowId } = query;
  const [, dispatch] = useAlert();
  const isDashboard = asPath.split("?")[0] === DASHBOARD_PATH;

  useEffect(() => {
    const isVerificationPath = asPath.split("?")[0] === DASHBOARD_PATH;

    if (isVerificationPath && flowId) {
      getVerification(flowId as string).then(async ({ state, code }) => {
        if (state === "choose_method" && code === 4070001) {
          return notify(dispatch, {
            variant: "negative",
            content: "Link verifikasi sudah tidak berlaku, harap kirim ulang.",
            testid: "home__snackbar__link-sent",
          });
        }

        if (state === "passed_challenge" && code === 1080002) {
          notify(dispatch, {
            variant: "positive",
            content: "Hore! Email sudah berhasil diverifikasi.",
            testid: "home__snackbar__link-sent",
          });

          setGTagUserVerifiedProperty(true);
          await invalidateUseSessionQuery();
          await push(DASHBOARD_PATH);
        }
      });
    }
  }, [asPath, dispatch, flowId, profile?.isVerified, push]);

  const sendVerification = async () => {
    if (flow && profile) {
      try {
        const { state } = await sendVerificationEmail({
          flowId: flow.id,
          email: profile.email,
          csrfToken: flow.csrfToken,
        });

        if (state === "sent_email") {
          trackResendVerificationLink();
          notify(dispatch, {
            variant: "positive",
            content:
              "Link verifikasi sudah terkirim! Harap cek email untuk melakukan verifikasi.",
            testid: "home__snackbar__link-sent",
          });
        }
      } catch (err) {
        notify(dispatch, {
          variant: "negative",
          content: "Link verifikasi gagal terkirim, harap kirim ulang.",
          testid: "home__snackbar__link-sent",
        });
      }
    }
  };

  return !profile?.isVerified && !isDashboard ? (
    <NotificationBar
      title={"Kamu belum melakukan verifikasi email."}
      message={`Cek email-mu untuk melakukan verifikasi email agar kamu dapat menggunakan ${env.APP_NAME} dengan maksimal.`}
      action={{
        label: "Kirim ulang link verifikasi",
        onClick: sendVerification,
        testid: "marketplace-connection__notification-bar__link",
      }}
      testid={"marketplace-connection__notification-bar"}
    />
  ) : null;
};

export default VerificationNotification;
