import { classNames } from "@saas/shared/utils";

const Size = {
  SMALL: "small",
  BIG: "big",
} as const;

export interface NewIndicatorProps {
  size?: typeof Size[keyof typeof Size];
  showLabel?: boolean;
  testid?: string;
  className?: string;
}

export const NewIndicator = ({
  size = "small",
  showLabel = false,
  testid = "",
  className,
}: NewIndicatorProps) => {
  const sizeClass = {
    [Size.SMALL]: "h-2 w-2",
    [Size.BIG]: "h-3 w-3",
  };

  return (
    <span
      className={classNames(
        "relative flex",
        sizeClass[size],
        showLabel &&
          "group-hover:bg-orange-O600 duration-300 ease-out group-hover:inline-flex group-hover:h-5 group-hover:w-10 group-hover:items-center group-hover:justify-center group-hover:rounded-3xl group-hover:py-[2px] group-hover:px-[7px]",
        className
      )}
      data-testid={testid}
    >
      <span className={"label-medium hidden text-white group-hover:block"}>
        Baru
      </span>
      <span
        className={classNames(
          "bg-orange-O700 absolute inline-flex h-full w-full animate-ping rounded-full",
          showLabel && "group-hover:hidden group-hover:animate-none"
        )}
      ></span>
      <span
        className={classNames(
          "bg-orange-O700 relative inline-flex rounded-full",
          sizeClass[size],
          showLabel && "group-hover:hidden"
        )}
      ></span>
    </span>
  );
};

export default NewIndicator;
