import { UsetifulUserRole } from "@saas/core";

const SDET_EMAILS = [
  "akuna@mail.com",
  "akunb@mail.com",
  "singide@mail.com",
  "mission_complete@mail.com",
  "new_aj@mail.com",
  "akundummyorder@mail.com",
  "submission_aj@mail.com",
  "saprudin@mail.com",
  "seller_shopee@mail.com",
  "akunamelakamarket@mail.com",
  "autovendor1@mail.com",
  "autovendor2@mail.com",
  "autovendor3@mail.com",
  "rdrct03@mail.com",
  "free_no_keyword@mail.com",
  "free_pm@mail.com",
  "paid_no_keyword@mail.com",
  "keyword_with_alerted@mail.com",
  "paid_with_alerted@mail.com",
  "dummy_pm@mail.com",
  "akunbmelakamarket@mail.com",
  "test_empty_notif@mail.com",
  "test_notif_akun@mail.com",
  "notifmaxtest@mail.com",
  "promotion_catalog1@mail.com",
  "promotion_catalog2@mail.com",
  "promotion_catalog3@mail.com",
  "promotion_catalog4@mail.com",
  "promotion_catalog5@mail.com",
  "promotion_catalog6@mail.com",
  "promotion_catalog7@mail.com",
  "sdet-production-a@hypefast.id",
  "sdet-production-b@hypefast.id",
  "sdet-production-c@hypefast.id",
  "sdet-production-d@hypefast.id",
  "sdet-production-e@hypefast.id",
  "unverified@mail.com",
  "verified@mail.com",
  "akunchangeemail@mail.com",
  "akunc@mail.com",
  "akundummytiktokexpirednoorder@mail.com",
  "no_shop_verified@mail.com",
  "akunz@mail.com",
];

export type GetUserRoleFromEmailInput = {
  email: string;
};

export type GetUserRoleFromEmailOutput = UsetifulUserRole;

const regexPattern = /^(?=.*@sdet\.com$)[\S\s]*$/;

export const getUserRoleFromEmail = ({
  email = "",
}: GetUserRoleFromEmailInput): GetUserRoleFromEmailOutput => {
  return SDET_EMAILS.includes(email) || regexPattern.test(email)
    ? "SDET"
    : "NON_SDET";
};

export default getUserRoleFromEmail;
