import { stringify } from "qs";

export type CreateShareWhatsappLinkInput = {
  tel?: string;
  text?: string;
};

export type CreateShareWhatsappLinkOutput = string;

export const createShareWhatsappLink = ({
  tel = "",
  text = "",
}: CreateShareWhatsappLinkInput): CreateShareWhatsappLinkOutput => {
  const encodeText = stringify({ text });

  return `http://wa.me/${tel}?${encodeText}`;
};

export default createShareWhatsappLink;
