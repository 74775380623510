import { useRouter } from "next/router";
import { RefObject } from "react";

import { AccessibleOnDesktopIllustration } from "@saas/shared/illustration";
import { PathKeysEnum, useHasMounted, usePathMatch } from "@saas/shared/utils";

import { BaseButton, BaseMobileDrawer } from "..";

export interface DesktopFeatureBlockerModalProps {
  initialRef: RefObject<HTMLElement>;
  isVerified?: boolean;
  excludedPaths?: Array<string>;
}

export const DesktopFeatureBlockerModal = ({
  initialRef,
  isVerified,
  excludedPaths = [],
}: DesktopFeatureBlockerModalProps) => {
  const testid = "popup";
  const { match } = usePathMatch(Object.values(PathKeysEnum), excludedPaths, {
    mobile: true,
    desktop: false,
  });
  const { push } = useRouter();

  const handleRedirectToHome = () => {
    push("/");
  };

  const hasMounted = useHasMounted();

  if (!hasMounted) {
    return null;
  }

  return (
    <div>
      {isVerified ? (
        <BaseMobileDrawer
          initialRef={initialRef}
          open={match}
          onClose={handleRedirectToHome}
        >
          <div className={"flex flex-col gap-6 p-6"}>
            <AccessibleOnDesktopIllustration
              width={300}
              height={200}
              data-testid={testid + "__blanket__icon"}
              className={"m-auto block"}
            />
            <div className={"flex flex-col gap-2 md:items-center"}>
              <span
                className={"text-he text-bold headline-h2 h2"}
                data-testid={testid + "__blanket__title"}
              >
                Rasakan Pengalaman Terbaik Menggunakan Fitur Ini di Desktop
              </span>
              <span
                className={"text-he body-b2 md:text-center "}
                data-testid={testid + "__blanket__text"}
              >
                Untuk mengakses fitur ini, kamu bisa membukanya melalui komputer
                / laptop (desktop web).
              </span>
            </div>

            <div className={"flex flex-col-reverse gap-4"}>
              <BaseButton
                variant={"primary"}
                data-testid={testid + "__blanket__button"}
                onClick={handleRedirectToHome}
              >
                Kembali ke Beranda
              </BaseButton>
            </div>
          </div>
        </BaseMobileDrawer>
      ) : null}
    </div>
  );
};

export default DesktopFeatureBlockerModal;
