export interface SubaccountQueryKeys {
  all: () => readonly ["subaccount"];
  accessesList: () => readonly ["subaccount", "accesses-list"];
  accessesListTemplate: () => readonly ["subaccount", "accesses-templates"];
  details: (id: string) => readonly ["subaccount", "details", { id: string }];
}

export const subaccountQueryKeys: SubaccountQueryKeys = {
  all: () => ["subaccount"] as const,
  accessesList: () => [...subaccountQueryKeys.all(), "accesses-list"] as const,
  accessesListTemplate: () =>
    [...subaccountQueryKeys.all(), "accesses-templates"] as const,
  details: (id: string) =>
    [...subaccountQueryKeys.all(), "details", { id }] as const,
};

export default subaccountQueryKeys;
