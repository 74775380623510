import { FetchError } from "@saas/core";

import {
  updateReadNotification,
  UpdateReadNotificationInput,
  UpdateReadNotificationOutput,
} from "../..";

import { useMutation } from "@tanstack/react-query";

export type UseUpdateReadNotificationMutationPayload =
  UpdateReadNotificationInput;

export type UseUpdateReadNotificationMutationData =
  UpdateReadNotificationOutput;

export const useUpdateReadNotificationMutation = () => {
  return useMutation<
    UseUpdateReadNotificationMutationData,
    FetchError,
    UseUpdateReadNotificationMutationPayload
  >(async (payload: UseUpdateReadNotificationMutationPayload) =>
    updateReadNotification(payload)
  );
};

export default useUpdateReadNotificationMutation;
