import { Fragment, ReactNode, useRef } from "react";

import { classNames } from "@saas/shared/utils";

import { Dialog, Transition } from "@headlessui/react";

enum Size {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export interface SlideOverProps {
  origin: "left" | "right";
  size?: `${Size}`;
  open: boolean;
  onClose: (open: false) => Promise<void> | void;
  children: ReactNode;
}

export const SlideOver = ({
  origin,
  size,
  open,
  onClose,
  children,
}: SlideOverProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const sizeClass = {
    [Size.SMALL]: "max-w-[17rem]",
    [Size.MEDIUM]: "max-w-md",
    [Size.LARGE]: "max-w-lg",
  };

  const transitionClass = {
    left: "-translate-x-full",
    right: "translate-x-full",
  };

  const positionClass = {
    left: "left-0",
    right: "right-0",
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog onClose={onClose} initialFocus={contentRef}>
        <div className={"fixed inset-0 z-20 overflow-hidden"}>
          <div className={"absolute inset-0 overflow-hidden"}>
            <Dialog.Overlay className={"bg-neutral-N700/40 absolute inset-0"} />
            <div
              className={classNames(
                "pointer-events-none fixed inset-y-0 flex max-w-full",
                positionClass[origin]
              )}
            >
              <Transition.Child
                as={Fragment}
                enter={
                  "transform transition ease-in-out duration-500 sm:duration-700"
                }
                enterFrom={transitionClass[origin]}
                enterTo={"translate-x-0"}
                leave={
                  "transform transition ease-in-out duration-500 sm:duration-700"
                }
                leaveFrom={"translate-x-0"}
                leaveTo={transitionClass[origin]}
              >
                <div
                  className={classNames(
                    "pointer-events-auto w-screen",
                    size && sizeClass[size]
                  )}
                >
                  <div
                    ref={contentRef}
                    className={classNames(
                      "bg-neutral-N0 shadow-elevation-1 flex h-full flex-col overflow-y-auto"
                    )}
                  >
                    {children}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SlideOver;
