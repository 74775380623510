export enum AccessesKeyEnum {
  MAIN_DASHBOARD_ALL = "main_dashboard:all",
  PAYMENT_ALL = "payment:all",
  POS_ALL = "pos:all",
  ORDER_VIEW = "order:view",
  ORDER_ACTION = "order:action",
  PRODUCT_VIEW = "produk:view",
  PRODUCT_ACTION = "produk:action",
  STOCK_VIEW = "stock:view",
  STOCK_ACTION = "stock:action",
  ACCOUNTING_JOURNAL_ALL = "accounting_journal:all",
  REPORT_LIST_ALL = "reports_list:all",
  CUSTOMER_ANALYSIS_ALL = "customer_analysis:all",
  PRICE_MONITORING_ALL = "price_monitoring:all",
  CATALOG_ALL = "catalog:all",
  BROADCAST_CAMPAIGN_ALL = "broadcast_campaign:all",
  PROMOTION_ALL = "promotion:all",
  PRODUCT_COLLECTION_VIEW = "product_collection:view",
  PRODUCT_COLLECTION_ACTION = "product_collection:action",
  MARKETPLACE_CONNECTION_VIEW = "marketplace_connection:view",
  MARKETPLACE_CONNECTION_ACTION = "marketplace_connection:action",
  CHAT_ALL = "chat:all",
  SHOP_MONITORING_ALL = "shop_monitoring:all",
  TERM_OF_PAYMENT_ALL = "term_of_payment:all",
  SALESPERSON_ALL = "salesperson:all",
  SETTINGS_SERVICE_FEE_ALL = "settings_service_fee:all",
  LOYALTY_POINTS_ACTION = "loyalty_points:action",
  LOYALTY_POINTS_VIEW = "loyalty_points:view",
  PAYMENT_METHOD_ALL = "payment_method:all",
  VIRTUAL_BUNDLING_VIEW = "virtual_bundling:view",
  VIRTUAL_BUNDLING_ACTION = "virtual_bundling:action",
  WAREHOUSE_VIEW = "warehouse:view",
  WAREHOUSE_ACTION = "warehouse:action",
  VOUCHER_VIEW = "voucher:view",
  VOUCHER_ACTION = "voucher:action",
}

export default AccessesKeyEnum;
