import { Fragment, useState } from "react";

import { mediaQuery, useMediaQuery } from "@saas/core";
import { useGetUnreadChatRooms, useMarkAsReadMessage } from "@saas/crm/feature";
import { ChatRoomPreview } from "@saas/crm/ui";
import { ChatRoomModel } from "@saas/crm/utils";
import { useConnectedChatMarketplaceConnectionsQuery } from "@saas/marketplace-connection/feature";
import { NotificationChatIcon } from "@saas/notification/ui";
import { CloseIcon, NoNotificationTwoTonedIcon } from "@saas/shared/icon";
import { BaseButton, Divider } from "@saas/shared/ui";
import { classNames } from "@saas/shared/utils";

import { Popover, Transition } from "@headlessui/react";
import { stringify } from "qs";
import { usePopper } from "react-popper";

const testid = "inapp-chat-notification";

export const ChatNotificationContainer = () => {
  const { isDesktop } = useMediaQuery(mediaQuery.md);

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
  });

  const { data: chatConnections = [] } =
    useConnectedChatMarketplaceConnectionsQuery();
  const { data: chatRooms } = useGetUnreadChatRooms();

  const hasChatConnection = chatConnections.length > 0;
  const totalUnread = chatRooms?.totalUnread ?? 0;
  const chatList = chatRooms?.data ?? [];

  return (
    <Popover>
      {({ open, close }) => (
        <>
          <Transition
            show={open}
            as={Fragment}
            enter={"ease-out duration-300 md:duration-100"}
            enterFrom={"transform opacity-0"}
            enterTo={"transform opacity-100"}
            leave={"ease-in duration-200 md:duration-75"}
            leaveFrom={"transform opacity-100"}
            leaveTo={"transform opacity-0"}
          >
            <div
              className={"bg-neutral-N700/40 fixed inset-0 z-10 md:hidden"}
            />
          </Transition>

          <div className={"relative h-full w-full"}>
            <Popover.Button
              aria-label={"chat-notification-icon"}
              ref={setReferenceElement}
              className={classNames(
                "cursor-pointer rounded px-0.5 pt-1 pb-0.5 focus:outline-none",
                open ? "bg-blue-B100" : null
              )}
              data-testid={`${testid}__button__open-notification`}
            >
              <NotificationChatIcon testid={testid} totalUnread={totalUnread} />
            </Popover.Button>

            <Transition
              show={open}
              as={Fragment}
              enter={"ease-out duration-300 md:duration-100"}
              enterFrom={
                "transform opacity-0 translate-y-4 md:translate-y-0 md:scale-y-95"
              }
              enterTo={"transform opacity-100 translate-y-0 scale-100"}
              leave={"ease-in duration-200 md:duration-75"}
              leaveFrom={"transform opacity-100 translate-y-0 scale-100"}
              leaveTo={
                "transform opacity-0 translate-y-4 md:translate-y-0 md:scale-y-95"
              }
            >
              <Popover.Panel
                ref={setPopperElement}
                className={
                  "fixed inset-0 z-10 mt-auto flex w-full flex-1 items-end md:absolute md:inset-auto md:mt-3 md:w-[413px]"
                }
                style={isDesktop ? styles["popper"] : undefined}
                {...attributes["popper"]}
              >
                <div
                  className={
                    "md:shadow-elevation-1 bg-neutral-N0 shadow-elevation-2 relative w-full rounded-t-xl text-base md:overflow-y-auto md:overflow-x-hidden md:rounded-lg"
                  }
                >
                  <hr
                    className={
                      "bg-neutral-N0 relative -top-3 z-10 mx-auto h-1 w-14 rounded-3xl shadow-sm  md:hidden"
                    }
                  />

                  <div className={"flex items-center gap-4 px-5 py-4 md:p-0"}>
                    <button
                      className={"md:hidden"}
                      data-testid={`${testid}__button__close`}
                      onClick={() => close()}
                    >
                      <CloseIcon className={"text-neutral-N600 h-5 w-5"} />
                    </button>

                    <h3
                      className={"title-large text-he md:px-4 md:pb-2 md:pt-4"}
                      data-testid={`${testid}__text__header`}
                    >
                      Notifikasi Chat
                    </h3>
                  </div>

                  <Divider orientation={"horizontal"} />

                  {hasChatConnection ? (
                    <ChatList data={chatList} />
                  ) : (
                    <NoChatConnection />
                  )}
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        </>
      )}
    </Popover>
  );
};

const NoChatConnection = () => {
  return (
    <div className={"flex items-center justify-center gap-4 p-6"}>
      <NoNotificationTwoTonedIcon className={"h-8 w-8 flex-shrink-0"} />
      <p
        className={"body-b2 text-he"}
        data-testid={`${testid}__text__empty-chat-connection`}
      >
        Kamu belum memiliki koneksi chat. Untuk mendapatkan notifikasi chat
        koneksikan chat toko online Kamu.
      </p>
    </div>
  );
};

const ChatList = ({ data }: { data: Array<ChatRoomModel> }) => {
  const { mutate } = useMarkAsReadMessage();
  const handleSeeMore = () => {
    window.open("/crm/chat", "_blank");
  };

  const handleOpenChatRoom = (data: ChatRoomModel) => {
    mutate({
      chatRoomId: data.id,
    });

    const queryString = stringify({
      shopId: data.shopId,
      roomId: data.id,
    });

    window.open("/crm/chat/" + data.channel + "?" + queryString, "_blank");
  };

  if (data.length === 0) return <EmptyChatList />;

  return (
    <div className={"border-neutral-N200 divide-y"}>
      {data.slice(0, 5).map((data, index) => (
        <ChatRoomPreview
          key={index}
          testid={testid}
          data={data}
          isActive={true}
          onOpenChatRoom={handleOpenChatRoom}
        />
      ))}
      {data.length > 5 ? (
        <div className={"flex justify-center"}>
          <BaseButton
            variant={"secondary"}
            size={"mini"}
            className={"mt-3 mb-4"}
            onClick={handleSeeMore}
          >
            Lihat pesan lainnya
          </BaseButton>
        </div>
      ) : null}
    </div>
  );
};

const EmptyChatList = () => {
  return (
    <div className={"flex items-center justify-center gap-4 p-6"}>
      <NoNotificationTwoTonedIcon className={"h-8 w-8 flex-shrink-0"} />
      <p
        className={"body-b2 text-he"}
        data-testid={`${testid}__text__empty-connection`}
      >
        Tidak ada notifikasi
      </p>
    </div>
  );
};

export default ChatNotificationContainer;
