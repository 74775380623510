import router from "next/router";

import { NoInternetConnectionIllustration } from "@saas/shared/illustration";

import BaseButton from "../lib/base-button/base-button";

export const OfflineState = () => {
  const handleRetry = () => {
    router.reload();
  };

  return (
    <div className={"flex flex-col gap-8 text-center"}>
      <NoInternetConnectionIllustration
        className={"m-auto h-auto md:h-[200px] md:w-[300px]"}
      />

      <div>
        <h2 className={"headline-h2 text-he mb-2 block"}>
          Yah, Internetnya mati
        </h2>

        <span className={"body-b2 text-he mb-2 block"}>
          Cek kembali koneksi internet yang digunakan, dan segarkan halaman.
        </span>

        <BaseButton onClick={handleRetry} variant={"primary"}>
          Segarkan Halaman ini
        </BaseButton>
      </div>
    </div>
  );
};

export default OfflineState;
