import { FetchError, queryClient } from "@saas/core";
import { ChannelEnum, QueryOptionsInterface } from "@saas/shared/utils";

import chatQueryKey from "../../../chat/chat/chat.query-key";

import { Updater, useQuery } from "@tanstack/react-query";

export type UseSyncChatInfoQueryParams = {
  channels: ReadonlyArray<ChannelEnum>;
};

export type UseSyncChatInfoQueryData = {
  errorSynchedChannels: ReadonlyArray<ChannelEnum>;
  synchedChannels: ReadonlyArray<ChannelEnum>;
  syncing: boolean;
};

export const useSyncChatInfoQuery = <
  DerivedQueryData = UseSyncChatInfoQueryData
>(
  params: UseSyncChatInfoQueryParams,
  options?: QueryOptionsInterface<UseSyncChatInfoQueryData, DerivedQueryData>
) => {
  return useQuery<UseSyncChatInfoQueryData, FetchError, DerivedQueryData>(
    chatQueryKey.sync(params.channels),
    () => {
      return Promise.resolve({
        errorSynchedChannels: [],
        synchedChannels: [],
        syncing: false,
      });
    },
    {
      ...options,
      select: options?.select,
      enabled: options?.enabled,
    }
  );
};

export const setUseSyncChatInfoQuery = (
  params: UseSyncChatInfoQueryParams,
  updater: Updater<
    UseSyncChatInfoQueryData | undefined,
    UseSyncChatInfoQueryData
  >
): void => {
  queryClient.setQueryData<UseSyncChatInfoQueryData>(
    chatQueryKey.sync(params.channels),
    updater
  );
};

export const getUseSyncChatInfoQuery = (
  params: UseSyncChatInfoQueryParams
): UseSyncChatInfoQueryData | undefined => {
  return queryClient.getQueryData<UseSyncChatInfoQueryData>(
    chatQueryKey.sync(params.channels)
  );
};

export const prefetchUseSyncChatInfoQuery = async (
  params: UseSyncChatInfoQueryParams,
  cookie?: string
): Promise<UseSyncChatInfoQueryData | null> => {
  let data: UseSyncChatInfoQueryData | null;

  try {
    data = await Promise.resolve({
      errorSynchedChannels: [],
      synchedChannels: [],
      syncing: false,
    });
  } catch {
    data = null;
  }

  await queryClient.prefetchQuery(
    chatQueryKey.sync(params.channels),
    () => data
  );

  return data;
};

export const invalidateUseSyncChatInfoQuery = (
  params: UseSyncChatInfoQueryParams
) => {
  return queryClient.invalidateQueries(chatQueryKey.sync(params.channels));
};

export default useSyncChatInfoQuery;
