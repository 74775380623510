import { camelCase, isNaN } from "lodash-es";

export interface Breadcrumb {
  href: string;
  label: string;
  current?: boolean;
}

export type GenerateBreadcrumbsInput = {
  labelGenerator: (path: string) => string;
  title?: string;
  asPath: string;
};

export type GenerateBreadcrumbsOutput = Breadcrumb[];

export const generateBreadcrumbs = ({
  labelGenerator,
  title,
  asPath,
}: GenerateBreadcrumbsInput): GenerateBreadcrumbsOutput => {
  const pathWithoutQuery = asPath.split("?")[0];

  let pathArray = pathWithoutQuery.split("/");

  pathArray.shift();

  pathArray = pathArray.filter((path) => path !== "");

  const isNanPathArray = pathArray.filter((path) => isNaN(+path));

  return isNanPathArray.length > 1
    ? isNanPathArray.map((path, index) => {
        const href = "/" + pathArray.slice(0, index + 1).join("/");

        return {
          href,
          label: labelGenerator(camelCase(path)) ?? title,
          current: index === isNanPathArray.length - 1,
        };
      })
    : [];
};

export default generateBreadcrumbs;
