import { env } from "@saas/config/shared";

import { ampli, Environment, type IdentifyProperties } from "./_generated";

import { pageViewTrackingPlugin } from "@amplitude/plugin-page-view-tracking-browser";

const initAmplitude = () => {
  if (!ampli.isLoaded) {
    ampli.load({
      environment: env.APP_ENV as Environment,
      client: { apiKey: env.AMPLITUDE_API_KEY },
    });

    ampli.client.add(
      pageViewTrackingPlugin(ampli.client, { trackHistoryChanges: "pathOnly" })
    );
  }
};

const setAmplitudeUser = (userId?: string, properties?: IdentifyProperties) => {
  ampli.identify(userId, properties);
};

const resetAmplitude = () => {
  ampli.client.reset();
};

export { ampli, initAmplitude, resetAmplitude, setAmplitudeUser };
export default ampli;
