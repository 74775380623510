import { isArray } from "lodash-es";

export const cleanPayload = <T>(object: T): Partial<T> => {
  const payload = object;

  for (const key in payload) {
    const value = payload[key];

    if (typeof value === "object" && !isArray(value))
      if (!value) delete payload[key];
      else cleanPayload(payload[key]);
    else if (typeof value === "string" && value === "") delete payload[key];
    else if (typeof value === "number" && isNaN(value)) delete payload[key];
    else if (isArray(value) && !value.length) delete payload[key];
    else if (value === undefined) delete payload[key];
  }

  return payload;
};

export default cleanPayload;
