import { QueryKey } from "@saas/account/config";
import { FetchError, queryClient } from "@saas/core";
import { QueryOptionsInterface } from "@saas/shared/utils";

import fetchSession from "../../lib/fetch-session/fetch-session";

import { Session } from "@ory/client";
import { Updater, useQuery } from "@tanstack/react-query";

export type UseSessionQueryData = Session;

export const useSessionQuery = <DerivedQueryData = UseSessionQueryData>(
  options?: QueryOptionsInterface<UseSessionQueryData, DerivedQueryData>
) => {
  return useQuery<UseSessionQueryData, FetchError, DerivedQueryData>(
    [QueryKey.SESSION],
    async () => {
      const data = await fetchSession();

      return data;
    },
    {
      ...options,
      select: options?.select,
      enabled: options?.enabled,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: true,
    }
  );
};

export const setUseSessionQuery = (
  updater: Updater<UseSessionQueryData | undefined, UseSessionQueryData>
): void => {
  queryClient.setQueryData<UseSessionQueryData>([QueryKey.SESSION], updater);
};

export const prefetchUseSessionQuery = async (
  cookie?: string
): Promise<UseSessionQueryData | null> => {
  let data: UseSessionQueryData | null;

  try {
    data = await fetchSession(cookie);
  } catch {
    data = null;
  }

  await queryClient.prefetchQuery([QueryKey.SESSION], () => data);

  return data;
};

export const invalidateUseSessionQuery = () => {
  return queryClient.invalidateQueries([QueryKey.SESSION]);
};

export const removeUseSessionQuery = () => {
  return queryClient.removeQueries([QueryKey.SESSION]);
};

export default useSessionQuery;
