enum Variant {
  INFORMATION = "information",
  POSITIVE = "positive",
  WARNING = "warning",
  NEGATIVE = "negative",
}

export interface AlertInterface {
  id?: string;
  variant?: `${Variant}`;
  content: string;
  action?: {
    label: string;
    onClick: () => void | Promise<void>;
  };
  testid?: string;
}

export enum AlertActionType {
  ADD_MESSAGE = "ADD MESSAGE",
  REMOVE_MESSAGE = "REMOVE MESSAGE",
}

export type AlertAction =
  | { type: AlertActionType.ADD_MESSAGE; payload: AlertInterface }
  | { type: AlertActionType.REMOVE_MESSAGE; payload: { id: string } };

export type AlertDispatch = (action: AlertAction) => void;

export default AlertInterface;
