import { QueryKey } from "@saas/account/config";
import { queryClient } from "@saas/core";
import { QueryOptionsInterface } from "@saas/shared/utils";

import { kratos } from "../..";

import { useQuery } from "@tanstack/react-query";

export const useLogoutUrlQuery = (
  options?: QueryOptionsInterface<string, string>
) => {
  return useQuery(
    [QueryKey.AUTH_LOGOUT],
    () =>
      kratos.createBrowserLogoutFlow().then(({ data }) => {
        return data.logout_token;
      }),
    {
      retry: false,
      enabled: options?.enabled,
    }
  );
};

export const invalidateLogoutUrlQuery = async () => {
  await queryClient.invalidateQueries([QueryKey.AUTH_LOGOUT]);
};

export default useLogoutUrlQuery;
