import { ComponentPropsWithoutRef, forwardRef } from "react";

import { ampli } from "@saas/core";
import { SpinnerIcon, SVG } from "@saas/shared/icon";
import { classNames, createShareWhatsappLink } from "@saas/shared/utils";

import { Typography } from "..";

const Variant = {
  PRIMARY: "primary",
  TERTIARY: "tertiary",
} as const;

const Size = {
  SMALL: "small",
  BIG: "big",
} as const;

const Fill = {
  SQUARE: "square",
  ROUNDED: "rounded",
} as const;
export interface ScheduleDemoButtonProps
  extends ComponentPropsWithoutRef<"button"> {
  /**
   * The HTML element to render
   * @default "button"
   */
  as?: "button" | "span";
  /**
   * The variant of the button
   */
  variant: typeof Variant[keyof typeof Variant];
  /**
   * The fill type of the button
   * @default "square"
   */
  fill?: typeof Fill[keyof typeof Fill];
  /**
   * The size of the button
   * @default "big"
   */
  size?: typeof Size[keyof typeof Size];
  /**
   * The icon to display
   */
  icon?: SVG;
  /**
   * Loading state of the button
   * @default false
   */
  isLoading?: boolean;
  /**
   * Class name for the button's text
   */
  testid?: string;
  phoneNumber: string;
}

export const ScheduleDemoButton = forwardRef<
  HTMLButtonElement,
  ScheduleDemoButtonProps
>(
  (
    {
      as: Element = "button",
      variant = "tertiary",
      size = Size.BIG,
      fill = Fill.SQUARE,
      icon: Icon,
      children,
      className,
      testid = "landing",
      isLoading = false,
      disabled,
      phoneNumber,
      onClick,
      ...props
    }: ScheduleDemoButtonProps,
    ref
  ) => {
    const whatsappLink = createShareWhatsappLink({
      tel: phoneNumber,
      text: "Hi Admin, saya mau dijadwalkan untuk demo",
    });

    const handleTracking = () => {
      const { location, document } = window;

      ampli.contactCs({
        button_name: "Jadwalkan Demo",
        purpose: "schedule_demo",
        page_domain: location.origin,
        page_location: location.href,
        page_path: location.pathname,
        page_title: document.title,
        page_url: location.href,
      });

      window.open(whatsappLink, "_blank");
    };

    const variantClass = {
      [Variant.PRIMARY]:
        "rounded-xl bg-gradient-to-r from-[#A15BD7] to-[#1164FF] px-6 py-3",
      [Variant.TERTIARY]:
        "text-disabled enabled:text-he border-neutral-N300 enabled:hover:text-le enabled:focus:text-button border",
    };

    const iconClass = {
      [Variant.PRIMARY]: "text-disabled group-enabled:text-invert",
      [Variant.TERTIARY]: "text-disabled group-enabled:text-le",
    };

    const sizeClass = {
      [Size.SMALL]: "py-2 px-6",
      [Size.BIG]: "py-3 px-6",
    };

    const fillClass = {
      [Fill.SQUARE]: "rounded-xl",
      [Fill.ROUNDED]: "rounded-full",
    };

    const typographyClassName = {
      [Variant.PRIMARY]: "text-invert font-bold",
      [Variant.TERTIARY]: "hover:text-blue-B700 uppercase",
    };

    return (
      <Element
        type={props.type ?? "button"}
        className={classNames(
          "group relative inline-flex items-center justify-center gap-2 enabled:focus:outline-none",
          variantClass[variant],
          sizeClass[size],
          fillClass[fill],
          (disabled || isLoading) && "cursor-not-allowed",
          className
        )}
        ref={ref}
        data-testid={`${testid}__button__schedule-demo`}
        disabled={disabled || isLoading}
        onClick={handleTracking}
        {...props}
      >
        {Icon ? (
          <Icon
            className={classNames(
              size === "big" ? "h-6 w-6" : "h-4 w-4",
              iconClass[variant]
            )}
          />
        ) : null}

        <Typography
          type={"button-small"}
          className={classNames(
            isLoading && "invisible",
            typographyClassName[variant]
          )}
        >
          Jadwalkan Demo
        </Typography>

        {isLoading ? (
          <span className={classNames("absolute flex")}>
            <SpinnerIcon
              className={classNames(
                "text-neutral-N0 animate-spin self-center",
                size === "big" ? "h-6 w-6" : "h-4 w-4"
              )}
            />
          </span>
        ) : null}
      </Element>
    );
  }
);

ScheduleDemoButton.displayName = "ScheduleDemoButton";

export default ScheduleDemoButton;
