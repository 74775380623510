import { env } from "@saas/config/shared";

import ReactGA from "react-ga4";

export const initializeGA = () => {
  if (env.GOOGLE_ANALYTICS_ID) ReactGA.initialize(env.GOOGLE_ANALYTICS_ID);
};

export const pageviewGA = () => {
  ReactGA.send("pageview");
};

export interface eventGAProps {
  name?: string;
  params: {
    action: string;
    category: string;
    label?: string;
    value?: number;
    nonInteraction?: boolean;
    [customProperty: string]: unknown;
  };
}

export const eventGA = ({ name, params }: eventGAProps) => {
  if (name) {
    return ReactGA.event(name, params);
  }
  return ReactGA.event(params);
};
