export type GetInitialsOutput = {
  first: string;
  last?: string;
};

export const getInitials = (name: string): GetInitialsOutput => {
  const [head, ...rest] = name.split(" ");
  const last = rest.pop();

  const firstInitial = head.toUpperCase()[0];
  const lastInitial = last?.toUpperCase()[0];

  return {
    first: firstInitial,
    last: lastInitial,
  };
};

export default getInitials;
