import { queryClient } from "@saas/core";
import { chatQueryKey } from "@saas/crm/data";
import {
  type ChatRoomMessageModel,
  type LoadChatRoomMessagesPayloadInterface,
} from "@saas/crm/utils";
import { type PaginationInterface } from "@saas/shared/utils";

export interface LoadChatRoomMessagesResponse {
  data: Array<ChatRoomMessageModel>;
  pagination: PaginationInterface;
  requestId?: string;
  statusCode?: number;
  type?: string;
}

export type LoadChatRoomMessagesHandlerInput = {
  response: LoadChatRoomMessagesResponse;
  requestPayload?: Partial<LoadChatRoomMessagesPayloadInterface>;
};

export type LoadChatRoomMessagesHandlerOutput = void;

export const loadChatRoomMessagesHandler = ({
  response,
  requestPayload,
}: LoadChatRoomMessagesHandlerInput): LoadChatRoomMessagesHandlerOutput => {
  const requestId = response.requestId ?? "";
  const isSync = requestId?.includes("isSync");

  if (isSync) {
    const [chatRoomId] = requestId.split(",");

    queryClient.setQueryData<LoadChatRoomMessagesResponse>(
      chatQueryKey.chatRoom([], Number(chatRoomId)),
      (prevData) => {
        if (!prevData) {
          return undefined;
        }

        return {
          ...prevData,
          chatRoomId: Number(chatRoomId) ?? 0,
          data: response.data,
        };
      }
    );
  }

  if (requestPayload?.chatRoomId && !isSync) {
    queryClient.setQueryData<LoadChatRoomMessagesResponse>(
      chatQueryKey.chatRoom([], requestPayload.chatRoomId),
      (prevData) => {
        if (!prevData) {
          return {
            chatRoomId: requestPayload?.chatRoomId ?? 0,
            data: response.data,
            pagination: response.pagination,
          };
        }

        const newData = [...response.data, ...prevData.data];

        return {
          chatRoomId: requestPayload?.chatRoomId ?? 0,
          data: newData,
          pagination: response.pagination,
        };
      }
    );
  }
};

export default loadChatRoomMessagesHandler;
