import { fetchJson } from "@saas/core";
import {
  ChannelEnum,
  ChannelTypeEnum,
  mapToCamelCase,
} from "@saas/shared/utils";

import { stringify } from "qs";

export type ActiveChannelSchema = {
  id: number;
  channelName: ChannelEnum;
  channelType: ChannelTypeEnum;
  stockAllocation: number;
  isPriorityChannel: boolean;
};

export type GetActiveChannelsInput = {
  channels?: string;
};

export type GetActiveChannelsOutput = {
  status_code: number;
  message: string;
  data: ActiveChannelSchema[];
};

export const getActiveChannels = async (
  input?: GetActiveChannelsInput,
  options?: RequestInit
): Promise<GetActiveChannelsOutput> => {
  const queryString = stringify(
    { channel: input },
    {
      arrayFormat: "comma",
      encode: false,
    }
  );

  const response = await fetchJson<GetActiveChannelsOutput>(
    `/api/inventory/inventory/master-products/stocks/allocation?` + queryString,
    options
  );
  return mapToCamelCase(response);
};

export default getActiveChannels;
