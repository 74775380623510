export const getContentDispositionFilename = (
  input: Headers
): string | undefined => {
  const match = /filename=["'](.*)["']/g.exec(
    input.get("content-disposition") as string
  );

  const [, filename] = match ?? [];

  return filename;
};

export default getContentDispositionFilename;
