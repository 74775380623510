import { AccessesKeyEnum } from "../..";

import { Session } from "@ory/client";

export interface SessionProfile {
  id: string;
  name: string;
  email: string;
  phone: string;
  isVerified: boolean;
  isActive: boolean;
  accesses: AccessesKeyEnum[];
  parentUserId: string | null;
}

export interface SessionMetadata {
  accesses: AccessesKeyEnum[];
  parent_user_id: string | null;
}

export const getProfileFromSession = (
  session?: Session
): SessionProfile | null => {
  if (!session) {
    return null;
  }

  let metadata: SessionMetadata = {
    accesses: [],
    parent_user_id: null,
  };

  if (session.identity.metadata_public) {
    metadata = session.identity.metadata_public as SessionMetadata;
  }

  return {
    id: session.identity.id,
    name: session.identity.traits.name,
    email: session.identity.traits.email,
    phone: session.identity.traits.phone,
    isVerified: session.identity.verifiable_addresses?.[0].verified ?? false,
    isActive: session.active ?? false,
    accesses: metadata.accesses,
    parentUserId: metadata.parent_user_id,
  };
};

export default getProfileFromSession;
