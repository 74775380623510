import { Dispatch, SetStateAction, useState } from "react";

import { mediaQuery } from "@saas/core";

import { useIsomorphicLayoutEffect, useWindowSize } from "usehooks-ts";

export interface UseLayoutShowAside {
  showAside: boolean;
  setShowAside: Dispatch<SetStateAction<boolean>>;
}

export const useLayoutShowAside = (): UseLayoutShowAside => {
  const { width } = useWindowSize();

  const [showAside, setShowAside] = useState(false);

  useIsomorphicLayoutEffect(() => {
    if (width < mediaQuery.md) setShowAside(false);
  }, [width]);

  return { setShowAside, showAside };
};

export default useLayoutShowAside;
