// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { GetActiveChannelsInput } from "@saas/inventory/data-access";

export interface ActiveChannelsQueryKeys {
  all: (
    queries?: GetActiveChannelsInput
  ) => readonly ["activeChannels", GetActiveChannelsInput];
}

export const activeChannelsQueryKeys: ActiveChannelsQueryKeys = {
  all: (queries?: GetActiveChannelsInput) =>
    ["activeChannels", { ...queries }] as const,
};

export default activeChannelsQueryKeys;
