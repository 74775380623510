import { FlagKeys } from "../..";

import { useFeatures } from "flagged";

export type UseFlagsStatusReturnType = Record<FlagKeys, boolean>;

export const useFlagsStatus = (): UseFlagsStatusReturnType => {
  return useFeatures() as UseFlagsStatusReturnType;
};

export default useFlagsStatus;
