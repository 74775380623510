import { queryClient } from "@saas/core";
import { chatQueryKey } from "@saas/crm/data";
import { type ReceivedMessageModel } from "@saas/crm/utils";

import { type UseGetChatRoomMessagesData } from "../@hook/get-chat-room-messages/use-get-chat-room-messages";
import { type UseGetChatRoomsData } from "../@hook/get-chat-rooms/use-get-chat-rooms";
import { type UseUnreadMessageCountData } from "../@hook/unread-message-count/use-unread-message-count";

export type ReceiveMessageHandlerInput = {
  response: {
    data: ReceivedMessageModel;
  };
  sendMessageProvider: <T>(payload: T) => void;
};

export type ReceiveMessageHandlerOutput = void;

export const receiveMessageHandler = ({
  response,
  sendMessageProvider,
}: ReceiveMessageHandlerInput): ReceiveMessageHandlerOutput => {
  invalidateUnreadChatRoomsQuery();
  updateUnreadChatRoomsCount(response.data);
  updateChatRoomMessage(response.data);
  appendNewMessageIntoChatRoom(response.data);
  sendMarkAsRead(response.data, sendMessageProvider);
};

const invalidateUnreadChatRoomsQuery = () => {
  queryClient.invalidateQueries(
    chatQueryKey.allUnreadChatRoomsBychannel([], "")
  );
};

const updateUnreadChatRoomsCount = (data: ReceivedMessageModel) => {
  const { channel, shopId, unreadChatRoomChannel, unreadChatRoomShop } = data;

  queryClient.setQueryData<UseUnreadMessageCountData>(
    chatQueryKey.allUnreadChatRoomsCount(),
    (prevData) => {
      if (!prevData)
        return {
          [channel]: {
            totalCount: unreadChatRoomChannel,
            shopList: {
              [shopId]: unreadChatRoomShop,
            },
          },
        };

      const channelData = prevData[channel];

      return {
        ...prevData,
        [channel]: {
          totalCount: unreadChatRoomChannel,
          shopList: {
            ...channelData?.shopList,
            [shopId]: unreadChatRoomShop,
          },
        },
      };
    }
  );
};

const updateChatRoomMessage = (data: ReceivedMessageModel) => {
  const {
    chatRoomId,
    channel,
    unreadChatRoomMessage,
    message,
    shopId,
    timestamp,
  } = data;

  queryClient.setQueriesData<UseGetChatRoomsData>(
    {
      queryKey: chatQueryKey.allChatRoomsByShop([channel], shopId).slice(0, 3),
    },
    (prevData) => {
      if (!prevData) return undefined;

      const newData = prevData.data.map((item) => {
        if (item.id !== chatRoomId) return item;
        return {
          ...item,
          totalUnreadCount: unreadChatRoomMessage,
          lastMessage: message,
          lastUpdatedAt: timestamp,
        };
      });

      return {
        ...prevData,
        data: newData,
      };
    }
  );
};

const appendNewMessageIntoChatRoom = (data: ReceivedMessageModel) => {
  const { chatRoomId } = data;
  queryClient.setQueryData<UseGetChatRoomMessagesData>(
    chatQueryKey.chatRoom([], chatRoomId),
    (prevData) => {
      if (!prevData) return undefined;

      const newData = [...prevData.data, data];

      return {
        ...prevData,
        data: newData,
      };
    }
  );
};

const sendMarkAsRead = (
  data: ReceivedMessageModel,
  callback: <T>(payload: T) => void
) => {
  const { chatRoomId } = data;

  const activeChatRoom = queryClient.getQueryState(
    chatQueryKey.chatRoom([], chatRoomId),
    {
      type: "active",
    }
  );

  // NOTES: send mark as read when query is active
  if (activeChatRoom)
    callback({
      type: "mark_as_read",
      chat_room_id: chatRoomId,
    });
};

export default receiveMessageHandler;
