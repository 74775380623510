import { fetchJson, ResponseInterface } from "@saas/core";
import { mapToCamelCase, mapToSnakeCase } from "@saas/shared/utils";

export interface RegisterNotificationUserResponse extends ResponseInterface {
  error_code: number;
}

export type RegisterNotificationUserInput = {
  deviceId: string;
  deviceType: string;
  isSubscribed: boolean;
};

export type RegisterNotificationUserOutput = {
  statusCode: number;
  message?: string;
};

export const registerNotificationUser = async (
  input: RegisterNotificationUserInput,
  options?: RequestInit
): Promise<RegisterNotificationUserOutput> => {
  const response = await fetchJson<RegisterNotificationUserResponse>(
    "/api/notifications/notifications/user",
    {
      ...options,
      method: "POST",
      body: JSON.stringify(mapToSnakeCase(input)),
    }
  );

  return mapToCamelCase(response);
};

export default registerNotificationUser;
