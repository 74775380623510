export enum MarketplaceListStatusEnum {
  PENDING = "pending",
  CONNECTED = "connected",
  REQUEST_TO_REMOVE = "request_to_remove",
  REMOVED = "removed",
  EXPIRED = "expired",
  UNCONNECTED = "unconnected",
}

export default MarketplaceListStatusEnum;
