import { useRouter } from "next/router";

import { mediaQuery } from "@saas/core";

import { useWindowSize } from "usehooks-ts";

export type UsePathMatchReturnType = { match: boolean };
interface UsePathMatchConfig {
  desktop?: boolean;
  mobile?: boolean;
}

export const usePathMatch = (
  paths: Array<string>,
  excludedValues: Array<string> = [],
  config: UsePathMatchConfig = {}
): UsePathMatchReturnType => {
  const { pathname } = useRouter();
  const { width } = useWindowSize();
  const isDesktop = width >= mediaQuery.md;

  // Check if the current path matches any of the path keys
  const isFirstPathMatch = paths.some(
    (key) => pathname.includes(key) && !excludedValues.includes(key)
  );

  let match = false;

  switch (true) {
    case config?.mobile:
      match = isFirstPathMatch && !isDesktop;
      break;
    case config?.desktop:
      match = isFirstPathMatch && isDesktop;
      break;
    default:
      match = isFirstPathMatch;
      break;
  }

  return {
    match,
  };
};
export default usePathMatch;
