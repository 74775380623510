import { FetchError } from "@saas/core";
import { chatQueryKey } from "@saas/crm/data";
import {
  ChatRoomMetadataInterface,
  ChatRoomModel,
  LoadChatRoomsPayloadInterface,
  ReadStatusEnum,
} from "@saas/crm/utils";
import {
  cleanPayload,
  type PaginationInterface,
  type QueryOptionsInterface,
} from "@saas/shared/utils";

import { useChatSocketConnection } from "../../chat/web-socket-chat-connection-provider/web-socket-chat-connection-provider";

import { useQuery } from "@tanstack/react-query";

export type UseGetChatRoomsParams = Partial<LoadChatRoomsPayloadInterface>;

export type UseGetChatRoomsData = {
  metadata: ChatRoomMetadataInterface;
  data: Array<ChatRoomModel>;
  pagination: PaginationInterface;
  statusCode: number;
};

const generateQueryKey = (params: UseGetChatRoomsParams) => {
  if (params.readStatus === ReadStatusEnum.ALL || params.search)
    return chatQueryKey.allChatRoomsByShop(
      params.channel ?? [],
      params.shopId ?? ""
    );

  if (params.readStatus === ReadStatusEnum.UNREAD)
    return chatQueryKey.allUnreadChatRoomsBychannel(
      params.channel ?? [],
      params.shopId ?? ""
    );

  return chatQueryKey.all();
};

export const useGetChatRooms = <DerivedQueryData = UseGetChatRoomsData>(
  params: UseGetChatRoomsParams,
  options?: QueryOptionsInterface<UseGetChatRoomsData, DerivedQueryData>
) => {
  const { canSendMessage, sendMessage } = useChatSocketConnection();

  return useQuery<UseGetChatRoomsData, FetchError, DerivedQueryData>(
    generateQueryKey(params),
    async () => {
      const {
        page = 1,
        perPage = 10,
        channel,
        shopId,
        readStatus,
        search,
      } = params;

      const payload = cleanPayload({
        type: "load_chat_rooms",
        page: page,
        per_page: perPage,
        channel: channel,
        shop_id: shopId,
        read_status: readStatus,
        search: search,
      });

      let resolve!: (
        value: UseGetChatRoomsData | PromiseLike<UseGetChatRoomsData>
      ) => void;
      let reject!: (value: unknown) => void;

      const promise = new Promise<UseGetChatRoomsData>((res, rej) => {
        resolve = res;
        reject = rej;
      });

      const requestPayload = {
        ...params,
        onResolve: resolve,
        onReject: reject,
      };

      sendMessage(payload, requestPayload);

      return promise;
    },
    {
      enabled: canSendMessage,
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useGetUnreadChatRooms = () =>
  useGetChatRooms(
    {
      readStatus: ReadStatusEnum.UNREAD,
    },
    {
      select: (data) => {
        if (data) {
          const totalUnread = (data.metadata.unreadChatRooms ?? []).reduce(
            (acc, item) => {
              return acc + item.count;
            },
            0
          );

          return {
            totalUnread,
            data: data.data,
          };
        }
      },
    }
  );

export default useGetChatRooms;
