import { ampli, eventGA } from "@saas/core";
import { ChannelEnum } from "@saas/shared/utils";

import MarketplaceTypeEnum from "../^enum/marketplace-type/marketplace-type";

import { capitalize } from "lodash-es";
import ReactGA from "react-ga4";

const userAccountCategory = "User Account";

export const trackAddMarketplace = ({
  channel,
  buttonName,
  label,
}: {
  channel: ChannelEnum;
  buttonName: string;
  label: string;
}) => {
  eventGA({
    name: "Add Marketplace",
    params: {
      category: userAccountCategory,
      marketplace: capitalize(channel),
      action: "clicked_continue_button",
    },
  });

  ampli.addMarketplace({
    marketplace: capitalize(channel),
    action: "clicked_add_button",
    type: "shop",
    button_name: buttonName,
    label,
  });
};

export const trackAddMarketplaceV2 = ({
  channel,
  type,
  buttonName,
  label,
}: {
  channel: ChannelEnum;
  type: string;
  buttonName: string;
  label: string;
}) => {
  ampli.addMarketplace({
    marketplace: capitalize(channel),
    action: "new_connection",
    type,
    button_name: buttonName,
    label,
  });
};

export const trackDeleteMarketplace = (
  channel: ChannelEnum,
  statusCode: number
) => {
  eventGA({
    name: "Delete Marketplace",
    params: {
      category: userAccountCategory,
      marketplace: capitalize(channel),
      action: "clicked_cancel_button",
    },
  });

  ampli.deleteMarketplace({
    action: "clicked_delete_button",
    marketplace: capitalize(channel),
    status: getRemoveConnectionStatus(channel, statusCode),
    type: "shop",
  });
};

export const trackDeleteMarketplaceV2 = (
  channel: ChannelEnum,
  statusCode: number,
  type: MarketplaceTypeEnum = MarketplaceTypeEnum.SHOP
) => {
  ampli.deleteMarketplace({
    marketplace: capitalize(channel),
    status: getRemoveConnectionStatus(channel, statusCode),
    action: "delete_marketplace",
    type,
  });
};

export const trackReconnectMarketplace = (channel: ChannelEnum) => {
  ampli.reconnectMarketplace({
    action: "clicked_reconnect_button",
    marketplace: capitalize(channel),
  });
};

export const trackMarketplaceConnected = (
  channel: ChannelEnum,
  statusCode: number
) => {
  eventGA({
    name: "Marketplace Connected",
    params: {
      category: userAccountCategory,
      marketplace: capitalize(channel),
      action: "clicked_continue_button",
    },
  });

  ampli.connectMarketplace({
    marketplace: capitalize(channel),
    action: "connected_marketplace",
    connection_status: getAddConnectionStatus(channel, statusCode),
    type: "shop",
  });
};

export const trackConnectMarketplaceV2 = ({
  channel,
  statusCode,
  isNewConnection,
  crmConnected,
  marketplaceConnected,
  type = MarketplaceTypeEnum.SHOP,
}: {
  channel: ChannelEnum;
  statusCode: number;
  isNewConnection: boolean;
  crmConnected?: Array<string>;
  marketplaceConnected?: Array<string>;
  type?: MarketplaceTypeEnum;
}) => {
  ampli.connectMarketplace({
    marketplace: capitalize(channel),
    connection_status: getAddConnectionStatus(channel, statusCode),
    action: isNewConnection ? "new_connected" : "reconnect",
    type,
  });

  if (statusCode === 200)
    ampli.identify(undefined, {
      app_name: "Melaka Web App",
      crm_connected: crmConnected,
      marketplace_connected: marketplaceConnected,
    });
};

export const trackConnection = (
  channel: ChannelEnum,
  connected: boolean,
  expired?: boolean
) => {
  ReactGA.gtag("set", "user_properties", {
    [`${channel}_connection`]: connected,
  });

  const value = expired ? "expired" : String(connected);

  ampli.identify(undefined, {
    [`${channel}_connection`]: value,
  });
};

export const trackAddNewMarketplaceV2 = (section: string) => {
  ampli.addNewMarketplace({
    button_name: "Tambah Toko Online",
    section,
  });
};

export const trackSubmitMarketplaceV2 = ({
  actionDetails,
  buttonName,
  label,
  channel,
  type = MarketplaceTypeEnum.SHOP,
}: {
  actionDetails: "cancel" | "save" | "close" | "delete";
  buttonName: string;
  label: string;
  channel?: ChannelEnum;
  type?: MarketplaceTypeEnum;
}) => {
  ampli.submitMarketplace({
    button_name: buttonName,
    section: "Input Form - Marketplace Connection",
    action: submitMarketplaceActionDetails(actionDetails),
    marketplace: capitalize(channel),
    label,
    type,
  });
};

export const trackContactCSV2 = (section: string) => {
  const { location, document } = window;

  ampli.contactCs({
    purpose: "contact_support",
    button_name: "Hubungi Admin Melaka",
    section:
      section === "Header"
        ? "Header - Marketplace Connection"
        : "Footer - Connection Form",
    page_domain: location.origin,
    page_location: location.href,
    page_path: location.pathname,
    page_title: document.title,
    page_url: location.href,
  });
};

const getAddConnectionStatus = (channel: ChannelEnum, statusCode: number) => {
  if (statusCode !== 200) return "failed";

  return channel === ChannelEnum.TOKOPEDIA ? "pending" : "success";
};

const getRemoveConnectionStatus = (
  channel: ChannelEnum,
  statusCode: number
) => {
  if (statusCode !== 200) return "failed";

  return channel === ChannelEnum.TOKOPEDIA ? "waiting to remove" : "success";
};

const submitMarketplaceActionDetails = (action_details: string) => {
  switch (action_details) {
    case "cancel":
      return "clicked_cancel_button";
    case "save":
      return "clicked_save_button";
    case "close":
      return "clicked_close_button";
    case "delete":
      return "clicked_delete_button";
    default:
      return "";
  }
};
