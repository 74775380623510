import { useEffect } from "react";

import { initOneSignal, registerUserDevice } from "@saas/core";

type UseOneSignalParams = {
  defaultFlags?: Record<string, boolean>;
  userId?: string;
  onOneSignalUserIdReceived: (oneSignalUserId: string) => void;
};

export const useOneSignal = ({
  defaultFlags,
  userId,
  onOneSignalUserIdReceived,
}: UseOneSignalParams) => {
  const isEnabled = defaultFlags;

  useEffect(() => {
    if (isEnabled && userId) {
      initOneSignal();
      registerUserDevice(userId, onOneSignalUserIdReceived);
    }
  }, [isEnabled, onOneSignalUserIdReceived, userId]);

  return null;
};

export default useOneSignal;
