import { env } from "@saas/config/shared";

import { dayjs, FetchError, getContentDispositionFilename } from "../..";

import * as uuid from "uuid";

const isTest = process.env["NODE_ENV"] === "test";
const isStorybook = process.env["STORYBOOK"] === "true";

const BASE_URL = typeof window === "undefined" ? env.APP_URL : "";

export interface FetchFileOutput {
  filename: string;
  file: Blob;
}

export const fetchFile = async (
  input: RequestInfo,
  init?: RequestInit
): Promise<FetchFileOutput> => {
  const url =
    isTest || isStorybook ? "https://localhost.test" + input : BASE_URL + input;

  const response = await fetch(url, {
    ...init,
    headers: {
      ...init?.headers,
      "X-Request-ID": uuid.v4(),
      "X-Timezone-Offset": dayjs().format("Z"),
    },
  });

  if (!response.ok || response.status === 204) {
    throw new FetchError({
      message: response.statusText,
      response,
    });
  }

  const filename =
    getContentDispositionFilename(response.headers) ??
    (response.headers.get("filename") as string);

  const file = await response.blob();

  return { file, filename };
};

export default fetchFile;
