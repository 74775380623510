import { createContext, ReactNode, useContext, useReducer } from "react";

import {
  AlertAction,
  AlertActionType,
  AlertDispatch,
  AlertInterface,
} from "@saas/shared/utils";

type AlertProviderProps = { children: ReactNode };

const AlertContext = createContext<
  [ReadonlyArray<AlertInterface>, AlertDispatch] | undefined
>(undefined);

const alertReducer = (
  state: ReadonlyArray<AlertInterface>,
  action: AlertAction
) => {
  switch (action.type) {
    case AlertActionType.ADD_MESSAGE: {
      return [
        ...state,
        {
          ...action.payload,
          id: Date.now().toString(),
        },
      ];
    }
    case AlertActionType.REMOVE_MESSAGE: {
      return state.filter((snackbar) => snackbar.id !== action.payload.id);
    }
    default: {
      throw new Error(`Unhandled action type: ${action["type"]}`);
    }
  }
};

const AlertProvider = ({ children }: AlertProviderProps) => {
  const [state, dispatch] = useReducer(alertReducer, []);

  return (
    <AlertContext.Provider value={[state, dispatch]}>
      {children}
    </AlertContext.Provider>
  );
};

const useAlert = () => {
  const context = useContext(AlertContext);

  if (context === undefined) {
    throw new Error("useAlert must be used within a AlertProvider");
  }

  return context;
};

export { AlertProvider, useAlert };

export default AlertProvider;
