import { QueryClient, QueryClientConfig } from "@tanstack/react-query";

export type CreateQueryClientOutput = QueryClient;

export const createQueryClient = (
  config?: QueryClientConfig
): CreateQueryClientOutput => {
  return new QueryClient(config);
};

export default createQueryClient;
