import { kratos } from "../..";

import { VerificationFlowState } from "@ory/client";

export type VerifyAccountInput = {
  flowId: string;
  email: string;
  csrfToken: string;
};

export type VerifyAccountOutput = {
  state: VerificationFlowState;
};

export const sendVerificationEmail = async ({
  flowId,
  email,
  csrfToken,
}: VerifyAccountInput): Promise<VerifyAccountOutput> => {
  const { data } = await kratos.updateVerificationFlow({
    flow: flowId,
    updateVerificationFlowBody: {
      email,
      csrf_token: csrfToken,
      method: "link",
    },
  });

  return {
    state: data.state,
  };
};

export default sendVerificationEmail;
