import { useRouter } from "next/router";
import { memo, ReactElement, useCallback, useEffect, useMemo } from "react";

import { removeUseSessionQuery } from "@saas/account/data-access";
import { marketRoutes, publicRoutes } from "@saas/config/dashboard";

import { useSessionProfile } from "../..";

import qs from "qs";
import { useIsClient } from "usehooks-ts";

export interface RouteGuardProps {
  children: ReactElement;
}

export const RouteGuard = memo(({ children }: RouteGuardProps) => {
  const { profile, error } = useSessionProfile();
  const { pathname, push, query } = useRouter();
  const isClient = useIsClient();

  const allowAccess = useMemo(() => {
    const [basedPath] = pathname.split("?");

    const isPublicRoute = [...publicRoutes, ...marketRoutes].includes(
      basedPath
    );

    if (isPublicRoute) return true;

    if (!profile || !profile.isActive || error) {
      return false;
    }

    return true;
  }, [error, pathname, profile]);

  const redirect = useCallback(async () => {
    const redirectedQueryString = qs.stringify(query, {
      addQueryPrefix: true,
    });

    const queryString = qs.stringify(
      {
        redirect:
          pathname !== "/" ? `${pathname}${redirectedQueryString}` : undefined,
      },
      { addQueryPrefix: true }
    );

    if (isClient) {
      removeUseSessionQuery();
      await push(`/login${queryString}`);
    }
  }, [isClient, pathname, push, query]);

  useEffect(() => {
    if (!allowAccess) {
      redirect();
    }
  }, [allowAccess, redirect]);

  return allowAccess ? children : null;
});

RouteGuard.displayName = "RouteGuard";

export default RouteGuard;
