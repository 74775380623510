import { useMemo } from "react";

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AccessesKeyEnum } from "@saas/account/utils";
import { env } from "@saas/config/shared";
import { FlagKeys } from "@saas/flags/utils";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { NavigationInterface } from "@saas/layout/utils";
import {
  AnalyticsIcon,
  ChatIcon,
  HelpIcon,
  HomeIcon,
  InventoryIcon,
  MarketplaceIcon,
  MyBalanceIcon,
  OrdersIcon,
  PromotionIcon,
  SettingsIcon,
} from "@saas/shared/icon";
import { NewMenuEnum } from "@saas/shared/utils";

export type UseNavigation = {
  navigation: ReadonlyArray<NavigationInterface>;
};

type UseNavigationParams = {
  defaultFlags: Record<FlagKeys, boolean>;
};

export const useNavigation = ({
  defaultFlags,
}: UseNavigationParams): UseNavigation => {
  const navigation = useMemo<ReadonlyArray<NavigationInterface>>(() => {
    const {
      "broadcast": broadcastEnabled,
      "crm/shopee-chat": isChatEnabled,
      "inventory": inventoryFlag,
      "inventory/product-bundling": productBundlingFlag,
      "inventory/collections": productCollectionsFlag,
      "order/redirect-cashier-to-pos": redirectCashierToPosFlag,
      "order/marketplace-r1": orderMarketplaceFlag,
      "payment": paymentFlag,
      "promotion/loyalty-points": loyaltyPointsFlag,
      "promotion/vouchers": voucherFlag,
      "settings": settingsFlag,
      "settings/service-fee": serviceFeeFlag,
    } = defaultFlags;

    const isPromotionMenuEnabled = loyaltyPointsFlag;

    return [
      {
        label: "Beranda",
        href: "/",
        icon: HomeIcon,
        testid: "home__menu__home",
        enabled: true,
        accesses: [AccessesKeyEnum.MAIN_DASHBOARD_ALL],
      },
      {
        label: "Saldo Saya",
        href: "/payment",
        icon: MyBalanceIcon,
        testid: "home__menu__payment",
        enabled: paymentFlag,
        accesses: [AccessesKeyEnum.PAYMENT_ALL],
      },
      {
        label: "Toko Saya",
        href: "/store",
        icon: MarketplaceIcon,
        testid: "home__menu__store",
        enabled: true,
        accesses: [
          AccessesKeyEnum.POS_ALL,
          AccessesKeyEnum.CATALOG_ALL,
          AccessesKeyEnum.SALESPERSON_ALL,
          AccessesKeyEnum.TERM_OF_PAYMENT_ALL,
        ],
        children: [
          {
            label: "Tim Sales",
            href: "/salesperson",
            testid: "home__menu__store-salesperson",
            enabled: true,
            accesses: [AccessesKeyEnum.SALESPERSON_ALL],
          },
          {
            label: "Kasir",
            href: redirectCashierToPosFlag ? env.POS_URL : "/cashier",
            target: redirectCashierToPosFlag ? "_blank" : undefined,
            testid: "home__menu__order-cashier",
            enabled: true,
            accesses: [AccessesKeyEnum.POS_ALL],
          },
          {
            label: "Katalog Melaka",
            href: "/catalog",
            testid: "home__menu__store-catalog",
            enabled: true,
            accesses: [AccessesKeyEnum.CATALOG_ALL],
          },
          {
            label: "Piutang",
            href: "/term-of-payment",
            testid: "home__menu__store-term-of-payment",
            enabled: true,
            accesses: [AccessesKeyEnum.TERM_OF_PAYMENT_ALL],
          },
        ],
      },
      {
        label: "Order Saya",
        href: "/order",
        icon: OrdersIcon,
        testid: "home__menu__order",
        enabled: orderMarketplaceFlag,
        accesses: [AccessesKeyEnum.ORDER_ACTION, AccessesKeyEnum.ORDER_VIEW],
      },
      {
        label: "Produk & Stok",
        href: "/inventory/products",
        icon: InventoryIcon,
        testid: "home__menu__inventory",
        enabled: inventoryFlag,
        accesses: [
          AccessesKeyEnum.PRODUCT_ACTION,
          AccessesKeyEnum.PRODUCT_VIEW,
          AccessesKeyEnum.STOCK_ACTION,
          AccessesKeyEnum.STOCK_VIEW,
          AccessesKeyEnum.VIRTUAL_BUNDLING_ACTION,
          AccessesKeyEnum.VIRTUAL_BUNDLING_VIEW,
          AccessesKeyEnum.PRODUCT_COLLECTION_ACTION,
          AccessesKeyEnum.PRODUCT_COLLECTION_VIEW,
        ],
        children: [
          {
            label: "Produk",
            href: "/inventory/products",
            enabled: true,
            testid: "home__submenu__inventory_product_list",
            accesses: [
              AccessesKeyEnum.PRODUCT_ACTION,
              AccessesKeyEnum.PRODUCT_VIEW,
            ],
          },
          {
            label: "Stok Inventori",
            href: "/inventory/stocks",
            enabled: true,
            testid: "home__submenu__inventory_stocks",
            accesses: [
              AccessesKeyEnum.STOCK_ACTION,
              AccessesKeyEnum.STOCK_VIEW,
            ],
          },
          {
            label: "Virtual Bundling",
            href: "/inventory/bundling",
            enabled: productBundlingFlag,
            testid: "home__submenu__inventory_virtual_bundling",
            accesses: [
              AccessesKeyEnum.VIRTUAL_BUNDLING_ACTION,
              AccessesKeyEnum.VIRTUAL_BUNDLING_VIEW,
            ],
          },
          {
            label: "Koleksi Produk",
            href: "/inventory/collections",
            enabled: productCollectionsFlag,
            isNew: true,
            keyIndicator: NewMenuEnum.PRODUCT_COLLECTIONS,
            accesses: [
              AccessesKeyEnum.PRODUCT_COLLECTION_ACTION,
              AccessesKeyEnum.PRODUCT_COLLECTION_VIEW,
            ],
            testid: "home__submenu__inventory_product_collections",
          },
        ],
      },
      {
        label: "Analisis Bisnis",
        href: "/customer-analysis",
        icon: AnalyticsIcon,
        testid: "home__menu__analysis",
        enabled: true,
        accesses: [
          AccessesKeyEnum.CUSTOMER_ANALYSIS_ALL,
          AccessesKeyEnum.ACCOUNTING_JOURNAL_ALL,
          AccessesKeyEnum.REPORT_LIST_ALL,
          AccessesKeyEnum.PRICE_MONITORING_ALL,
          AccessesKeyEnum.SHOP_MONITORING_ALL,
        ],
        children: [
          {
            label: "Catatan Keuangan",
            href: "/accounting",
            testid: "home__submenu__journal",
            enabled: true,
            isNew: false,
            keyIndicator: NewMenuEnum.ACCOUNTING_JOURNAL,
            accesses: [AccessesKeyEnum.ACCOUNTING_JOURNAL_ALL],
          },
          {
            label: "Laporan Toko",
            href: "/reports/list",
            enabled: true,
            testid: "home__submenu__reports_list",
            accesses: [AccessesKeyEnum.REPORT_LIST_ALL],
          },
          {
            label: "Analisis Pelanggan",
            href: "/customer-analysis",
            testid: "home__submenu__analysis",
            enabled: true,
            accesses: [AccessesKeyEnum.CUSTOMER_ANALYSIS_ALL],
          },
        ],
      },
      {
        label: "Promosi Saya",
        href: "/promotion",
        icon: PromotionIcon,
        testid: "home__menu__promotion",
        enabled: isPromotionMenuEnabled,
        accesses: [
          AccessesKeyEnum.PROMOTION_ALL,
          AccessesKeyEnum.BROADCAST_CAMPAIGN_ALL,
          AccessesKeyEnum.LOYALTY_POINTS_VIEW,
          AccessesKeyEnum.LOYALTY_POINTS_ACTION,
          AccessesKeyEnum.VOUCHER_VIEW,
          AccessesKeyEnum.VOUCHER_ACTION,
        ],
        children: isPromotionMenuEnabled
          ? [
              {
                label: "Poin Loyalti",
                href: "/loyalty-point",
                enabled: loyaltyPointsFlag,
                testid: "home__menu__promotion-loyalty-point",
                accesses: [
                  AccessesKeyEnum.LOYALTY_POINTS_ACTION,
                  AccessesKeyEnum.LOYALTY_POINTS_VIEW,
                ],
              },
              {
                label: "Level Member",
                href: "/customer-tiers",
                enabled: false,
                testid: "home__menu__promotion-customer-tiers",
              },
              {
                label: "Promosi Melaka",
                href: "/promotion/store",
                enabled: true,
                testid: "home__menu__promotion-melaka",
                accesses: [AccessesKeyEnum.PROMOTION_ALL],
              },
              {
                label: "Promosi Katalog Melaka",
                href: "/promotion/catalog",
                enabled: false,
                testid: "home__submenu__promotion-catalog",
              },
              {
                label: "Broadcast via Whatsapp",
                href: "/promotion/broadcast-campaign",
                enabled: broadcastEnabled,
                testid: "home__submenu__promotion-whatsapp",
                isNew: false,
                keyIndicator: NewMenuEnum.PROMOTION_BROADCAST_CAMPAIGN,
                accesses: [AccessesKeyEnum.BROADCAST_CAMPAIGN_ALL],
              },
              {
                label: "Promosi Toko Online",
                href: "/promotion/promotion-list",
                enabled: false,
                testid: "home__submenu__promotion-marketplace",
                accesses: [AccessesKeyEnum.PROMOTION_ALL],
              },
              {
                label: "Voucher",
                href: "/voucher",
                enabled: voucherFlag,
                testid: "home__submenu__promotion-voucher",
                accesses: [
                  AccessesKeyEnum.VOUCHER_VIEW,
                  AccessesKeyEnum.VOUCHER_ACTION,
                ],
              },
            ]
          : undefined,
      },
      {
        label: "Demografis Pembeli",
        href: "/demographics",
        icon: AnalyticsIcon,
        testid: "home__menu__demographics",
        enabled: false,
        accesses: [AccessesKeyEnum.CUSTOMER_ANALYSIS_ALL],
      },
      {
        label: "Koneksi Toko Online",
        href: "/marketplace-connections",
        icon: MarketplaceIcon,
        testid: "home__menu__marketplace-connection",
        enabled: true,
        accesses: [
          AccessesKeyEnum.MARKETPLACE_CONNECTION_ACTION,
          AccessesKeyEnum.MARKETPLACE_CONNECTION_VIEW,
        ],
      },
      {
        label: "Pengaturan",
        href: "#",
        testid: "home__menu__settings",
        icon: SettingsIcon,
        enabled: settingsFlag && serviceFeeFlag,
        children: [
          {
            label: "Biaya Layanan",
            enabled: serviceFeeFlag,
            href: "/service-fee",
            testid: "home__submenu__settings-service-fee",
            accesses: [AccessesKeyEnum.SETTINGS_SERVICE_FEE_ALL],
          },
        ],
        accesses: [AccessesKeyEnum.SETTINGS_SERVICE_FEE_ALL],
      },
      {
        label: "Kelola Chat",
        href: "/crm/chat",
        target: "_blank",
        icon: ChatIcon,
        testid: "home__menu__chat",
        keyIndicator: NewMenuEnum.CRM,
        enabled: isChatEnabled,
        gap: isChatEnabled,
        accesses: [AccessesKeyEnum.CHAT_ALL],
      },
      {
        label: "Pusat Bantuan",
        href: env.HELP_URL,
        icon: HelpIcon,
        testid: "home__menu__help-center",
        enabled: true,
        sticky: true,
        className: "mb-0",
      },
    ];
  }, [defaultFlags]);

  return { navigation: navigation };
};

export default useNavigation;
