export interface Timezones {
  abbreviation: string;
  name: string;
  displayName: string;
  offset: string;
}

export const timezones: ReadonlyArray<Timezones> = [
  {
    abbreviation: "A",
    name: "Alpha Time Zone",
    displayName: "Alpha Time Zone(UTC + 1)",
    offset: "1 hours",
  },
  {
    abbreviation: "ACDT",
    name: "Australian Central Daylight Time",
    displayName: "Australian Central Daylight Time(UTC + 10:30)",
    offset: "10:30 hours",
  },
  {
    abbreviation: "ACST",
    name: "Australian Central Standard Time",
    displayName: "Australian Central Standard Time(UTC + 9:30)",
    offset: "9:30 hours",
  },
  {
    abbreviation: "ADT",
    name: "Atlantic Daylight Time",
    displayName: "Atlantic Daylight Time(UTC - 3)",
    offset: "-3 hours",
  },
  {
    abbreviation: "ADT",
    name: "Atlantic Daylight Time",
    displayName: "Atlantic Daylight Time(UTC - 3)",
    offset: "-3 hours",
  },
  {
    abbreviation: "AEDT",
    name: "Australian Eastern Daylight Time",
    displayName: "Australian Eastern Daylight Time(UTC + 11)",
    offset: "11 hours",
  },
  {
    abbreviation: "AEST",
    name: "Australian Eastern Standard Time",
    displayName: "Australian Eastern Standard Time(UTC + 10)",
    offset: "10 hours",
  },
  {
    abbreviation: "AFT",
    name: "Afghanistan Time",
    displayName: "Afghanistan Time(UTC + 4:30)",
    offset: "4:30 hours",
  },
  {
    abbreviation: "AKDT",
    name: "Alaska Daylight Time",
    displayName: "Alaska Daylight Time(UTC - 8)",
    offset: "-8 hours",
  },
  {
    abbreviation: "AKST",
    name: "Alaska Standard Time",
    displayName: "Alaska Standard Time(UTC - 9)",
    offset: "-9 hours",
  },
  {
    abbreviation: "ALMT",
    name: "Alma-Ata Time",
    displayName: "Alma-Ata Time(UTC + 6)",
    offset: "6 hours",
  },
  {
    abbreviation: "AMST",
    name: "Armenia Summer Time",
    displayName: "Armenia Summer Time(UTC + 5)",
    offset: "5 hours",
  },
  {
    abbreviation: "AMST",
    name: "Amazon Summer Time",
    displayName: "Amazon Summer Time(UTC - 3)",
    offset: "-3 hours",
  },
  {
    abbreviation: "AMT",
    name: "Armenia Time",
    displayName: "Armenia Time(UTC + 4)",
    offset: "4 hours",
  },
  {
    abbreviation: "AMT",
    name: "Amazon Time",
    displayName: "Amazon Time(UTC - 4)",
    offset: "-4 hours",
  },
  {
    abbreviation: "ANAST",
    name: "Anadyr Summer Time",
    displayName: "Anadyr Summer Time(UTC + 12)",
    offset: "12 hours",
  },
  {
    abbreviation: "ANAT",
    name: "Anadyr Time",
    displayName: "Anadyr Time(UTC + 12)",
    offset: "12 hours",
  },
  {
    abbreviation: "AQTT",
    name: "Aqtobe Time",
    displayName: "Aqtobe Time(UTC + 5)",
    offset: "5 hours",
  },
  {
    abbreviation: "ART",
    name: "Argentina Time",
    displayName: "Argentina Time(UTC - 3)",
    offset: "-3 hours",
  },
  {
    abbreviation: "AST",
    name: "Arabia Standard Time",
    displayName: "Arabia Standard Time(UTC + 3)",
    offset: "3 hours",
  },
  {
    abbreviation: "AST",
    name: "Atlantic Standard Time",
    displayName: "Atlantic Standard Time(UTC - 4)",
    offset: "-4 hours",
  },
  {
    abbreviation: "AWDT",
    name: "Australian Western Daylight Time",
    displayName: "Australian Western Daylight Time(UTC + 9)",
    offset: "9 hours",
  },
  {
    abbreviation: "AWST",
    name: "Australian Western Standard Time",
    displayName: "Australian Western Standard Time(UTC + 8)",
    offset: "8 hours",
  },
  {
    abbreviation: "AZOST",
    name: "Azores Summer Time",
    displayName: "Azores Summer Time(UTC)",
    offset: "0 hours",
  },
  {
    abbreviation: "AZOT",
    name: "Azores Time",
    displayName: "Azores Time(UTC - 1)",
    offset: "-1 hours",
  },
  {
    abbreviation: "AZST",
    name: "Azerbaijan Summer Time",
    displayName: "Azerbaijan Summer Time(UTC + 5)",
    offset: "5 hours",
  },
  {
    abbreviation: "AZT",
    name: "Azerbaijan Time",
    displayName: "Azerbaijan Time(UTC + 4)",
    offset: "4 hours",
  },
  {
    abbreviation: "B",
    name: "Bravo Time Zone",
    displayName: "Bravo Time Zone(UTC + 2)",
    offset: "2 hours",
  },
  {
    abbreviation: "BNT",
    name: "Brunei Darussalam Time",
    displayName: "Brunei Darussalam Time(UTC + 8)",
    offset: "8 hours",
  },
  {
    abbreviation: "BOT",
    name: "Bolivia Time",
    displayName: "Bolivia Time(UTC - 4)",
    offset: "-4 hours",
  },
  {
    abbreviation: "BRST",
    name: "Brasilia Summer Time",
    displayName: "Brasilia Summer Time(UTC - 2)",
    offset: "-2 hours",
  },
  {
    abbreviation: "BRT",
    name: "Brasília time",
    displayName: "Brasília time(UTC - 3)",
    offset: "-3 hours",
  },
  {
    abbreviation: "BST",
    name: "Bangladesh Standard Time",
    displayName: "Bangladesh Standard Time(UTC + 6)",
    offset: "6 hours",
  },
  {
    abbreviation: "BST",
    name: "British Summer Time",
    displayName: "British Summer Time(UTC + 1)",
    offset: "1 hours",
  },
  {
    abbreviation: "BTT",
    name: "Bhutan Time",
    displayName: "Bhutan Time(UTC + 6)",
    offset: "6 hours",
  },
  {
    abbreviation: "C",
    name: "Charlie Time Zone",
    displayName: "Charlie Time Zone(UTC + 3)",
    offset: "3 hours",
  },
  {
    abbreviation: "CAST",
    name: "Casey Time",
    displayName: "Casey Time(UTC + 8)",
    offset: "8 hours",
  },
  {
    abbreviation: "CAT",
    name: "Central Africa Time",
    displayName: "Central Africa Time(UTC + 2)",
    offset: "2 hours",
  },
  {
    abbreviation: "CCT",
    name: "Cocos Islands Time",
    displayName: "Cocos Islands Time(UTC + 6:30)",
    offset: "6:30 hours",
  },
  {
    abbreviation: "CDT",
    name: "Cuba Daylight Time",
    displayName: "Cuba Daylight Time(UTC - 4)",
    offset: "-4 hours",
  },
  {
    abbreviation: "CDT",
    name: "Central Daylight Time",
    displayName: "Central Daylight Time(UTC - 5)",
    offset: "-5 hours",
  },
  {
    abbreviation: "CEST",
    name: "Central European Summer Time",
    displayName: "Central European Summer Time(UTC + 2)",
    offset: "2 hours",
  },
  {
    abbreviation: "CET",
    name: "Central European Time",
    displayName: "Central European Time(UTC + 1)",
    offset: "1 hours",
  },
  {
    abbreviation: "CHADT",
    name: "Chatham Island Daylight Time",
    displayName: "Chatham Island Daylight Time(UTC + 13:45)",
    offset: "13:45 hours",
  },
  {
    abbreviation: "CHAST",
    name: "Chatham Island Standard Time",
    displayName: "Chatham Island Standard Time(UTC + 12:45)",
    offset: "12:45 hours",
  },
  {
    abbreviation: "CKT",
    name: "Cook Island Time",
    displayName: "Cook Island Time(UTC - 10)",
    offset: "-10 hours",
  },
  {
    abbreviation: "CLST",
    name: "Chile Summer Time",
    displayName: "Chile Summer Time(UTC - 3)",
    offset: "-3 hours",
  },
  {
    abbreviation: "CLT",
    name: "Chile Standard Time",
    displayName: "Chile Standard Time(UTC - 4)",
    offset: "-4 hours",
  },
  {
    abbreviation: "COT",
    name: "Colombia Time",
    displayName: "Colombia Time(UTC - 5)",
    offset: "-5 hours",
  },
  {
    abbreviation: "CST",
    name: "China Standard Time",
    displayName: "China Standard Time(UTC + 8)",
    offset: "8 hours",
  },
  {
    abbreviation: "CST",
    name: "Central Standard Time",
    displayName: "Central Standard Time(UTC - 6)",
    offset: "-6 hours",
  },
  {
    abbreviation: "CST",
    name: "Cuba Standard Time",
    displayName: "Cuba Standard Time(UTC - 5)",
    offset: "-5 hours",
  },
  {
    abbreviation: "CVT",
    name: "Cape Verde Time",
    displayName: "Cape Verde Time(UTC - 1)",
    offset: "-1 hours",
  },
  {
    abbreviation: "CXT",
    name: "Christmas Island Time",
    displayName: "Christmas Island Time(UTC + 7)",
    offset: "7 hours",
  },
  {
    abbreviation: "ChST",
    name: "Chamorro Standard Time",
    displayName: "Chamorro Standard Time(UTC + 10)",
    offset: "10 hours",
  },
  {
    abbreviation: "D",
    name: "Delta Time Zone",
    displayName: "Delta Time Zone(UTC + 4)",
    offset: "4 hours",
  },
  {
    abbreviation: "DAVT",
    name: "Davis Time",
    displayName: "Davis Time(UTC + 7)",
    offset: "7 hours",
  },
  {
    abbreviation: "E",
    name: "Echo Time Zone",
    displayName: "Echo Time Zone(UTC + 5)",
    offset: "5 hours",
  },
  {
    abbreviation: "EASST",
    name: "Easter Island Summer Time",
    displayName: "Easter Island Summer Time(UTC - 5)",
    offset: "-5 hours",
  },
  {
    abbreviation: "EAST",
    name: "Easter Island Standard Time",
    displayName: "Easter Island Standard Time(UTC - 6)",
    offset: "-6 hours",
  },
  {
    abbreviation: "EAT",
    name: "Eastern Africa Time",
    displayName: "Eastern Africa Time(UTC + 3)",
    offset: "3 hours",
  },
  {
    abbreviation: "EAT",
    name: "East Africa Time",
    displayName: "East Africa Time(UTC + 3)",
    offset: "3 hours",
  },
  {
    abbreviation: "ECT",
    name: "Ecuador Time",
    displayName: "Ecuador Time(UTC - 5)",
    offset: "-5 hours",
  },
  {
    abbreviation: "EDT",
    name: "Eastern Daylight Time",
    displayName: "Eastern Daylight Time(UTC - 4)",
    offset: "-4 hours",
  },
  {
    abbreviation: "EEST",
    name: "Eastern European Summer Time",
    displayName: "Eastern European Summer Time(UTC + 3)",
    offset: "3 hours",
  },
  {
    abbreviation: "EET",
    name: "Eastern European Time",
    displayName: "Eastern European Time(UTC + 2)",
    offset: "2 hours",
  },
  {
    abbreviation: "EGST",
    name: "Eastern Greenland Summer Time",
    displayName: "Eastern Greenland Summer Time(UTC)",
    offset: "0 hours",
  },
  {
    abbreviation: "EGT",
    name: "East Greenland Time",
    displayName: "East Greenland Time(UTC - 1)",
    offset: "-1 hours",
  },
  {
    abbreviation: "EST",
    name: "Eastern Standard Time",
    displayName: "Eastern Standard Time(UTC - 5)",
    offset: "-5 hours",
  },
  {
    abbreviation: "ET",
    name: "Tiempo del Este",
    displayName: "Tiempo del Este(UTC - 5)",
    offset: "-5 hours",
  },
  {
    abbreviation: "F",
    name: "Foxtrot Time Zone",
    displayName: "Foxtrot Time Zone(UTC + 6)",
    offset: "6 hours",
  },
  {
    abbreviation: "FJST",
    name: "Fiji Summer Time",
    displayName: "Fiji Summer Time(UTC + 13)",
    offset: "13 hours",
  },
  {
    abbreviation: "FJT",
    name: "Fiji Time",
    displayName: "Fiji Time(UTC + 12)",
    offset: "12 hours",
  },
  {
    abbreviation: "FKST",
    name: "Falkland Islands Summer Time",
    displayName: "Falkland Islands Summer Time(UTC - 3)",
    offset: "-3 hours",
  },
  {
    abbreviation: "FKT",
    name: "Falkland Island Time",
    displayName: "Falkland Island Time(UTC - 4)",
    offset: "-4 hours",
  },
  {
    abbreviation: "FNT",
    name: "Fernando de Noronha Time",
    displayName: "Fernando de Noronha Time(UTC - 2)",
    offset: "-2 hours",
  },
  {
    abbreviation: "G",
    name: "Golf Time Zone",
    displayName: "Golf Time Zone(UTC + 7)",
    offset: "7 hours",
  },
  {
    abbreviation: "GALT",
    name: "Galapagos Time",
    displayName: "Galapagos Time(UTC - 6)",
    offset: "-6 hours",
  },
  {
    abbreviation: "GAMT",
    name: "Gambier Time",
    displayName: "Gambier Time(UTC - 9)",
    offset: "-9 hours",
  },
  {
    abbreviation: "GET",
    name: "Georgia Standard Time",
    displayName: "Georgia Standard Time(UTC + 4)",
    offset: "4 hours",
  },
  {
    abbreviation: "GFT",
    name: "French Guiana Time",
    displayName: "French Guiana Time(UTC - 3)",
    offset: "-3 hours",
  },
  {
    abbreviation: "GILT",
    name: "Gilbert Island Time",
    displayName: "Gilbert Island Time(UTC + 12)",
    offset: "12 hours",
  },
  {
    abbreviation: "GMT",
    name: "Greenwich Mean Time",
    displayName: "Greenwich Mean Time(UTC)",
    offset: "0 hours",
  },
  {
    abbreviation: "GST",
    name: "Gulf Standard Time",
    displayName: "Gulf Standard Time(UTC + 4)",
    offset: "4 hours",
  },
  {
    abbreviation: "GYT",
    name: "Guyana Time",
    displayName: "Guyana Time(UTC - 4)",
    offset: "-4 hours",
  },
  {
    abbreviation: "H",
    name: "Hotel Time Zone",
    displayName: "Hotel Time Zone(UTC + 8)",
    offset: "8 hours",
  },
  {
    abbreviation: "HAA",
    name: "Heure Avancée de l'Atlantique",
    displayName: "Heure Avancée de l'Atlantique(UTC - 3)",
    offset: "-3 hours",
  },
  {
    abbreviation: "HAC",
    name: "Heure Avancée du Centre",
    displayName: "Heure Avancée du Centre(UTC - 5)",
    offset: "-5 hours",
  },
  {
    abbreviation: "HADT",
    name: "Hawaii-Aleutian Daylight Time",
    displayName: "Hawaii-Aleutian Daylight Time(UTC - 9)",
    offset: "-9 hours",
  },
  {
    abbreviation: "HAE",
    name: "Heure Avancée de l'Est",
    displayName: "Heure Avancée de l'Est(UTC - 4)",
    offset: "-4 hours",
  },
  {
    abbreviation: "HAP",
    name: "Heure Avancée du Pacifique",
    displayName: "Heure Avancée du Pacifique(UTC - 7)",
    offset: "-7 hours",
  },
  {
    abbreviation: "HAR",
    name: "Heure Avancée des Rocheuses",
    displayName: "Heure Avancée des Rocheuses(UTC - 6)",
    offset: "-6 hours",
  },
  {
    abbreviation: "HAST",
    name: "Hawaii-Aleutian Standard Time",
    displayName: "Hawaii-Aleutian Standard Time(UTC - 10)",
    offset: "-10 hours",
  },
  {
    abbreviation: "HAT",
    name: "Heure Avancée de Terre-Neuve",
    displayName: "Heure Avancée de Terre-Neuve(UTC - 2:30)",
    offset: "-2:30 hours",
  },
  {
    abbreviation: "HAY",
    name: "Heure Avancée du Yukon",
    displayName: "Heure Avancée du Yukon(UTC - 8)",
    offset: "-8 hours",
  },
  {
    abbreviation: "HKT",
    name: "Hong Kong Time",
    displayName: "Hong Kong Time(UTC + 8)",
    offset: "8 hours",
  },
  {
    abbreviation: "HLV",
    name: "Hora Legal de Venezuela",
    displayName: "Hora Legal de Venezuela(UTC - 4:30)",
    offset: "-4:30 hours",
  },
  {
    abbreviation: "HNA",
    name: "Heure Normale de l'Atlantique",
    displayName: "Heure Normale de l'Atlantique(UTC - 4)",
    offset: "-4 hours",
  },
  {
    abbreviation: "HNC",
    name: "Heure Normale du Centre",
    displayName: "Heure Normale du Centre(UTC - 6)",
    offset: "-6 hours",
  },
  {
    abbreviation: "HNE",
    name: "Heure Normale de l'Est",
    displayName: "Heure Normale de l'Est(UTC - 5)",
    offset: "-5 hours",
  },
  {
    abbreviation: "HNP",
    name: "Heure Normale du Pacifique",
    displayName: "Heure Normale du Pacifique(UTC - 8)",
    offset: "-8 hours",
  },
  {
    abbreviation: "HNR",
    name: "Heure Normale des Rocheuses",
    displayName: "Heure Normale des Rocheuses(UTC - 7)",
    offset: "-7 hours",
  },
  {
    abbreviation: "HNT",
    name: "Heure Normale de Terre-Neuve",
    displayName: "Heure Normale de Terre-Neuve(UTC - 3:30)",
    offset: "-3:30 hours",
  },
  {
    abbreviation: "HNY",
    name: "Heure Normale du Yukon",
    displayName: "Heure Normale du Yukon(UTC - 9)",
    offset: "-9 hours",
  },
  {
    abbreviation: "HOVT",
    name: "Hovd Time",
    displayName: "Hovd Time(UTC + 7)",
    offset: "7 hours",
  },
  {
    abbreviation: "I",
    name: "India Time Zone",
    displayName: "India Time Zone(UTC + 9)",
    offset: "9 hours",
  },
  {
    abbreviation: "ICT",
    name: "Indochina Time",
    displayName: "Indochina Time(UTC + 7)",
    offset: "7 hours",
  },
  {
    abbreviation: "IDT",
    name: "Israel Daylight Time",
    displayName: "Israel Daylight Time(UTC + 3)",
    offset: "3 hours",
  },
  {
    abbreviation: "IOT",
    name: "Indian Chagos Time",
    displayName: "Indian Chagos Time(UTC + 6)",
    offset: "6 hours",
  },
  {
    abbreviation: "IRDT",
    name: "Iran Daylight Time",
    displayName: "Iran Daylight Time(UTC + 4:30)",
    offset: "4:30 hours",
  },
  {
    abbreviation: "IRKST",
    name: "Irkutsk Summer Time",
    displayName: "Irkutsk Summer Time(UTC + 9)",
    offset: "9 hours",
  },
  {
    abbreviation: "IRKT",
    name: "Irkutsk Time",
    displayName: "Irkutsk Time(UTC + 9)",
    offset: "9 hours",
  },
  {
    abbreviation: "IRST",
    name: "Iran Standard Time",
    displayName: "Iran Standard Time(UTC + 3:30)",
    offset: "3:30 hours",
  },
  {
    abbreviation: "IST",
    name: "Israel Standard Time",
    displayName: "Israel Standard Time(UTC + 2)",
    offset: "2 hours",
  },
  {
    abbreviation: "IST",
    name: "India Standard Time",
    displayName: "India Standard Time(UTC + 5:30)",
    offset: "5:30 hours",
  },
  {
    abbreviation: "IST",
    name: "Irish Standard Time",
    displayName: "Irish Standard Time(UTC + 1)",
    offset: "1 hours",
  },
  {
    abbreviation: "JST",
    name: "Japan Standard Time",
    displayName: "Japan Standard Time(UTC + 9)",
    offset: "9 hours",
  },
  {
    abbreviation: "K",
    name: "Kilo Time Zone",
    displayName: "Kilo Time Zone(UTC + 10)",
    offset: "10 hours",
  },
  {
    abbreviation: "KGT",
    name: "Kyrgyzstan Time",
    displayName: "Kyrgyzstan Time(UTC + 6)",
    offset: "6 hours",
  },
  {
    abbreviation: "KRAST",
    name: "Krasnoyarsk Summer Time",
    displayName: "Krasnoyarsk Summer Time(UTC + 8)",
    offset: "8 hours",
  },
  {
    abbreviation: "KRAT",
    name: "Krasnoyarsk Time",
    displayName: "Krasnoyarsk Time(UTC + 8)",
    offset: "8 hours",
  },
  {
    abbreviation: "KST",
    name: "Korea Standard Time",
    displayName: "Korea Standard Time(UTC + 9)",
    offset: "9 hours",
  },
  {
    abbreviation: "KUYT",
    name: "Kuybyshev Time",
    displayName: "Kuybyshev Time(UTC + 4)",
    offset: "4 hours",
  },
  {
    abbreviation: "L",
    name: "Lima Time Zone",
    displayName: "Lima Time Zone(UTC + 11)",
    offset: "11 hours",
  },
  {
    abbreviation: "LHDT",
    name: "Lord Howe Daylight Time",
    displayName: "Lord Howe Daylight Time(UTC + 11)",
    offset: "11 hours",
  },
  {
    abbreviation: "LHST",
    name: "Lord Howe Standard Time",
    displayName: "Lord Howe Standard Time(UTC + 10:30)",
    offset: "10:30 hours",
  },
  {
    abbreviation: "LINT",
    name: "Line Islands Time",
    displayName: "Line Islands Time(UTC + 14)",
    offset: "14 hours",
  },
  {
    abbreviation: "M",
    name: "Mike Time Zone",
    displayName: "Mike Time Zone(UTC + 12)",
    offset: "12 hours",
  },
  {
    abbreviation: "MAGST",
    name: "Magadan Summer Time",
    displayName: "Magadan Summer Time(UTC + 12)",
    offset: "12 hours",
  },
  {
    abbreviation: "MAGT",
    name: "Magadan Time",
    displayName: "Magadan Time(UTC + 12)",
    offset: "12 hours",
  },
  {
    abbreviation: "MART",
    name: "Marquesas Time",
    displayName: "Marquesas Time(UTC - 9:30)",
    offset: "-9:30 hours",
  },
  {
    abbreviation: "MAWT",
    name: "Mawson Time",
    displayName: "Mawson Time(UTC + 5)",
    offset: "5 hours",
  },
  {
    abbreviation: "MDT",
    name: "Mountain Daylight Time",
    displayName: "Mountain Daylight Time(UTC - 6)",
    offset: "-6 hours",
  },
  {
    abbreviation: "MESZ",
    name: "Mitteleuropäische Sommerzeit",
    displayName: "Mitteleuropäische Sommerzeit(UTC + 2)",
    offset: "2 hours",
  },
  {
    abbreviation: "MEZ",
    name: "Mitteleuropäische Zeit",
    displayName: "Mitteleuropäische Zeit(UTC + 1)",
    offset: "1 hours",
  },
  {
    abbreviation: "MHT",
    name: "Marshall Islands Time",
    displayName: "Marshall Islands Time(UTC + 12)",
    offset: "12 hours",
  },
  {
    abbreviation: "MMT",
    name: "Myanmar Time",
    displayName: "Myanmar Time(UTC + 6:30)",
    offset: "6:30 hours",
  },
  {
    abbreviation: "MSD",
    name: "Moscow Daylight Time",
    displayName: "Moscow Daylight Time(UTC + 4)",
    offset: "4 hours",
  },
  {
    abbreviation: "MSK",
    name: "Moscow Standard Time",
    displayName: "Moscow Standard Time(UTC + 4)",
    offset: "4 hours",
  },
  {
    abbreviation: "MST",
    name: "Mountain Standard Time",
    displayName: "Mountain Standard Time(UTC - 7)",
    offset: "-7 hours",
  },
  {
    abbreviation: "MUT",
    name: "Mauritius Time",
    displayName: "Mauritius Time(UTC + 4)",
    offset: "4 hours",
  },
  {
    abbreviation: "MVT",
    name: "Maldives Time",
    displayName: "Maldives Time(UTC + 5)",
    offset: "5 hours",
  },
  {
    abbreviation: "MYT",
    name: "Malaysia Time",
    displayName: "Malaysia Time(UTC + 8)",
    offset: "8 hours",
  },
  {
    abbreviation: "N",
    name: "November Time Zone",
    displayName: "November Time Zone(UTC - 1)",
    offset: "-1 hours",
  },
  {
    abbreviation: "NCT",
    name: "New Caledonia Time",
    displayName: "New Caledonia Time(UTC + 11)",
    offset: "11 hours",
  },
  {
    abbreviation: "NDT",
    name: "Newfoundland Daylight Time",
    displayName: "Newfoundland Daylight Time(UTC - 2:30)",
    offset: "-2:30 hours",
  },
  {
    abbreviation: "NFT",
    name: "Norfolk Time",
    displayName: "Norfolk Time(UTC + 11:30)",
    offset: "11:30 hours",
  },
  {
    abbreviation: "NOVST",
    name: "Novosibirsk Summer Time",
    displayName: "Novosibirsk Summer Time(UTC + 7)",
    offset: "7 hours",
  },
  {
    abbreviation: "NOVT",
    name: "Novosibirsk Time",
    displayName: "Novosibirsk Time(UTC + 6)",
    offset: "6 hours",
  },
  {
    abbreviation: "NPT",
    name: "Nepal Time",
    displayName: "Nepal Time(UTC + 5:45)",
    offset: "5:45 hours",
  },
  {
    abbreviation: "NST",
    name: "Newfoundland Standard Time",
    displayName: "Newfoundland Standard Time(UTC - 3:30)",
    offset: "-3:30 hours",
  },
  {
    abbreviation: "NUT",
    name: "Niue Time",
    displayName: "Niue Time(UTC - 11)",
    offset: "-11 hours",
  },
  {
    abbreviation: "NZDT",
    name: "New Zealand Daylight Time",
    displayName: "New Zealand Daylight Time(UTC + 13)",
    offset: "13 hours",
  },
  {
    abbreviation: "NZST",
    name: "New Zealand Standard Time",
    displayName: "New Zealand Standard Time(UTC + 12)",
    offset: "12 hours",
  },
  {
    abbreviation: "O",
    name: "Oscar Time Zone",
    displayName: "Oscar Time Zone(UTC - 2)",
    offset: "-2 hours",
  },
  {
    abbreviation: "OMSST",
    name: "Omsk Summer Time",
    displayName: "Omsk Summer Time(UTC + 7)",
    offset: "7 hours",
  },
  {
    abbreviation: "OMST",
    name: "Omsk Standard Time",
    displayName: "Omsk Standard Time(UTC + 7)",
    offset: "7 hours",
  },
  {
    abbreviation: "P",
    name: "Papa Time Zone",
    displayName: "Papa Time Zone(UTC - 3)",
    offset: "-3 hours",
  },
  {
    abbreviation: "PDT",
    name: "Pacific Daylight Time",
    displayName: "Pacific Daylight Time(UTC - 7)",
    offset: "-7 hours",
  },
  {
    abbreviation: "PET",
    name: "Peru Time",
    displayName: "Peru Time(UTC - 5)",
    offset: "-5 hours",
  },
  {
    abbreviation: "PETST",
    name: "Kamchatka Summer Time",
    displayName: "Kamchatka Summer Time(UTC + 12)",
    offset: "12 hours",
  },
  {
    abbreviation: "PETT",
    name: "Kamchatka Time",
    displayName: "Kamchatka Time(UTC + 12)",
    offset: "12 hours",
  },
  {
    abbreviation: "PGT",
    name: "Papua New Guinea Time",
    displayName: "Papua New Guinea Time(UTC + 10)",
    offset: "10 hours",
  },
  {
    abbreviation: "PHOT",
    name: "Phoenix Island Time",
    displayName: "Phoenix Island Time(UTC + 13)",
    offset: "13 hours",
  },
  {
    abbreviation: "PHT",
    name: "Philippine Time",
    displayName: "Philippine Time(UTC + 8)",
    offset: "8 hours",
  },
  {
    abbreviation: "PKT",
    name: "Pakistan Standard Time",
    displayName: "Pakistan Standard Time(UTC + 5)",
    offset: "5 hours",
  },
  {
    abbreviation: "PMDT",
    name: "Pierre & Miquelon Daylight Time",
    displayName: "Pierre & Miquelon Daylight Time(UTC - 2)",
    offset: "-2 hours",
  },
  {
    abbreviation: "PMST",
    name: "Pierre & Miquelon Standard Time",
    displayName: "Pierre & Miquelon Standard Time(UTC - 3)",
    offset: "-3 hours",
  },
  {
    abbreviation: "PONT",
    name: "Pohnpei Standard Time",
    displayName: "Pohnpei Standard Time(UTC + 11)",
    offset: "11 hours",
  },
  {
    abbreviation: "PST",
    name: "Pacific Standard Time",
    displayName: "Pacific Standard Time(UTC - 8)",
    offset: "-8 hours",
  },
  {
    abbreviation: "PST",
    name: "Pitcairn Standard Time",
    displayName: "Pitcairn Standard Time(UTC - 8)",
    offset: "-8 hours",
  },
  {
    abbreviation: "PT",
    name: "Tiempo del Pacífico",
    displayName: "Tiempo del Pacífico(UTC - 8)",
    offset: "-8 hours",
  },
  {
    abbreviation: "PWT",
    name: "Palau Time",
    displayName: "Palau Time(UTC + 9)",
    offset: "9 hours",
  },
  {
    abbreviation: "PYST",
    name: "Paraguay Summer Time",
    displayName: "Paraguay Summer Time(UTC - 3)",
    offset: "-3 hours",
  },
  {
    abbreviation: "PYT",
    name: "Paraguay Time",
    displayName: "Paraguay Time(UTC - 4)",
    offset: "-4 hours",
  },
  {
    abbreviation: "Q",
    name: "Quebec Time Zone",
    displayName: "Quebec Time Zone(UTC - 4)",
    offset: "-4 hours",
  },
  {
    abbreviation: "R",
    name: "Romeo Time Zone",
    displayName: "Romeo Time Zone(UTC - 5)",
    offset: "-5 hours",
  },
  {
    abbreviation: "RET",
    name: "Reunion Time",
    displayName: "Reunion Time(UTC + 4)",
    offset: "4 hours",
  },
  {
    abbreviation: "S",
    name: "Sierra Time Zone",
    displayName: "Sierra Time Zone(UTC - 6)",
    offset: "-6 hours",
  },
  {
    abbreviation: "SAMT",
    name: "Samara Time",
    displayName: "Samara Time(UTC + 4)",
    offset: "4 hours",
  },
  {
    abbreviation: "SAST",
    name: "South Africa Standard Time",
    displayName: "South Africa Standard Time(UTC + 2)",
    offset: "2 hours",
  },
  {
    abbreviation: "SBT",
    name: "Solomon IslandsTime",
    displayName: "Solomon IslandsTime(UTC + 11)",
    offset: "11 hours",
  },
  {
    abbreviation: "SCT",
    name: "Seychelles Time",
    displayName: "Seychelles Time(UTC + 4)",
    offset: "4 hours",
  },
  {
    abbreviation: "SGT",
    name: "Singapore Time",
    displayName: "Singapore Time(UTC + 8)",
    offset: "8 hours",
  },
  {
    abbreviation: "SRT",
    name: "Suriname Time",
    displayName: "Suriname Time(UTC - 3)",
    offset: "-3 hours",
  },
  {
    abbreviation: "SST",
    name: "Samoa Standard Time",
    displayName: "Samoa Standard Time(UTC - 11)",
    offset: "-11 hours",
  },
  {
    abbreviation: "T",
    name: "Tango Time Zone",
    displayName: "Tango Time Zone(UTC - 7)",
    offset: "-7 hours",
  },
  {
    abbreviation: "TAHT",
    name: "Tahiti Time",
    displayName: "Tahiti Time(UTC - 10)",
    offset: "-10 hours",
  },
  {
    abbreviation: "TFT",
    name: "French Southern and Antarctic Time",
    displayName: "French Southern and Antarctic Time(UTC + 5)",
    offset: "5 hours",
  },
  {
    abbreviation: "TJT",
    name: "Tajikistan Time",
    displayName: "Tajikistan Time(UTC + 5)",
    offset: "5 hours",
  },
  {
    abbreviation: "TKT",
    name: "Tokelau Time",
    displayName: "Tokelau Time(UTC + 13)",
    offset: "13 hours",
  },
  {
    abbreviation: "TLT",
    name: "East Timor Time",
    displayName: "East Timor Time(UTC + 9)",
    offset: "9 hours",
  },
  {
    abbreviation: "TMT",
    name: "Turkmenistan Time",
    displayName: "Turkmenistan Time(UTC + 5)",
    offset: "5 hours",
  },
  {
    abbreviation: "TVT",
    name: "Tuvalu Time",
    displayName: "Tuvalu Time(UTC + 12)",
    offset: "12 hours",
  },
  {
    abbreviation: "U",
    name: "Uniform Time Zone",
    displayName: "Uniform Time Zone(UTC - 8)",
    offset: "-8 hours",
  },
  {
    abbreviation: "ULAT",
    name: "Ulaanbaatar Time",
    displayName: "Ulaanbaatar Time(UTC + 8)",
    offset: "8 hours",
  },
  {
    abbreviation: "UTC",
    name: "Coordinated Universal Time",
    displayName: "Coordinated Universal Time(UTC)",
    offset: "0 hours",
  },
  {
    abbreviation: "UYST",
    name: "Uruguay Summer Time",
    displayName: "Uruguay Summer Time(UTC - 2)",
    offset: "-2 hours",
  },
  {
    abbreviation: "UYT",
    name: "Uruguay Time",
    displayName: "Uruguay Time(UTC - 3)",
    offset: "-3 hours",
  },
  {
    abbreviation: "UZT",
    name: "Uzbekistan Time",
    displayName: "Uzbekistan Time(UTC + 5)",
    offset: "5 hours",
  },
  {
    abbreviation: "V",
    name: "Victor Time Zone",
    displayName: "Victor Time Zone(UTC - 9)",
    offset: "-9 hours",
  },
  {
    abbreviation: "VET",
    name: "Venezuelan Standard Time",
    displayName: "Venezuelan Standard Time(UTC - 4:30)",
    offset: "-4:30 hours",
  },
  {
    abbreviation: "VLAST",
    name: "Vladivostok Summer Time",
    displayName: "Vladivostok Summer Time(UTC + 11)",
    offset: "11 hours",
  },
  {
    abbreviation: "VLAT",
    name: "Vladivostok Time",
    displayName: "Vladivostok Time(UTC + 11)",
    offset: "11 hours",
  },
  {
    abbreviation: "VUT",
    name: "Vanuatu Time",
    displayName: "Vanuatu Time(UTC + 11)",
    offset: "11 hours",
  },
  {
    abbreviation: "W",
    name: "Whiskey Time Zone",
    displayName: "Whiskey Time Zone(UTC - 10)",
    offset: "-10 hours",
  },
  {
    abbreviation: "WAST",
    name: "West Africa Summer Time",
    displayName: "West Africa Summer Time(UTC + 2)",
    offset: "2 hours",
  },
  {
    abbreviation: "WAT",
    name: "West Africa Time",
    displayName: "West Africa Time(UTC + 1)",
    offset: "1 hours",
  },
  {
    abbreviation: "WEST",
    name: "Western European Summer Time",
    displayName: "Western European Summer Time(UTC + 1)",
    offset: "1 hours",
  },
  {
    abbreviation: "WESZ",
    name: "Westeuropäische Sommerzeit",
    displayName: "Westeuropäische Sommerzeit(UTC + 1)",
    offset: "1 hours",
  },
  {
    abbreviation: "WET",
    name: "Western European Time",
    displayName: "Western European Time(UTC)",
    offset: "0 hours",
  },
  {
    abbreviation: "WEZ",
    name: "Westeuropäische Zeit",
    displayName: "Westeuropäische Zeit(UTC)",
    offset: "0 hours",
  },
  {
    abbreviation: "WFT",
    name: "Wallis and Futuna Time",
    displayName: "Wallis and Futuna Time(UTC + 12)",
    offset: "12 hours",
  },
  {
    abbreviation: "WGST",
    name: "Western Greenland Summer Time",
    displayName: "Western Greenland Summer Time(UTC - 2)",
    offset: "-2 hours",
  },
  {
    abbreviation: "WGT",
    name: "West Greenland Time",
    displayName: "West Greenland Time(UTC - 3)",
    offset: "-3 hours",
  },
  {
    abbreviation: "WIB",
    name: "Western Indonesia Time",
    displayName: "Western Indonesian Time(UTC + 7)",
    offset: "7 hours",
  },
  {
    abbreviation: "WIT",
    name: "Eastern Indonesia Time",
    displayName: "Eastern Indonesian Time(UTC + 9)",
    offset: "9 hours",
  },
  {
    abbreviation: "WITA",
    name: "Central Indonesia Time",
    displayName: "Central Indonesian Time(UTC + 8)",
    offset: "8 hours",
  },
  {
    abbreviation: "WST",
    name: "Western Sahara Summer Time",
    displayName: "Western Sahara Summer Time(UTC + 1)",
    offset: "1 hours",
  },
  {
    abbreviation: "WST",
    name: "West Samoa Time",
    displayName: "West Samoa Time(UTC + 13)",
    offset: "13 hours",
  },
  {
    abbreviation: "WT",
    name: "Western Sahara Standard Time",
    displayName: "Western Sahara Standard Time(UTC)",
    offset: "0 hours",
  },
  {
    abbreviation: "X",
    name: "X-ray Time Zone",
    displayName: "X-ray Time Zone(UTC - 11)",
    offset: "-11 hours",
  },
  {
    abbreviation: "Y",
    name: "Yankee Time Zone",
    displayName: "Yankee Time Zone(UTC - 12)",
    offset: "-12 hours",
  },
  {
    abbreviation: "YAKST",
    name: "Yakutsk Summer Time",
    displayName: "Yakutsk Summer Time(UTC + 10)",
    offset: "10 hours",
  },
  {
    abbreviation: "YAKT",
    name: "Yakutsk Time",
    displayName: "Yakutsk Time(UTC + 10)",
    offset: "10 hours",
  },
  {
    abbreviation: "YAPT",
    name: "Yap Time",
    displayName: "Yap Time(UTC + 10)",
    offset: "10 hours",
  },
  {
    abbreviation: "YEKST",
    name: "Yekaterinburg Summer Time",
    displayName: "Yekaterinburg Summer Time(UTC + 6)",
    offset: "6 hours",
  },
  {
    abbreviation: "YEKT",
    name: " Yekaterinburg Time",
    displayName: " Yekaterinburg Time(UTC + 6)",
    offset: "6 hours",
  },
  {
    abbreviation: "Z",
    name: "Zulu Time Zone",
    displayName: "Zulu Time Zone(UTC)",
    offset: "0 hours",
  },
];

export default timezones;
