import { dayjs } from "@saas/core";

import { timezones } from "../..";

export type FormatReadableTimeLocalInput = string | Date;

export type FormatReadableTimeLocalOutput = string;

export const formatReadableTimeLocal = (
  input?: FormatReadableTimeLocalInput
): FormatReadableTimeLocalOutput => {
  if (!input || input.toString() === "Invalid Date") return "-";

  const datetime = dayjs(input).format("HH.mm");
  const abbreviation = getTimezoneAbbreviation(input);

  return `${datetime} ${abbreviation}`;
};

const getTimezoneAbbreviation = (input: string | Date) => {
  const timezoneName = new Date(input).toString();

  const timezone = timezones.find((timezone) => {
    return timezoneName.includes(timezone.name);
  });

  return timezone?.abbreviation;
};

export default formatReadableTimeLocal;
