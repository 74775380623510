import { fetchJson, ResponseInterface } from "@saas/core";
import { FeatureFlagsInterface, FlagKeys } from "@saas/flags/utils";

export interface GetFeatureFlagsStatusResponse extends ResponseInterface {
  flags: FeatureFlagsInterface["status"];
}

export type GetFeatureFlagsStatusOutput = Record<FlagKeys, boolean>;

export const getFeatureFlagsStatus = async (
  options?: RequestInit
): Promise<GetFeatureFlagsStatusOutput> => {
  const response = await fetchJson<GetFeatureFlagsStatusResponse>(
    "/api/feature-flags/status",
    options
  );

  return response.flags;
};

export default getFeatureFlagsStatus;
