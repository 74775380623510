import { useCallback } from "react";

import { useIsClient } from "usehooks-ts";

interface OpenOptions {
  newTab?: boolean;
}

export type UseBlobReturnType = {
  open: (file: Blob, options?: OpenOptions) => void;
  download: (file: Blob, filename: string) => void;
};

export const useBlob = (): UseBlobReturnType => {
  const isClient = useIsClient();

  const open = useCallback(
    (file: Blob, options?: OpenOptions) => {
      if (isClient) {
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL, options?.newTab ? "_blank" : undefined);

        URL.revokeObjectURL(fileURL);
      }
    },
    [isClient]
  );

  const download = useCallback(
    (file: Blob, filename: string) => {
      if (isClient) {
        const url = URL.createObjectURL(file);

        const link = document.createElement("a");
        // Setting download to enforce filename value for blob
        link.setAttribute("download", filename);
        link.href = url;
        link.click();

        URL.revokeObjectURL(url);
      }
    },
    [isClient]
  );

  return {
    open,
    download,
  };
};

export default useBlob;
