import { env } from "@saas/config/shared";

import { dayjs, FetchError } from "../..";

import * as uuid from "uuid";

const isTest = process.env["NODE_ENV"] === "test";
const isStorybook = process.env["STORYBOOK"] === "true";

const BASE_URL = typeof window === "undefined" ? env.APP_URL : "";

export const fetchForm = async <JSONDataType = unknown>(
  input: RequestInfo,
  formData: FormData,
  init?: RequestInit
): Promise<JSONDataType> => {
  const url =
    isTest || isStorybook ? "https://localhost.test" + input : BASE_URL + input;

  const response = await fetch(url, {
    method: "POST",
    body: formData,
    ...init,
    headers: {
      "Accept": "application/json",
      "X-Request-ID": uuid.v4(),
      "X-Timezone-Offset": dayjs().format("Z"),
      ...init?.headers,
    },
  });

  // if the server replies, there's always some data in json
  // if there's a network error, it will throw at the previous line
  const data = await response.json();

  if (!response.ok) {
    throw new FetchError({
      message: response.statusText,
      response,
      payload: formData,
      data,
    });
  }

  return data;
};

export default fetchForm;
