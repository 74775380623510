import { Fragment, MutableRefObject, ReactNode } from "react";

import { Dialog, Transition } from "@headlessui/react";

export interface BaseMobileDrawerProps {
  open: boolean;
  onClose: (falsy: boolean) => void | Promise<void>;
  children: ReactNode;
  initialRef?: MutableRefObject<HTMLElement | null>;
}

export const BaseMobileDrawer = ({
  open,
  onClose,
  children,
  initialRef,
}: BaseMobileDrawerProps) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as={"div"}
        open={open}
        className={"relative z-10 md:hidden"}
        onClose={() => onClose(false)}
        initialFocus={initialRef}
      >
        <Transition.Child
          as={Fragment}
          enter={"ease-out duration-300"}
          enterFrom={"opacity-0"}
          enterTo={"opacity-100"}
          leave={"ease-in duration-200"}
          leaveFrom={"opacity-100"}
          leaveTo={"opacity-0"}
        >
          <div
            className={
              "bg-neutral-N700/40 fixed inset-0 bg-opacity-75 transition-opacity"
            }
          />
        </Transition.Child>

        <div className={"fixed inset-0 z-10 overflow-y-auto"}>
          <div
            className={
              "relative flex min-h-full items-end justify-center text-center sm:items-center"
            }
          >
            <Transition.Child
              as={Fragment}
              enter={"ease-out duration-300"}
              enterFrom={"opacity-0 translate-y-4"}
              enterTo={"opacity-100 translate-y-0"}
              leave={"ease-in duration-200"}
              leaveFrom={"opacity-100 translate-y-0"}
              leaveTo={"opacity-0 translate-y-4"}
            >
              <div className={"w-full"}>
                <hr
                  className={
                    "bg-neutral-N0 relative mx-auto mb-2 h-1 w-14 rounded-3xl"
                  }
                />

                <Dialog.Panel
                  className={
                    "bg-neutral-N0 relative w-full transform overflow-hidden rounded-t-xl text-left transition-all sm:my-8 sm:w-full sm:max-w-lg"
                  }
                >
                  {children}
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default BaseMobileDrawer;
