import { dayjs } from "@saas/core";

export type FormatRelativeDatetimeInput = string | Date;
export type FormatRelativeDatetimeOutput = string;

export const formatRelativeDatetime = (
  input: FormatRelativeDatetimeInput
): FormatRelativeDatetimeOutput => {
  const time = dayjs(input);

  const daysDiff = getDayDiff(time);
  if (daysDiff > 0) {
    return time.format("DD MMM YYYY");
  }

  return time.format("HH.mm");
};

const getDayDiff = (datetime: dayjs.Dayjs): number => {
  return dayjs().diff(datetime, "day");
};

export default formatRelativeDatetime;
