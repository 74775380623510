import { useRouter } from "next/router";
import { Fragment } from "react";

import { useFlagStatus } from "@saas/flags/feature";
import { ChevronRightIcon } from "@saas/shared/icon";
import { Typography } from "@saas/shared/ui";
import { Breadcrumb, classNames } from "@saas/shared/utils";

interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}

export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  const { push } = useRouter();
  const isNewBreadCrumbs = useFlagStatus("SAAS-6100");

  return (
    <div className={"flex items-center gap-1"}>
      {breadcrumbs.map((item, index) => {
        const lastIndex = breadcrumbs.length - 1;
        const showLabelOnMobile = isNewBreadCrumbs
          ? index >= lastIndex - 1
          : index === 0 || index === lastIndex;
        const showChevronOnMobile = isNewBreadCrumbs
          ? index === lastIndex
          : index === 0 || index === lastIndex;

        return item.label ? (
          <Fragment key={item.href}>
            {index !== 0 ? (
              <ChevronRightIcon
                className={classNames(
                  "text-neutral-N600 h-6 w-6",
                  showChevronOnMobile ? "inline" : "hidden md:inline"
                )}
              />
            ) : null}

            <button
              className={showLabelOnMobile ? "inline" : "hidden md:inline"}
              onClick={() => !item.current && push(item.href)}
            >
              <Typography
                type={"title-small"}
                className={classNames(
                  "truncate",
                  index === lastIndex
                    ? "text-he cursor-default"
                    : "text-le hover:text-he"
                )}
                data-testid={"breadcrumbs"}
              >
                {item.label}
              </Typography>
            </button>
          </Fragment>
        ) : null;
      })}
    </div>
  );
};

export default Breadcrumbs;
