import { useRouter } from "next/router";
import { useEffect } from "react";

type Action<T> = (item: T) => void;

export type UsePushNotificationOpenedListenerReturnType = void;

export const usePushNotificationOpenedListener = (
  callback: Action<number>
): UsePushNotificationOpenedListenerReturnType => {
  const { query, replace } = useRouter();
  const { melaka_notification_id: melakaNotificationId, ...restOfQuery } =
    query;

  useEffect(() => {
    if (melakaNotificationId && typeof melakaNotificationId === "string") {
      callback(parseInt(melakaNotificationId));

      replace({
        query: {
          ...restOfQuery,
        },
      });
    }
  }, [callback, melakaNotificationId, replace, restOfQuery]);
};

export default usePushNotificationOpenedListener;
